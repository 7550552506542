/**
 * Created by osirvent on 10/10/2016.
 */
angular.module('annexaApp')
    .directive('uiTreeSelect', ['$timeout', '$filter', '$compile', '$rootScope', function ($timeout, $filter, $compile, $rootScope) {
        var getTemplate = function(scope) {
            var template = '';

            //if(scope.clearFunction) {
                template += '<div class=" pos-relative">';
                template += '<button type="button" class="bt-deselect m-r-xxl no-bg b-a-0 p-a-0" ng-if="canView" ng-click="clearTree()" ng-show="ngModel.$selected"><i class="fa fa-remove" title="{{ \'global.literals.remove\' | translate}}" aria-hidden="true"></i><span class="sr-only">"{{ \'global.literals.remove\' | translate}}"</span></button>'
            //}
            template += '<ui-select data-ng-model="ngModel.$selected" ui-select-focuser theme="selectize" ng-change="changeFilter()" on-select="change($item, extraChange)" data-ng-required="{{req}}" ng-disabled="disabled">';
            template += '   <ui-select-match placeholder="{{\'global.literals.select\' | translate}}">{{ $select.selected.title }}</ui-select-match>';
            template += '   <ui-select-choices data-repeat="group in groups | filter: $select.search">';
            if(scope.onlyLastLevelClick){
                template += '       <div ng-if="group.parent" class="tree__item--parent" ng-click="loadChildGroupsOf(group, $select)">';
                template += '           <div ng-bind-html="group.title | highlight: $select.search"></div>';
                template += '           <a href ng-show="group.parent" class="goto-child-group">';
                template += '               <i class="fa fa-angle-right fa-2x"></i>';
                template += '           </a>';
                template += '       </div>';
                template += '       <div ng-if="!group.parent" class="tree__item--child">';
                template += '           <div ng-bind-html="group.title | highlight: $select.search"></div>';
                template += '       </div>';
            }else {
                if(!scope.checkSelectable) {
                    template += '       <div>';
                    template += '           <span ng-bind-html="group.title | highlight: $select.search"></span>';
                    template += '           <a href ng-show="group.parent" class="goto-child-group" ng-click="loadChildGroupsOf(group, $select)">';
                    template += '               <i class="fa fa-angle-right fa-2x"></i>';
                    template += '           </a>';
                    template += '       </div>';
                } else {
                    template += '       <div ng-if="!group.selectable" class="tree__item--parent" ng-click="loadChildGroupsOf(group, $select)">';
                    template += '           <div ng-bind-html="group.title | highlight: $select.search"></div>';
                    template += '           <a href ng-show="group.parent" class="goto-child-group">';
                    template += '               <i class="fa fa-angle-right fa-2x"></i>';
                    template += '           </a>';
                    template += '       </div>';
                    template += '       <div ng-if="group.selectable" class="tree__item--child">';
                    template += '           <div ng-bind-html="group.title | highlight: $select.search"></div>';
                    template += '       </div>';
                }
            }
            template += '   </ui-select-choices>';
            template += '</ui-select>';
            if(scope.clearFunction) {
                template += '</div>';
            }

            return template;
        };

        return {
            restrict: 'E',
            scope: {
                idModel: '@',
                ngModel: '=',
                data: '=',
                change: '=',
                req: '=',
                onlyLastLevelClick: '=',
                changeFilter: '&',
                extraChange: '=?',
                checkSelectable: '=',
                disabled: '=?'//,
                //clearFunction: '='
            },
            link: function (scope, element) {
                scope.breadcrumbs = [{"id":0,"title": $filter('translate')('global.literals.all')}];
                scope.groups = scope.data[0];
                scope.canView = ((scope.disabled)?false:true);
                scope.disabled = ((scope.disabled)?true:false);
                var changeTree = function() {
                    scope.breadcrumbs = [{"id":0,"title": $filter('translate')('global.literals.all')}];
                    if(scope.data && scope.data[0]){
                        scope.groups = scope.data[0];
                    }else{
                        scope.groups = {};
                    }
                    if(element && element.querySelectorAll('.ui-select-choices-content') && element.querySelectorAll('.ui-select-choices-content').length > 0) {
                        element.querySelectorAll('.ui-select-choices-content')[0].scrollTop = 0;
                    }
                }

                scope.clearTree = function() {
                    scope.ngModel = {}

                    changeTree();

                    $rootScope.$broadcast('annexaUISelectTreeCleared', { idModel: scope.idModel });
                }

                scope.loadChildGroupsOf = function(group, $select) {
                    $select.search = '';

                    scope.breadcrumbs.push(group);
                    scope.groups = scope.data[group.id];
                    if(element && element.querySelectorAll('.ui-select-choices-content') && element.querySelectorAll('.ui-select-choices-content').length > 0) {
                        element.querySelectorAll('.ui-select-choices-content')[0].scrollTop = 0;
                    }
                    scope.$broadcast('uiSelectFocus');
                };

                scope.navigateBackTo = function (crumb, $select) {
                    $select.search = '';
                    var index = _.findIndex(scope.breadcrumbs, {id: crumb.id});

                    scope.breadcrumbs.splice(index + 1, scope.breadcrumbs.length);
                    scope.groups = scope.data[_.last(scope.breadcrumbs).id];
                    $select.open = false;
                    if(element && element.querySelectorAll('.ui-select-choices-content') && element.querySelectorAll('.ui-select-choices-content').length > 0) {
                        element.querySelectorAll('.ui-select-choices-content')[0].scrollTop = 0;
                    }
                    scope.$broadcast('uiSelectFocus');
                };


                scope.$on('treeTemplatesChange', function(event, args) {
                    changeTree();
                });

                element.html(getTemplate(scope)).show();

                $compile(element.contents())(scope);
            }
        };
    }])
    .directive('uiSelectFocuser', function ($timeout) {
        return {
            restrict: 'A',
            require: '^uiSelect',
            link: function (scope, elem, attrs, uiSelect) {
                scope.$on('uiSelectFocus', function () {
                    $timeout(uiSelect.activate);
                });
            }
        };
    })
v