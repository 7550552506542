/**
 * Created by osirvent on 13/06/2016.
 */
angular
    .module('annexaApp')
    .factory('AnnexaFormlyFactory',['ModalService', 'formlyUtil', '$timeout', 'AnnexaPlugins', function(ModalService, formlyUtil, $timeout, AnnexaPlugins) {

        return {
            showAnnexaFormModalObject: function(idModal, modalDefinition, submitFunction) {
                if(submitFunction) {
                    modalDefinition.submitModal = submitFunction;
                }

                $timeout(function() {
                    ModalService.showModal({
                        template: '<annexa-form-modal-object id="' + idModal + '" modal-object="modalObj" class="modal black-overlay" data-backdrop="false"/>',
                        controller: 'AnnexaFormlyModalController',
                        modalObj: modalDefinition
                    }).then(function(modal) {
                        modalDefinition.modal = modal;
                        modalDefinition.close = modal.element.scope().close;
                        modal.element.modal();
                        angular.element(".modal-title").focus();
                        /*if(pluginId && pluginObject){
                            AnnexaPlugins.addPlugin(modal.element.scope(), pluginObject, pluginId);
                        }*/
                    })
                })
            },
            showAnnexaFormModal: function(idModal, modalDefinition, submitFunction) {
                if(submitFunction) {
                    modalDefinition.submitModal = submitFunction;
                }

                $timeout(function() {
                    ModalService.showModal({
                        template: '<annexa-form-modal id="' + idModal + '" modal-object="modalObj" class="modal black-overlay" data-backdrop="false"/>',
                        controller: 'AnnexaFormlyModalController',
                        modalObj: modalDefinition
                    }).then(function(modal) {
                        modalDefinition.modal = modal;
                        modalDefinition.close = modal.element.scope().close;
                        modal.element.modal();
                        angular.element(".modal-title").focus();
                        /*if(pluginId && pluginObject){
                            AnnexaPlugins.addPlugin(modal.element.scope(), pluginObject, pluginId);
                        }*/
                    })
                })
            },
            showAnnexaAdvancedSearchModal: function(idModal, modalDefinition, submitFunction) {
                if(submitFunction) {
                    modalDefinition.submitModal = submitFunction;
                }

                $timeout(function() {
                    ModalService.showModal({
                        template: '<annexa-advanced-search-modal id="' + idModal + '" modal-object="modalObj" class="modal black-overlay" data-backdrop="false"/>',
                        controller: 'AnnexaFormlyModalController',
                        modalObj: modalDefinition
                    }).then(function(modal) {
                        modalDefinition.modal = modal;
                        modalDefinition.close = modal.element.scope().close;
                        modal.element.modal();
                        angular.element(".modal-title").focus();
                    })
                })
            },
            showModal: function(idModal, modalDefinition, submitFunction, showdebuginfo, hideSubmitButton, buttonLabel, pluginId, pluginObject) {
                modalDefinition.submitModal = submitFunction;
                if(!buttonLabel){
                    buttonLabel = '';
                }
                var template = '<annexa-formly-modal id="' + idModal + '" modal-object="modalObj" class="modal black-overlay" data-backdrop="false" showdebuginfo="' + showdebuginfo+ '" buttonlabel="'+buttonLabel + '"/>';
                if(hideSubmitButton){
                    template = '<annexa-formly-modal id="' + idModal + '" modal-object="modalObj" class="modal black-overlay" data-backdrop="false" showdebuginfo="' + showdebuginfo + '" hidesubmitbutton="'+hideSubmitButton+ '" buttonlabel="'+buttonLabel+'"/>';
                }
                $timeout(function() {
                    ModalService.showModal({
                        template: template,
                        controller: 'AnnexaFormlyModalController',
                        modalObj: modalDefinition
                    }).then(function (modal) {
                        modalDefinition.modal = modal;
                        modalDefinition.close = modal.element.scope().close;
                        modal.element.modal();
                        angular.element(".modal-title").focus();
                        if(pluginId && pluginObject){
                            AnnexaPlugins.addPlugin(modal.element.scope(), pluginObject, pluginId);
                        }
                    });
                });
            },
            showModalPromise: function(idModal, modalDefinition, submitFunction, showdebuginfo, hideSubmitButton, buttonLabel) {
                modalDefinition.submitModal = submitFunction;
                if(!buttonLabel){
                    buttonLabel = '';
                }
                var template = '<annexa-formly-modal id="' + idModal + '" modal-object="modalObj" class="modal black-overlay" data-backdrop="false" showdebuginfo="' + showdebuginfo+ '" buttonlabel="'+buttonLabel  + '"/>';
                if(hideSubmitButton){
                    template = '<annexa-formly-modal id="' + idModal + '" modal-object="modalObj" class="modal black-overlay" data-backdrop="false" showdebuginfo="' + showdebuginfo + '" hidesubmitbutton="'+hideSubmitButton+ '" buttonlabel="'+buttonLabel +'"/>';
                }
                return ModalService.showModal({
                    template: template,
                    controller: 'AnnexaFormlyModalController',
                    modalObj: modalDefinition
                });
            }
        }
    }])
    .factory('AnnexaFormsFactory',['Language', '$filter', '$state', 'RestService', '$rootScope', 'HelperService', function(Language, $filter, $state, RestService, $rootScope, HelperService) {
        return {
            inputRegisterEntry: function(form, data, channels, document_types, classifications, new_dt, new_cl,entryType) {
                var userProfiles = $linq($rootScope.LoggedUser.userProfiles).distinctBy("x => x.profile.id").select("x => x.profile").toArray();
                var defaultProfile = $linq($rootScope.LoggedUser.userDefaultEntityProfiles).singleOrDefault(undefined,'x => x.entityType=="REG"');

                var addProfile = false;

                if(!defaultProfile && userProfiles.length > 1) {
                    form.addField(
                        'profile',
                        'annexaSelectRow',
                        '',
                        new AnnexaFormlyFieldSelectTemplateOptions(
                            'global.literals.profile',
                            'id',
                            Language.getActiveColumn(),
                            userProfiles,
                            true
                        ),
                        data
                    );
                } else {
                    addProfile = true;
                }

                if(entryType == 'INPUT') {
                    // No ha de ser visible ni editable, pero si existir per fer el insert a BBDD.
                    form.addField(
                        'presentationDate',
                        'annexaDatepickerRow',
                        '',
                        new AnnexaFormlyFieldDatepickerTemplateOptions(
                            'text',
                            'global.literals.presentationDate',
                            false,
                            {
                                format: 'dd/MM/yyyy',
                                initDate: new Date(),
                                showWeeks: false,
                                startingDay: 1
                            }
                        ),
                        data,
                        "true"
                    );
                }
                form.addField(
                    'registerEntryChannel',
                    'annexaSelectRow',
                    '',
                    new AnnexaFormlyFieldSelectTemplateOptions(
                        'global.literals.channel',
                        'id',
                        Language.getActiveColumn(),
                        channels,
                        true
                    ),
                    data
                );

                form.addField(
                    'documentDate',
                    'annexaDatepickerRow',
                    '',
                    new AnnexaFormlyFieldDatepickerTemplateOptions(
                        'text',
                        'global.literals.documentDate',
                        false,
                        {
                            format: 'dd/MM/yyyy',
                            initDate: new Date(),
                            showWeeks: false,
                            startingDay: 1
                        }
                    ),
                    data
                );

                form.addField(
                    'entryDocumentType',
                    'annexaSelectRow',
                    '',
                    new AnnexaFormlyFieldSelectTemplateOptions(
                        'global.literals.docType',
                        'id',
                        Language.getActiveColumn(),
                        document_types,
                        false,
                        undefined,
                        new_dt
                    ),
                    data
                );

                form.addField(
                    'entryClassification',
                    'annexaSelectRow',
                    '',
                    new AnnexaFormlyFieldSelectTemplateOptions(
                        'global.literals.classification',
                        'id',
                        Language.getActiveColumn(),
                        classifications,
                        false,
                        undefined,
                        new_cl
                    ),
                    data
                );

                form.addField(
                    'extract',
                    'annexaTextAreaRow',
                    '',
                    new AnnexaFormlyFieldTextAreaTemplateOptions(
                        'global.literals.extract',
                        5,
                        false,
                        undefined,
                        1024
                    ),
                    data
                );

                form.addField(
                    'observations',
                    'annexaTextAreaRow',
                    '',
                    new AnnexaFormlyFieldTextAreaTemplateOptions(
                        'global.literals.observations',
                        5,
                        false,
                        undefined,
                        255
                    ),
                    data
                );

                form.addField(
                    'referent',
                    'annexaRadioCheckboxRow',
                    '',
                    new AnnexaFormlyFieldSelectTemplateOptions(
                        'global.literals.relative',
                        'value',
                        'label',
                        [{value: 1, label: $filter('translate')('global.literals.actionOtherUser')}],
                        false,
                        'checkbox'
                    ),
                    data
                );

                form.addField(
                    'referntUser',
                    'annexaTypeaheadRow',
                    '',
                    new AnnexaFormlyTypeaheadTemplateOptions(
                        '',
                        function(val) {
                            if(!val || !val.val) {
                                return [];
                            }else if(val.val != '*' && val.val.length < 3){
                                return [];
                            }else {
                                if(val.val == '*'){
                                    val.val = '';
                                }
                                var filter = {name: val.val};
                                return RestService.filterData('common', 'User', filter, {}, 0, 100, '', 3)
                                    .then(function (data) {
                                        var response = [];

                                        angular.forEach(data.data.content, function (value, key) {
                                            var val = value.name;
                                            if (value.surename1 != null && value.surename1 != undefined) {
                                                val += " " + value.surename1;
                                            }
                                            if (value.surename2 != null && value.surename2 != undefined) {
                                                val += " " + value.surename2;
                                            }
                                            response.push({id: value.id, object: JSOG.encode(value), value: val});
                                        });

                                        return response;
                                    }).catch(function (error) {
                                        var a = error;
                                    });
                            }
                        },
                        'global.literals.user',
                        false
                    ),
                    data,
                    'model.referent_1 == false'
                );

                if(addProfile) {
                    var dfValue = undefined;

                    if(defaultProfile) {
                        defaultProfile.profile.userProfiles = undefined;
                        dfValue = defaultProfile.profile;
                    } else if (userProfiles.length > 0) {
                        dfValue = userProfiles[0];
                    }

                    form.addField(
                        'profile',
                        'annexaHidden',
                        '',
                        new AnnexaFormlyFieldTemplateOptions(
                            'hidden',
                            '',
                            true
                        ),
                        data,
                        undefined,
                        dfValue
                    )
                }
                return form;
            }
        }
    }]);
