/**
 * Created by osirvent on 02/02/2016.
 */

var HeadButton = function(btClass,dataToggle, dataTarget, btIcon, btText, btText2, eClick, eDisabled, form, permissions, dropdownButtons, groupClass, id) {
    this.id = id;
    this.btClass = btClass;
    this.dataToggle = dataToggle;
    this.dataTarget = dataTarget;
    this.btIcon = btIcon;
    this.btText = btText;
    this.btText2 = btText2;
    this.eClick = eClick;
    this.eDisabled = eDisabled;
    this.form = form;
    if(permissions) {
        if(typeof permissions === 'string') {
            this.permissions = [permissions];
        } else {
            this.permissions = permissions;
        }
    } else {
        this.permissions = [];
    }

    this.setPermissions = function (permissions) {
        if(permissions) {
            if(typeof permissions === 'string') {
                this.permissions = [permissions];
            } else {
                this.permissions = permissions;
            }
        } else {
            this.permissions = [];
        }

        return this;
    }
    this.dropdownButtons = dropdownButtons;
    this.groupClass = groupClass;
}

var HeadButtonSearch = function(dataTarget) {
    this.btClass = 'btn grey-500 text-white breadDivButton';
    this.dataToggle = 'collapse';
    this.dataTarget = dataTarget;
    this.btIcon = 'fa-search';
    this.btText = 'global.literals.filter';
    this.btText2 = undefined;
    this.eClick = undefined;
    this.eDisabled = undefined;
    this.id = "filter-button";
}