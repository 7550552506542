/**
 * Created by oscar on 21/01/16.
 */
angular.module('annexaApp')
    .directive('compileData',['$compile', '$injector','$rootScope', function ($compile, $injector, $rootScope) {
        return function ( scope, element, attrs ) {
                var elmnt;
                attrs.$observe( 'eventclick', function ( myTemplate ) {
                    if ( angular.isDefined( myTemplate ) && myTemplate != '' && attrs.divcontrol != null && attrs.divcontrol != '') {
                        element.bind("click", function(event){
                            if(attrs.compileData) {
                                $('#'+myTemplate).click()
                            }else{
                                var aux = angular.element(attrs.divcontrol).scope();
                                if(aux && aux.$ctrl && aux.$ctrl[myTemplate]) {
                                    aux.$ctrl[myTemplate](event);
                                } else {
                                    if(aux && aux[myTemplate]) {
                                        aux[myTemplate](event);
                                    }else if($rootScope && $rootScope[myTemplate]) {
                                        $rootScope[myTemplate](event);
                                    }
                                }
                            }
                        });
                    }
                });
        };
    }])
    .directive('compile', ['$compile', function ($compile) {
    return {
        link: function(scope, element, attrs) {
            if(attrs['modal']) {
                scope.modal = scope.$eval(attr['modal']);
            }
            scope.$watch(
                function(scope) {
                    // watch the 'compile' expression for changes
                    return scope.$eval(attrs.compile);
                },
                function(value) {
                    // when the 'compile' expression changes
                    // assign it into the current DOM
                    element.html(value);

                    // compile the new DOM and link it to the current
                    // scope.
                    // NOTE: we only compile .childNodes so that
                    // we don't get into infinite loop compiling ourselves
                    $compile(element.contents())(scope);
                }
            );
        }
    };
    }]);
