/**
 * Created by osirvent on 20/01/2016.
 */
angular
    .module('annexaApp')
    .factory('TaskFactory',['$q','$http','$filter', '$rootScope', 'RestService', 'HelperService', 'globalModals', 'AnnexaModalFactory', 'AnnexaFormlyFactory', 'Language', 'Upload', 'TableFilter', 'DTColumnBuilder', 'CommonService', function($q, $http, $filter, $rootScope, RestService, HelperService, globalModals, AnnexaModalFactory, AnnexaFormlyFactory, Language, Upload, TableFilter, DTColumnBuilder, CommonService) {
        var factory = {};

        factory.getLanguageColumn = Language.getActiveColumn();
        factory.templateTypes = [];
        factory.task = {};
        factory.userAux = {user: undefined};
        factory.createdUserAux = {user: undefined};
        factory.isCreator = false;
        factory.isAssigned = false;

        factory.getTask = function (idTask) {
            return RestService.findOne('Task', idTask).then(function (data) {
                factory.task = JSOG.decode(data.data);
                if (factory.task.user && factory.task.user.id) {
                    var user_name = '';
                    user_name = factory.task.user.name + ' ' + factory.task.user.surename1 + (factory.task.user.surename2 ? ' ' + factory.task.user.surename2 : '');
                    factory.userAux = {
                        user: {
                            id: factory.task.user.id,
                            user: factory.task.user,
                            value: user_name
                        }
                    };
                    if (factory.task.createdUser.id == $rootScope.LoggedUser.id) {
                        factory.isCreator = true;
                    }else{
                        factory.isCreator = false;
                    }
                    if (factory.task.user && factory.task.user.id == $rootScope.LoggedUser.id) {
                        factory.isAssigned = true;
                    }else{
                        factory.isAssigned = false;
                    }
                }
                if (factory.task.createdUser && factory.task.createdUser.id) {
                    var created_user_name = '';
                    created_user_name = factory.task.createdUser.name + ' ' + factory.task.createdUser.surename1 + (factory.task.createdUser.surename2 ? ' ' + factory.task.createdUser.surename2 : '');
                    
                    factory.createdUserAux = {
                            user: {
                                id: factory.task.createdUser.id,
                                user: {},
                                value: created_user_name
                            }
                        };                	
                }
            });
        }
        factory.getTaskData = function () {
            CommonService.getMimetypeDescription('application/pdf')
                .then(function(data) {
                    var a = 0;
                });
        };

        factory.getTaskTemplateData = function () {
            return $http({
                url: './api/taskTemplates/taskTemplate',
                method: 'GET'
            }).then(function (data) {
                var decodedData = JSOG.decode(data.data);
                factory.templateTypes = decodedData;
            }).catch(function (error) {
            });
        };

        factory.addTask = function (task,idProfile) {
            var deferred = $q.defer();
            var url = './api/tasks/addTask';
            if(idProfile){
                url = url +'/'+idProfile;
            }
            $http({
                url: url,
                method: 'POST',
                data: JSOG.encode(task)
            }).then(function (data) {
                deferred.resolve(data.data)
            }).catch(function (error) {
                deferred.reject(error);
            })

            return deferred.promise;
        };
        factory.addTaskComment = function (taskCommnet) {
            var deferred = $q.defer();

            $http({
                url: './api/tasks/addTaskComment',
                method: 'POST',
                data: JSOG.encode(taskCommnet)
            }).then(function (data) {
                deferred.resolve(data.data)
            }).catch(function (error) {
                deferred.reject(error);
            })

            return deferred.promise;
        };

        factory.updateTask = function (task, url) {
            var deferred = $q.defer();
            if (!url) {
                url = './api/tasks/updateTask';
            }
            $http({
                url: url,
                method: 'PUT',
                data: JSOG.encode(task)
            }).then(function (data) {
                deferred.resolve(data.data)
            }).catch(function (error) {
                deferred.reject(error);
            })

            return deferred.promise;
        };

        factory.searchTasks = function (value) {
            var valueOk = value;
            if (valueOk && valueOk.val) {
                valueOk = valueOk.val;
            }
            if (!valueOk) {
                return [];
            } else if (valueOk != '*' && valueOk.length < 3) {
                return [];
            } else {
                if (valueOk == '*') {
                    valueOk = '';
                }
                return RestService.loadData('common', 'User', valueOk).then(function (dataSearch) {
                    var usersLoad = [];
                    if (dataSearch.data && dataSearch.data.content && dataSearch.data.content.length > 0) {
                        angular.forEach(JSOG.decode(dataSearch.data.content), function (val, key) {
                            var name = val.name + " " + val.surename1;
                            if (val.surename2) {
                                name = name + " " + val.surename2;
                            }
                            usersLoad.push({'id': val.id, 'user': val, 'value': name});
                        });
                    }
                    return usersLoad;
                }).catch(function () {
                    return [];
                })
            }
        };

        factory.CommentBox = {
            initialize: function (permission, newButton) {
                this.boxDefinition = new BoxDefinition('global.literals.comments', $rootScope.LoggedUser.id);
                this.boxDefinition.addViewAndNewPremissions('create_task');
                if (permission && newButton) {
                    this.boxDefinition.addNew(this.newComment, this.completeNewComment);
                }
                return this;
            },
            boxDefinition: null,
            newComment: function () {
                factory.createComment(this.task, this, this.completedNew);
            },
            completeNewComment: function (data, task, boxDefinition) {
                var self = this;
                if (task) {
                    task.taskComments.push(data);
                } else {
                    boxDefinition.content.push(data);
                }
            }
        };

        factory.createComment = function (task, boxDefinition, boxFunc) {
            var self = this;
            var data = {
                row: true,
                colClass: ' col-sm-12',
                labelClass: 'label-strong'
            };
            var modal = angular.copy(globalModals.taskUpdateComment);
            modal.annexaFormly.model = {};
            modal.annexaFormly.model.modal_body = {task: {}};
            modal.annexaFormly.options = {};
            modal.annexaFormly.options.formState = {readOnly: false};
            modal.annexaFormly.fields[0].fieldGroup[0].templateOptions.placeholder = $filter('translate')('global.literals.update_comment_task_title');
            modal.annexaFormly.fields[0].fieldGroup[0].templateOptions.required = true;
            modal.languageColumn = factory.languageColumn;
            modal.extra = {task: task};
            modal.addTaskComment = function () {
                var taskComment = {
                    comments: modal.annexaFormly.model.modal_body.task.comments,
                    createdUser: $rootScope.LoggedUser,
                    createdDate: new Date()
                };
                if (modal.extra && modal.extra.task && modal.extra.task.id) {
                    taskComment.task = {id: modal.extra.task.id}
                    factory.addTaskComment(taskComment).then(function (data) {
                        boxFunc(taskComment, task, boxDefinition);
                        modal.close();
                    }).catch(function (msg, error) {
                        var a = 0;
                    });
                } else {
                    boxFunc(taskComment, undefined, boxDefinition);
                    modal.close();
                }

            };

            AnnexaFormlyFactory.showModal("modalUpdateComentTask", modal, modal.addTaskComment, false);
        };

        factory.AttachmentBox = {
            initialize: function (permision, newButton) {
                this.boxDefinition = new BoxDefinition('global.literals.attachments', $rootScope.LoggedUser.id);
                this.boxDefinition.addViewAndNewPremissions('create_task');
                if (permision, newButton) {
                    this.boxDefinition.addNew(this.newAttachment, this.completeNewAttachment);
                }
                return this;
            },
            boxDefinition: null,
            newAttachment: function () {
                factory.createAttachment(this.task, this, this.completedNew);
            },
            completeNewAttachment: function (data, task, boxDefinition) {
                var self = this;
                if (task) {
                    task.attachments.push(data);
                } else {
                    boxDefinition.content.push(data);
                }
            }
        };

        factory.createAttachment = function (task, boxDefinition, boxFunc) {
            var self = this;
            var data = {
                row: true,
                colClass: ' col-sm-12',
                labelClass: 'label-strong'
            };
            var modal = angular.copy(globalModals.uploadFile);
            modal.title = 'global.literals.attachDocument';

            modal.annexaFormly.model = {};
            modal.annexaFormly.model.modal_body = {};
            modal.annexaFormly.options = {};
            modal.annexaFormly.options.formState = {readOnly: false};
            modal.extra = {task: task};
            modal.addTaskAttachment = function () {
                if (modal.annexaFormly.form.$valid) {
                    if (boxFunc) {
                        var data = modal.annexaFormly.model.modal_body;
                        var send = {
                            fileName: data.file.name,
                            idTask: modal.extra.task.id,
                            file: data.file
                        };
                        data.file.upload = Upload.upload({
                            url: './api/tasks/addTaskAttachment',
                            data: send
                        });

                        data.file.upload.then(function (response) {
                            if (response.data) {
                                var taskAttachment = JSOG.decode(response.data);

                            } else {
                                self.close();
                            }
                            boxFunc(taskAttachment, task, boxDefinition);
                            modal.close();
                        }, function (response) {
                            modal.close();
                        }, function (evt) {
                            var a = 0;
                        });
                    }
                }
            };
            AnnexaFormlyFactory.showModal("modalUpdateAttachmentTask", modal, modal.addTaskAttachment, false);
        };

        factory.downloadAttach = function (id, fileName, mimetype) {
            var href = "./api/repodocs/downloaddocmanager?id=" + id + '&fileName=' + fileName + '&mimetype=' + mimetype;
            $http.get(href, {responseType: 'arraybuffer'})
                .success(function (data, status, headers) {
                    headers = headers();
                    var contentType = headers['content-type'];
                    var file = new Blob([data], {type: contentType});
                    if (window.navigator.msSaveOrOpenBlob) {
                        try {
                            window.navigator.msSaveOrOpenBlob(file, fileName);
                        } catch (e) {
                            console.log(e.message);
                        }
                    } else {
                        var linkElement = document.createElement('a');
                        try {
                            var fileURL = URL.createObjectURL(file);
                            linkElement.setAttribute('href', fileURL);
                            linkElement.setAttribute("download", fileName);
                            var clickEvent = new MouseEvent("click", {
                                "view": window,
                                "bubbles": true,
                                "cancelable": false
                            });
                            linkElement.dispatchEvent(clickEvent);
                        } catch (ex) {
                            console.log(ex);
                        }
                    }
                }).error(function (error) {
            });
        }

        factory.rejectTask = function (task, headerService, state, tableTask) {
            var modal = angular.copy(globalModals.taskUpdateComment);
            modal.title = 'global.literals.rejectTask';
            modal.annexaFormly.model = {};
            modal.annexaFormly.model.modal_body = {task: task};
            modal.annexaFormly.options = {};
            modal.annexaFormly.options.formState = {readOnly: false};
            modal.annexaFormly.fields[0].fieldGroup[0].templateOptions.placeholder = $filter('translate')('global.literals.commentReject');
            modal.annexaFormly.fields[0].fieldGroup[0].templateOptions.required = true;
            var rejectTaskComplete = function () {
                if (modal.annexaFormly.model.modal_body.task && modal.annexaFormly.model.modal_body.task.comments) {
                    modal.annexaFormly.model.modal_body.task.endedTask = true;
                    modal.annexaFormly.model.modal_body.task.endDate = new Date();
                    modal.annexaFormly.model.modal_body.task.finishOk = false;

                    factory.updateTask(modal.annexaFormly.model.modal_body.task, './api/tasks/rejectTask').then(function (data) {
                        if (tableTask) {
                            tableTask.reloadInternalData(true, true);
                        }else{
                            headerService.changeState(state.current);
                            state.reload();
                        }
                        task = JSOG.decode(data);
                        modal.close();
                    }).catch(function (msg, error) {
                        if (tableTask) {
                            tableTask.reloadInternalData(false);
                        }
                        modal.close();
                    });
                }
            };
            AnnexaFormlyFactory.showModal("modalUpdateComentTask", modal, rejectTaskComplete, false);
        }


        factory.finishTask = function (task, headerService, state, tableTask) {
            var modal = angular.copy(globalModals.taskUpdateComment);
            modal.title = 'global.literals.finishTask';
            modal.annexaFormly.model = {};
            modal.annexaFormly.model.modal_body = {task: task};
            modal.annexaFormly.options = {};
            modal.annexaFormly.options.formState = {readOnly: false};
            modal.annexaFormly.fields[0].fieldGroup[0].templateOptions.placeholder = $filter('translate')('global.literals.commenFinished');
            var rejectTaskComplete = function () {
                if (modal.annexaFormly.model.modal_body.task) {
                    modal.annexaFormly.model.modal_body.task.endedTask = true;
                    modal.annexaFormly.model.modal_body.task.endDate = new Date();
                    modal.annexaFormly.model.modal_body.task.finishOk = true;

                    factory.updateTask(modal.annexaFormly.model.modal_body.task, './api/tasks/finishTask').then(function (data) {
                        if (tableTask) {
                            tableTask.reloadInternalData(false);
                        }else{
                            headerService.changeState(state.current);
                            state.reload();
                        }
                        task = JSOG.decode(data);
                        modal.close();
                    }).catch(function (msg, error) {
                        if (tableTask) {
                            tableTask.reloadInternalData(false);
                        }
                        modal.close();
                    });
                }
            };
            AnnexaFormlyFactory.showModal("modalUpdateComentTask", modal, rejectTaskComplete, false);
        }

        factory.initTask = function (task, headerService, state, tableTask) {
            if (task != -1) {
                task.startedUser = {id: $rootScope.LoggedUser.id};
                task.startDate = new Date();
            }
            factory.updateTask(task, './api/tasks/startTask').then(function (data) {
                if (tableTask) {
                    tableTask.reloadInternalData(false);
                    state.reload();
                }else{
                    headerService.changeState(state.current);
                    state.reload();
                }
                task = JSOG.decode(data);
            }).catch(function (msg, error) {
                if (tableTask) {
                    tableTask.reloadInternalData(false);
                }
            });
        }

        factory.reopenTask = function (taskCommnet) {
            var deferred = $q.defer();

            $http({
                url: './api/tasks/reopen',
                method: 'POST',
                data: JSOG.encode(taskCommnet)
            }).then(function (data) {
                deferred.resolve(data.data)
            }).catch(function (error) {
                deferred.reject(error);
            })

            return deferred.promise;
        };

        factory.reassignTask = function (taskCommnet, userId, taskId) {
            var deferred = $q.defer();

            $http({
                url: './api/tasks/reasignTask/'+taskId+'/'+userId,
                method: 'PUT',
                data: JSOG.encode(taskCommnet)
            }).then(function (data) {
                deferred.resolve(data.data)
            }).catch(function (error) {
                deferred.reject(error);
            })

            return deferred.promise;
        };
        return factory;
    }]);