/**
 * Created by osirvent on 27/01/2017.
 */
angular.module('annexaApp')
    .factory('ABMInspectorFactory', ['$filter', 'ABMInspectorHelperFactory', 'Language', '$timeout', 'ABMShapesFactory', '$rootScope', 'ABMModelFactory', function($filter, ABMInspectorHelperFactory, Language, $timeout, ABMShapesFactory, $rootScope, ABMModelFactory) {
        var factory = {};

        factory.graph = undefined;
        factory.scope = undefined;

        //region General

        factory.InitializeInspectorEvents = function(graph, scope) {
            factory.graph = graph;
            factory.scope = scope;

            $rootScope.$on("BPMInspectorChange", function (event, args) {
                if(!args.origin) {
                    procedureEventChange(args.field, args.value);
                } else {
                    switch(args.origin.type) {
                        case 'transaction':
                            transactionEventChange(args.origin.id, args.field, args.value);
                            break;
                        default:
                            procedureEventChange(args.field, args.value);
                    }
                }
            });
        }

        //endregion

        //region Events

        var procedureEventChange = function(field, value) {
            switch(field) {
                case 'showClassification':
                    if(value == 'DISABLED') {
                        angular.element('#procedureClassifications').hide();
                    } else {
                        angular.element('#procedureClassifications').show();
                    }
                    break;
            }
        };

        var transactionEventChange = function(cellId, field, value) {
            if(factory.graph && factory.scope.model.transactions[cellId]) {
                var cell = factory.graph.getCell(cellId);

                if(cell) {
                    switch (field) {
                        case 'assignationType':
                            ABMShapesFactory.setCellType(cell, value, ABMModelFactory.getTramitationType(cell));
                            if(value == ABMModelFactory.AT_NONGUIDED) {
                                angular.element('#subprocedureNameBPMField').show();
                                angular.element('#subprocedureBPMField').hide();
                            } else {
                                angular.element('#subprocedureBPMField').show();
                                angular.element('#subprocedureNameBPMField').hide();
                            }
                            break;
                        case 'tramitationType':
                            ABMShapesFactory.setCellType(cell, ABMModelFactory.getAssignationType(cell), value);
                            break;
                        case 'subprocedure':
                            var selected = $linq(factory.scope.options.subprocedure).singleOrDefault(undefined, "x => x.id == " + value);

                            if(selected) {
                               ABMShapesFactory.setCellContent(cell, selected[Language.getActiveColumn()]);
                            }
                            break;
                        case 'subprocedureName':
                            ABMShapesFactory.setCellContent(cell, Language.getActiveColumn());
                            break;
                        case 'transactionType':
                            var transType = $linq(factory.scope.options.transactionType).singleOrDefault(undefined, "x => x.id == " + value);
                            if(transType){
                                ABMModelFactory.setTransactionModel(cell, 'maxDays', transType.days);
                                ABMModelFactory.setTransactionModel(cell, 'daysComputeGlobal', ((transType.countDays)?'YES':'NO'));
                            }
                            break;
                        case 'assignationTypeTram':
                            if(value == ABMModelFactory.AT_NONGUIDED){
                                ABMModelFactory.setTransactionModel(cell, 'assignationType', ABMModelFactory.AT_NONGUIDED);
                                ABMShapesFactory.setCellType(cell, ABMModelFactory.AT_NONGUIDED, ABMModelFactory.getTramitationType(cell));
                            }else{
                                ABMModelFactory.setTransactionModel(cell, 'assignationType', ABMModelFactory.AT_GUIDED);
                                ABMShapesFactory.setCellType(cell, ABMModelFactory.AT_GUIDED, ABMModelFactory.getTramitationType(cell));
                            }
                            if(value == ABMModelFactory.AT_GUIDED){
                                ABMModelFactory.setTransactionModel(cell, 'userInitialStateInheritance', false);
                                angular.element('#processProfilesBPMField').show();
                                angular.element('#userStateInheritanceBPMField').hide();
                            }else if(value == ABMModelFactory.AT_INHERITED){
                                ABMModelFactory.setTransactionModel(cell, 'userInitialStateInheritance', false);
                                angular.element('#processProfilesBPMField').hide();
                                angular.element('#userStateInheritanceBPMField').show();
                            }else{
                                ABMModelFactory.setTransactionModel(cell, 'userInitialStateInheritance', true);
                                angular.element('#processProfilesBPMField').hide();
                                angular.element('#userStateInheritanceBPMField').hide();
                            }
                            break;
                    }
                }

                factory.scope.model.transactions[cellId].graphNodeType = ABMShapesFactory.getShapeType(cell);
            }
        }

        //endregion

        //region Inspector Creation

        factory.ElementInspector = function(cell, scope, $inspectorHolder) {
            if(_.contains([ABMShapesFactory.TI, ABMShapesFactory.TEN, ABMShapesFactory.TEF, ABMShapesFactory.TFN, ABMShapesFactory.TFF, ABMShapesFactory.TI, ABMShapesFactory.SN, ABMShapesFactory.SFN], ABMShapesFactory.getShapeType(cell))) {
                factory.TransactionInspector(cell, scope, $inspectorHolder);
            } else {
                factory.GatewayInspector(cell, scope, $inspectorHolder);
            }
        };

        //region Procedure

        factory.ModelerInspector = function(cell, scope, $inspectorHolder) {
            $timeout(function(){
                joint.ui.Inspector.create($inspectorHolder, {
                    cell: cell,
                    inputs: {
                        procedureType: { type: 'UISelect', label: 'global.literals.type', group: 'basicinfo', index: 1, group: 'basicinfo', scope: scope, field: 'procedureType', required: true, showClear: false },
                        language1: { type: 'InputLanguage', label: 'global.literals.name', group: 'basicinfo', index: 2, scope: scope, field: '', required: true },
                        descriptionLanguage1: { type: 'InputLanguage', label: 'global.literals.description', group: 'basicinfo', index: 3, scope: scope, field: 'descriptionLanguage', required: true },
                        acronym: { type: 'InputText', label: 'global.literals.acronym', group: 'basicinfo', index: 4, scope: scope, maxlength: 20, field: 'acronym' },
                        showSubject: { type: 'UISelect', label: 'global.commonAdmin.modal.unguidedProcedure.showSubject', group: 'basicinfo', index: 5, scope: scope, field: 'showSubject', required: true, showClear: false },
                        maxDays: { type: 'InputNumber', label: 'global.literals.days', group: 'basicinfo', index: 6, scope: scope, field: 'maxDays', min: 1},
                        //tramitationTypeProcedure: { type: 'UISelect', label: 'global.literals.tramitationType', group: 'basicinfo', index: 8, group: 'basicinfo', scope: scope, field: 'tramitationTypeProcedure', required: true, showClear: false, labelProp: Language.getActiveColumn() },
                        allowUserChangeMaxDays: { type: 'UISelect', label: 'global.literals.allowUserChangeMaxDays', group: 'basicinfo', index: 7, scope: scope, field: 'allowUserChangeMaxDays', required: true, showClear: false },
                        active: { type: 'UISelect', label: 'global.literals.active', group: 'basicinfo', index: 8, scope: scope, field: 'active', required: true, showClear: false },
                        family: { type: 'SelectTree', label: 'global.literals.family', group: 'classifications', index: 1, scope: scope, field: 'family', required: true },
                        archiveClassification: { type: 'SelectTree', label: 'global.literals.classificationBox', group: 'classifications', index: 2, scope: scope, field: 'archiveClassification' },
                        showClassification: { type: 'UISelect', label: 'global.commonAdmin.modal.unguidedProcedure.showClassification', group: 'classifications', index: 3, scope: scope, field: 'showClassification', required: true, showClear: false },
                        procedureClassifications: { type: 'LanguageFieldSet', label: 'global.literals.classification', group: 'classifications', index: 4, scope: scope, field: 'procedureClassifications', placeholder: 'global.commonAdmin.modal.unguidedProcedure.placeholderClassification', hidden: true },
                        procedureStartProfiles: { type: 'UISelectMultiple', label: 'global.literals.initialize', group: 'profiles', index: 1, scope: scope, field: 'procedureStartProfiles', required: true, labelProp: Language.getActiveColumn() },
                        procedureViewProfiles: { type: 'UISelectMultiple', label: 'global.literals.showProcedure', group: 'profiles', index: 2, scope: scope, field: 'procedureViewProfiles', required: false, labelProp: Language.getActiveColumn() },
                        procedureResponsibleProfiles: { type: 'UISelectMultiple', label: 'global.literals.responsible', group: 'profiles', index: 3, scope: scope, field: 'procedureResponsibleProfiles', required: false, labelProp: Language.getActiveColumn() },
                        informationLanguage1: { type: 'InputLanguage', label: 'global.literals.information', group: 'others', index: 1, scope: scope, field: 'informationLanguage' }
                    },
                    groups: {
                        basicinfo: { label: $filter('translate')('global.literals.basicInfo'), index: 1 },
                        classifications: { label: $filter('translate')('global.literals.classifications'), index: 2, closed: true },
                        profiles: { label: $filter('translate')('global.literals.profiles'), index: 3, closed: true },
                        others: { label: $filter('translate')('global.literals.others'), index: 4, closed: true }
                    },
                    renderFieldContent: ABMInspectorHelperFactory.renderCustomFields
                });
            });

            angular.element('.inspector-container').removeClass('inspector-no-data');
            angular.element('.paper-container').removeClass('paper-no-data');
        };

        //endregion

        //region Transaction

        var isInGateway = function(cell) {
            var gatewayPath = cell.get('gatewayPath');

            if(!gatewayPath) {
                return false;
            }

            var initial = $linq(factory.graph.getCells()).singleOrDefault(undefined, function(x) {
                return ABMShapesFactory.getShapeType(x) == ABMShapesFactory.TI;
            });

            if(!initial) {
                return false;
            }

            return initial.get('gatewayId') != gatewayPath.gatewayId;
        }

        factory.TransactionInspector = function(cell, scope, $inspectorHolder) {
            var groups = {};
            var inputs = {};

            var closed = false;
            var shapeType = ABMShapesFactory.getShapeType(cell);
            var assignationTypeTramSelect = ABMModelFactory.getTransactionModel(cell, 'assignationTypeTram');
            if(_.contains([ ABMShapesFactory.TEN, ABMShapesFactory.TEF, ABMShapesFactory.TFN, ABMShapesFactory.TFF, ABMShapesFactory.SN, ABMShapesFactory.SFN], shapeType)) {
                groups.elementinfo= { label: $filter('translate')('global.literals.elementInfo'), index: 1, closed: closed };
                closed = true;

                if(!isInGateway(cell) && shapeType != ABMShapesFactory.SN  && shapeType != ABMShapesFactory.SFN) {
                    inputs.tramitationType = {
                        type: 'UISelect',
                        label: 'global.literals.finallyType',
                        group: 'basicinfo',
                        index: 2,
                        scope: scope,
                        field: 'tramitationType',
                        required: true,
                        showClear: false,
                        origin: {type: 'transaction', id: cell.id}
                    };
                }
            }

            if(shapeType != ABMShapesFactory.SN && shapeType != ABMShapesFactory.SFN) {
                if(factory.scope && factory.scope.model  && factory.scope.model.transactions){
                    var trans = [];
                    for(var key in factory.scope.model.transactions){
                        if(factory.scope.model.transactions[key].tramitationType == 'INITIAL'){
                            trans.push({id:factory.scope.model.transactions[key].graphNodeId, language1:factory.scope.model.transactions[key].language1, language2:factory.scope.model.transactions[key].language2, language3:factory.scope.model.transactions[key].language3});
                        }
                    }
                    factory.scope.options.userStateInheritance = trans;
                }
                inputs.transactionType = { type: 'UISelect', label: 'global.literals.transactionType', group: 'basicinfo', index: 1, scope: scope, field: 'transactionType', required: true, labelProp: Language.getActiveColumn(), showClear: false, origin: { type: 'transaction', id: cell.id } };
                inputs.language1 = { type: 'InputLanguage', label: 'global.literals.name', group: 'basicinfo', index: 3, scope: scope, field: '', origin: { type: 'transaction', id: cell.id }, required: true };
                inputs.descriptionLanguage1 = { type: 'InputLanguage', label: 'global.literals.description', group: 'basicinfo', index: 4, scope: scope, field: 'descriptionLanguage', origin: { type: 'transaction', id: cell.id }, required: false };
                inputs.maxDays = { type: 'InputNumber', label: 'global.literals.days', group: 'basicinfo', index: 5, scope: scope, field: 'maxDays', min: 1, origin: { type: 'transaction', id: cell.id }, required: true };
                inputs.daysComputeGlobal = { type: 'UISelect', label: 'global.literals.daysComputeGlobal', group: 'basicinfo', index: 6, scope: scope, field: 'daysComputeGlobal', required: true, showClear: false, origin: { type: 'transaction', id: cell.id } };
                //inputs.allowThirds = { type: 'UISelect', label: 'global.literals.allowThirds', group: 'basicinfo', index: 4, scope: scope, field: 'allowThirds', required: true, showClear: false, origin: { type: 'transaction', id: cell.id } };
                //inputs.allowExpedientRelations = { type: 'UISelect', label: 'global.literals.allowExpedientRelations', group: 'basicinfo', index: 5, scope: scope, field: 'allowExpedientRelations', required: true, showClear: false, origin: { type: 'transaction', id: cell.id } };
                //inputs.userInitialStateInheritance = { type: 'UISelect', label: 'global.literals.userInitialStateInheritance', group: 'permissions', index: 2, scope: scope, field: 'userInitialStateInheritance', required: true, showClear: false, origin: { type: 'transaction', id: cell.id } };
                if(shapeType != ABMShapesFactory.TI){
                    inputs.assignationTypeTram = { type: 'UISelect', label: 'global.literals.assignationType', group: 'permissions', index: 1, scope: scope, field: 'assignationTypeTram', required: true, showClear: false, origin: { type: 'transaction', id: cell.id }};
                    inputs.processProfiles = { type: 'UISelectMultiple', label: 'global.literals.processProfiles', group: 'permissions', index: 2, scope: scope, field: 'processProfiles', required: true, labelProp: Language.getActiveColumn(), origin: { type: 'transaction', id: cell.id } };
                    inputs.userStateInheritance = { type: 'UISelect', label: 'global.literals.userInitialStateInheritance', group: 'permissions', index: 3, scope: scope, field: 'userStateInheritance', required: true, labelProp: Language.getActiveColumn(), showClear: false, origin: { type: 'transaction', id: cell.id }};
                }
                //inputs.viewProfiles = { type: 'UISelectMultiple', label: 'global.literals.viewProfiles', group: 'permissions', index: 3, scope: scope, field: 'viewProfiles', required: false, labelProp: Language.getActiveColumn(), origin: { type: 'transaction', id: cell.id } };
                inputs.customFields = { type: 'CustomFields', label: '', group: 'cusfields', index: 1, scope: scope, field: 'customFields', origin: { type: 'transaction', id: cell.id }};
                inputs.informationLanguage1 = { type: 'InputLanguage', label: 'global.literals.information', group: 'others', index: 1, scope: scope, field: 'informationLanguage', origin: { type: 'transaction', id: cell.id } };
            } else {
                inputs.assignationType = { type: 'UISelect', label: 'global.literals.type', group: 'basicinfo', index: 1, scope: scope, field: 'assignationType', required: true, showClear: false, origin: { type: 'transaction', id: cell.id }};
                inputs.subprocedureName = { type: 'InputLanguage', label: 'global.literals.name', group: 'basicinfo', index: 2, scope: scope, field: 'subprocedureName', required: true, origin: { type: 'transaction', id: cell.id }};
                inputs.subprocedure = { type: 'UISelect', label: 'global.literals.name', group: 'basicinfo', index: 3, scope: scope, field: 'subprocedure', required: true, showClear: false, origin: { type: 'transaction', id: cell.id }, labelProp: Language.getActiveColumn()};
            }

            groups.basicinfo = { label: $filter('translate')('global.literals.basicInfo'), index: 2 };
            groups.permissions = { label: $filter('translate')('global.literals.permissions'), index: 3, closed: true };
            groups.cusfields = { label: $filter('translate')('global.literals.customFields'), index: 4, closed: true};
            groups.others = { label: $filter('translate')('global.literals.others'), index: 5, closed: true };

            $timeout(function(){
                joint.ui.Inspector.create($inspectorHolder, {
                    cell: cell,
                    inputs: inputs,
                    groups: groups,
                    renderFieldContent: ABMInspectorHelperFactory.renderCustomFields,
                });

                angular.element('.inspector-container').removeClass('inspector-no-data');
                angular.element('.paper-container').removeClass('paper-no-data');
                if(shapeType == ABMShapesFactory.SN){
                    angular.element('#subprocedureBPMField').show();
                    angular.element('#subprocedureNameBPMField').hide();
                }else if(shapeType == ABMShapesFactory.SFN){
                    angular.element('#subprocedureNameBPMField').show();
                    angular.element('#subprocedureBPMField').hide();
                    cell.attr({'.outer': {'stroke-width': 1}});
                    cell.attr({'.outer': {'stroke-dasharray': 'none'}});
                }

                if(assignationTypeTramSelect == ABMModelFactory.AT_GUIDED){
                    angular.element('#processProfilesBPMField').show();
                    angular.element('#userStateInheritanceBPMField').hide();
                }else if(assignationTypeTramSelect == ABMModelFactory.AT_INHERITED){
                    angular.element('#processProfilesBPMField').hide();
                    angular.element('#userStateInheritanceBPMField').show();
                }else{
                    angular.element('#processProfilesBPMField').hide();
                    angular.element('#userStateInheritanceBPMField').hide();
                }
            });


        };

        //endregion

        //region Gateway

        factory.GatewayInspector = function(cell, scope, $inspectorHolder) {
            angular.element('.inspector-container').addClass('inspector-no-data');
            angular.element('.paper-container').addClass('paper-no-data');
        }

        //endregion

        //endregion

    return factory;
}])
    .factory('ABMInspectorHelperFactory',['$compile', 'Language', '$rootScope', 'CommonAdminModals', 'AnnexaFormlyFactory', 'CommonAdminService', 'HelperService', 'ErrorFactory', 'AdminFactory', function($compile, Language, $rootScope, CommonAdminModals, AnnexaFormlyFactory, CommonAdminService, HelperService, ErrorFactory, AdminFactory) {
        var factory = {};

        //region Generals

        var compiledElement = function(template, scope) {
            var compiled = $compile(template)(scope);

            var $element = $(compiled);
            return $element;
        }

        var getContainerWithContent = function (label, field, content, compile, scope) {
            var template = getContainerTemplate(label, field);

            template = template.replace('[CONTENT]', content);

            if(compile) {
                return compiledElement(template, scope);
            } else {
                return template;
            }
        }

        var getModel = function(origin, field, language) {
            var defaultModel = !language ? 'model[\'' + field + '\']' : 'model';

            if(origin && origin.type && origin.id) {
                switch (origin.type) {
                    case 'transaction':
                        return !language ? 'model.transactions[\'' + origin.id + '\'][\'' + field + '\']' : 'model.transactions[\'' + origin.id + '\']';
                }
            }

            return defaultModel;
        }
        //endregion

        //region Templates

        var getContainerTemplate = function(label, field, hidden) {
            var template = '';
            var id = field+"BPMField";
            template += '<div class="row" id="'+id+'">';
            template += '   <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">';
            template += '       <div class="row">';
            template += '           <div class="col-xs-12">';
            template += '               <label class="label-strong small" small m-b-0"><span translate="' + label + '">' + label + '</span>';
            template += '           </div>';
            template += '           <div class="col-xs-12">';
            template += '               [CONTENT]';
            template += '           </div>';
            template += '       </div>';
            template += '   </div>';
            template += '</div>';

            return template;
        };

        var getValidation = function(required, maxlength, min, max, languageRequired, languageRequiredOrigin) {
            var tag = 'validation="';
            if(languageRequired) {
                if(required) {
                    if (!languageRequiredOrigin) {
                        languageRequiredOrigin = {type: 'Procedure', id: ''};
                    }
                    return 'validation="custom:angularInputLanguageValidation(\'' + languageRequired + '\',\'' + languageRequiredOrigin.id + '\')"';
                } else {
                    return '';
                }
            }

            var validation = tag;

            if(required) {
                if(validation == tag) {
                    validation += 'required:alt={{ \'global.validation.required\' | translate }}';
                } else {
                    validation += '|required:alt={{ \'global.validation.required\' | translate }}';
                }
            }

            if(maxlength) {
                if(validation == tag) {
                    validation += 'max_len:' + maxlength;
                } else {
                    validation += '|max_len:' + maxlength;
                }
            }

            if(min) {
                if(validation == tag) {
                    validation += 'min_num:' + min;
                } else {
                    validation += '|min_num:' + min;
                }
            }

            if(max) {
                if(validation == tag) {
                    validation += 'max_num:' + max;
                } else {
                    validation += '|max_num:' + max;
                }
            }

            if(validation == tag) {
                validation = '';
            } else {
                validation += '"';
            }

            return validation;
        };

        var getInputTemplate = function(type, field, origin, maxlength, min, max, required) {
            var inputClass = 'form-control';

            if(type == 'number') {
                inputClass += ' num-20-pc';
            }

            var template = '<input type="' + type + '" class="' + inputClass + '" ng-model="' + getModel(origin,field)  + '" id="' + field + '" name="' + field + '"';

            template += getValidation(required, maxlength, min, max) + '>';

            return template;
        };

        var getUISelectTemplate = function (field, origin, labelProp, valueProp, required, searchEnabled, showClear, options) {
            var template = '';

            template += '<a href="" tabindex="0" class="bt-deselect" ng-click="clearUISelect($event,' + getModel(origin, field) + ',$select)" ng-show="' + getModel(origin, field)  + ' && ' + showClear + '" role="button">';
            template += '   <i class="fa fa-remove" aria-hidden="true"></i><span class="sr-only">{{ \'global.literals.remove\' | translate }}</span>';
            template += '</a>';
            template += '<ui-select data-ng-model="' + getModel(origin, field) + '" data-ng-required="' + required + '" search-enabled="' + searchEnabled + '" theme="bootstrap" on-select="onUISelect($select, $model, \'' + (origin == undefined ? '' : origin.type) + '\',\'' + (origin == undefined ? '' : origin.id) + '\',\'' + field + '\')" name="' + field + '" ' + getValidation(required) + '>';
            template += '   <ui-select-match placeholder="">';
            template += '       <span>{{$select.selected[\'' + labelProp + '\'] | translate}}</span>';
            template += '   </ui-select-match>'
            template += '   <ui-select-choices data-repeat="option[\'' + valueProp + '\'] as option in options[\'' + field + '\'] | filter: $select.search">';
            template += '       <div ng-bind-html="option[\'' + labelProp + '\'] | translate | highlight: $select.search"></div>';
            template += '   </ui-select-choices>';
            template += '</ui-select>';

            return template;
        };

        var getUISelectMultipleTemplate = function (field, origin, labelProp, valueProp, required, searchEnabled) {
            var template = '';

            template += '<ui-select multiple data-ng-model="' + getModel(origin, field) + '" data-ng-required="' + required + '" search-enabled="' + searchEnabled + '" theme="bootstrap" on-select="onUISelect($select, $model, \'' + (origin == undefined ? '' : origin.type) + '\',\'' + (origin == undefined ? '' : origin.id) + '\',\'' + field + '\')" name="' + field + '" ' + getValidation(required) + '>';
            template += '   <ui-select-match placeholder="">';
            template += '       <span style="display: inline;">{{$item[\'' + labelProp + '\']}}</span>';
            template += '   </ui-select-match>';
            template += '   <ui-select-choices data-repeat="option[\'' + valueProp + '\'] as option in (options[\'' + field + '\'] | filter: {'+labelProp+': $select.search})">';
            template += '       <div ng-bind-html="option[\'' + labelProp + '\'] | translate | highlight: $select.search"></div>';
            template += '   </ui-select-choices>';
            template += '</ui-select>';

            return template;
        };

        var getSelectTreeTemplate = function (field, origin, onlyLastLevelClick, required) {
            var template = '';

            template += '<ui-tree-select ng-model="' + getModel(origin, field) + '" data="options[\'' + field + '\']" required="' + required + '" only-last-level-click="' + onlyLastLevelClick + '" name="' + field + '" ' + getValidation(required) + '>';
            template += '</ui-tree-select>';

            return template;
        };

        //endregion

        //region Field Types

        var CustomFields = function (scope, origin) {
            if(origin.type != 'transaction') {
                return '';
            }

            if(!origin.id) {
                return '';
            }
            /**
             * MODIFICAT PER APOP 14/11/2017 (hidden field)
             * modificat per APOP 11/01/2018 (no editable)
             */
            /********************************************************************/
            var  customFieldRequiredList = [
                {id:'OPTIONAL', description:'global.literals.OPTIONAL'},
                {id:'REQUIRED', description:'global.literals.REQUIRED'},
                {id:'HIDDEN_FIELD', description:'global.literals.HIDDEN_FIELD'},
                {id:'NO_EDITABLE', description:'global.literals.NO_EDITABLE'}
            ];

            var template = '<annexa-custom-field-definition custom-fields="' + getModel(origin, 'customFields', false)+'" type="PROCEDURE" update-on-change="false" parent-property="transaction" parent-id="' + getModel(origin, 'id', false)+'"/>';
            return compiledElement(template, scope);
        }
        var InputLanguage = function (scope, field, label, origin, required) {
            var template = '<div id="'+field+'BPMField"><languageinputs  model-language="' + getModel(origin, field, true) + '" enabled-field="true" model-field="' + field + '" label="' + label + '" ng-model="' + getModel(origin, field, true) + '" name="' + (field == undefined || field == "" ? "language" : field) + '" ' + getValidation(required,undefined,undefined,undefined, field == undefined || field == "" ? "language" : field, origin) + '/><div>';
            return compiledElement(template, scope);
        };

        var InputText = function(scope, field, label, origin, maxlength, min, max, required) {
            return getContainerWithContent(label, field, getInputTemplate('text', field, origin, maxlength, min, max, required), true, scope);
        };

        var InputNumber = function (scope, field, label, origin, min, max, required) {
            return getContainerWithContent(label, field, getInputTemplate('number', field, origin, false, min, max, required), true, scope);
        };

        var UISelect = function (scope, field, label, origin, labelProp, valueProp, required, searchEnabled, showClear, options) {
            scope.clearUISelect = function($event, model, $select) {
                $event.stopPropagation();
                model = undefined;
                if($select) {
                    $select.selected = undefined;
                    $select.search = undefined;
                }
            };

            scope.onUISelect = function($item, $model, origin_type, origin_id, field) {
                $rootScope.$emit('BPMInspectorChange', { origin: { type: origin_type, id: origin_id }, field: field, value: $model });
            }

            return getContainerWithContent(label, field, getUISelectTemplate(field, origin, labelProp, valueProp, required, searchEnabled, showClear, options), true, scope);
        };

        var UISelectMultiple = function(scope, field, label, origin, labelProp, valueProp, required, searchEnabled) {
            scope.onUISelect = function($item, $model, origin_type, origin_id, field) {
                $rootScope.$emit('BPMInspectorChange', { origin: { type: origin_type, id: origin_id }, field: field, value: $model });
            }

            return getContainerWithContent(label, field, getUISelectMultipleTemplate(field, origin, labelProp, valueProp,required, searchEnabled), true, scope);
        }

        var SelectTree = function(scope, field, label, origin, onlyLastLevelClick, required) {
            return getContainerWithContent(label, field, getSelectTreeTemplate(field, origin, onlyLastLevelClick, required), true, scope);
        };

        var LanguageFieldSet = function(scope, field, label, origin, modelField, placeholder, hidden) {
            scope.addLanguageFieldSet = function(field) {
                var id = 0;

                if(scope.optionsAdd[field]) {
                    var element = {};
                    element[Language.getActiveColumn()] = scope.optionsAdd[field];
                    element.deleted = false;

                    if(scope.model[field]) {
                        scope.model[field].push(element);
                    } else {
                        scope.model[field] = [];
                        scope.model[field].push(element);
                    }

                    scope.optionsAdd[field] = '';
                }
            };

            scope.removeLanguageFieldSet = function(field, index) {
                if(scope.model[field] && scope.model[field].length > index) {
                    scope.model[field].splice(index, 1);
                }
            };

            scope.filterList = function(item) {
                if(item && item.deleted){
                    return false;
                }else{
                    return true;
                }
            };

            var template = '';
            var showFieldSet = true;
            if(scope.model.showClassification == 'DISABLED'){
                showFieldSet = false;
            }
            if(hidden && !showFieldSet) {
                template += '<fieldset id="' + field + '" class="m-b" style="display: none">';
            } else {
                template += '<fieldset id="' + field + '" class="m-b">';
            }
            template += '   <legend class="label-strong">';
            template += '       {{\'' + label + '\' | translate}}';
            template += '   </legend>';
            template += '   <div ng-model="' + getModel(origin, field, true) + '">';
            template += '       <div ng-repeat="field in ' + getModel(origin, field) + ' | filter: filterList">';
            template += '           <div class="row m-b-125">';
            template += '               <div class="col-sm-1 text-right p-x-0">';
            template += '                   <label class="col-sm-3 p-y-sm grey-pencil p-a-0">{{((field.id)? field.id : \'\')}}</label>';
            template += '               </div>';
            template += '               <div class="col-sm-10">';
            template += '                   <languageinputs model-language="field" model-field="' + modelField + '"/>';
            template += '               </div>';
            template += '               <div class="col-sm-1 p-l-0 m-t-xs">';
            template += '                   <a href="" tabindex="0" ng-show="{{!field.id}}" ng-click="removeLanguageFieldSet(\'' + field + '\', $index)" class="remove-button m-t-xs"><i class="fa fa-remove grey-pencil" aria-hidden="true"></i><span class="sr-only">{{ \'global.literals.remove\' | translate }}</span></a>';
            template += '               </div>';
            template += '           </div>';
            template += '       </div>';
            template += '       <div class="row m-b-125">';
            template += '           <div class="col-sm-1 text-right p-r-xs">';
            template += '           </div>';
            template += '           <div class="col-sm-10">';
            template += '               <input type="text" class="form-control" ng-model="optionsAdd[\'' + field + '\']" placeholder="{{\'' + placeholder + '\' | translate}}">';
            template += '           </div>';
            template += '           <div class="col-sm-1 p-l-0">';
            template += '               <a ng-click="addLanguageFieldSet(\'' + field + '\')" ng-show="optionsAdd[\'' + field + '\']" class="remove-button m-t-xs"><i class="fa fa-check grey-pencil"></i></a>';
            template += '           </div>';
            template += '       </div>';
            template += '   </div>';
            template += '</fieldset>';

            return compiledElement(template, scope);
        }

        //endregion

        //region Inspector Functions

        factory.renderCustomFields = function (options, path, value) {
            switch (options.type) {
                case 'InputLanguage':
                    return InputLanguage(options.scope, options.field, options.label, options.origin, options.required);
                case 'InputText':
                    return InputText(options.scope, options.field, options.label, options.origin, options.maxlength || false, undefined, undefined, options.required);
                case 'InputNumber':
                    return InputNumber(options.scope, options.field, options.label, options.origin, options.min || false, options.max || false, options.required);
                case 'UISelect':
                    return UISelect(options.scope, options.field, options.label, options.origin, options.labelProp || 'description', options.valueProp || 'id', options.required == undefined ? false : options.required, options.searchEnabled == undefined ? false : options.searchEnabled, options.showClear == undefined ? true : options.showClear);
                case 'UISelectMultiple':
                    return UISelectMultiple(options.scope, options.field, options.label, options.origin, options.labelProp || 'description', options.valueProp || 'id', options.required == undefined ? false : options.required, options.searchEnabled == undefined ? false : options.searchEnabled);
                case 'SelectTree':
                    return SelectTree(options.scope, options.field, options.label, options.origin, options.onlyLastLevelClick == undefined ? false : options.onlyLastLevelClick, options.required == undefined ? false : options.required);
                case 'LanguageFieldSet':
                    return LanguageFieldSet(options.scope, options.field, options.label, options.origin, options.modelField || '', options.placeholder || '', options.hidden == undefined ? false : options.hidden );
                case 'CustomFields':
                    return CustomFields(options.scope, options.origin);
            }

            var $span = $('<span>Error</span>');
            return $span;
        };


        factory.notEqual = function(cell, value, arguments) {
            return arguments.scope.model[arguments.field] != arguments.value;
        };

        //endregion

        return factory;
    }]);
