angular
    .module('annexaApp')
    .factory('GlobalDataFactory',['RestService', '$q', 'Language', '$http', 'CacheFactory', '$rootScope', function(RestService, $q, Language, $http, CacheFactory, $rootScope) {
        CacheFactory('globalDataCache', {
            maxAge: 4 * 60 * 60 * 1000, // 4 hores
            cacheFlushInterval: 8 * 60 * 60 * 1000, // 8 hores
            deleteOnExpire: 'aggressive',
            storageMode: 'sessionStorage'
        });

        var factory = {};

        factory.documentTypes = [];
        factory.allDocumentTypes = [];
        factory.templateTypes = [];
        factory.templates = [];
        factory.archiveClassifications = [];
        factory.documentStatuses = [
            { id: 'COMPLETE', name: 'COMPLETE', icon: 'dot-circle-o', style: 'success', orderStatus: 6, nameLiteral: 'global.documentState.COMPLETE' },
            { id: 'NEW', name: 'NEW', icon: 'circle-o', style: 'blue', orderStatus: 1, nameLiteral: 'global.documentState.NEW' },
            { id: 'NEW_AND_ATTACHMENT', name: 'NEW_AND_ATTACHMENT', icon: 'adjust', style: 'blue', orderStatus: 2, nameLiteral: 'global.documentState.NEW_AND_ATTACHMENT' },
            { id: 'PENDING_SIGN', name: 'PENDING_SIGN', icon: 'circle', style: 'blue', orderStatus: 3, nameLiteral: 'global.documentState.PENDING_SIGN' },
            { id: 'REJECTED', name: 'REJECTED', icon: 'circle', style: 'danger', orderStatus: 5, nameLiteral: 'global.documentState.REJECTED' },
            { id: 'SIGNED', name: 'SIGNED', icon: 'circle', style: 'success', orderStatus: 4, nameLiteral: 'global.documentState.SIGNED' }
        ];
        factory.documentViewModeTypes = [];
        factory.sections = [];
        factory.templateTree = [];
        factory.modules = [];
        factory.signActionTypes = [];
        factory.registerDiligenceTypes = [];
        factory.publishPlugins = [];
        factory.allProfiles = [];
        factory.registerEntryOffices = [];
        factory.registerEntryChannelsInput = [];
        factory.allRegisterEntryChannelsInput = [];
        factory.registerEntryChannelsOutput = [];
        factory.allRegisterEntryChannelsOutput = [];
        factory.registerEntryDocumentTypesInput = [];
        factory.registerEntryDocumentTypesOutput = [];
        factory.registerEntryClassificationsInput = [];
        factory.registerEntryClassificationsOutput = [];
        factory.familyProcedures = [];
        factory.procedures = [];
        factory.organs = [];

        factory.proposals = [];

        factory.thirdIdentificationDocumentTypes = [
            { id: 'DNI', language1: 'DNI', language2: 'DNI', language3: 'DNI' },
            { id: 'NIE', language1: 'NIE', language2: 'NIE', language3: 'NIE' },
            { id: 'NIF', language1: 'NIF', language2: 'NIF', language3: 'NIF' },
            { id: 'OTHER', language1: 'PASSPORT', language2: 'PASSPORT', language3: 'PASSPORT' },
            { id: 'OTHER', language1: 'OTHER', language2: 'OTHER', language3: 'OTHER' }
        ];
        factory.thirdTypes = [
            { id: 'PHISICAL', language1: 'PHISICAL', language2: 'PHISICAL', language3: 'PHISICAL' },
            { id: 'LEGAL', language1: 'LEGAL', language2: 'LEGAL', language3: 'LEGAL' },
            { id: 'PERSONWITHOUTLEGALPERSONALITY', language1: 'PERSONWITHOUTLEGALPERSONALITY', language2: 'PERSONWITHOUTLEGALPERSONALITY', language3: 'PERSONWITHOUTLEGALPERSONALITY' }
        ];
        factory.docModules = [
            { id: 'DOC', language1: 'DOC', language2: 'DOC', language3: 'DOC' },
            { id: 'EXPED', language1: 'EXPED', language2: 'EXPED', language3: 'EXPED' },
            { id: 'THIRDS', language1: 'THIRDS', language2: 'THIRDS', language3: 'THIRDS' },
            { id: 'REGENTRADA', language1: 'REGENTRADA', language2: 'REGENTRADA', language3: 'REGENTRADA' },
            { id: 'REGSALIDA', language1: 'REGSALIDA', language2: 'REGSALIDA', language3: 'REGSALIDA' },
            { id: 'SEC', language1: 'SEC', language2: 'SEC', language3: 'SEC' }
        ];
        factory.auditActions = [
            { id: 'CREATED', language1: 'Creat', language2: 'Creado', language3: 'CREATED', types:["DOSSIER","DOSSIER_TRANSACTION","DOCUMENT", "REGISTER_ENTRY", "DILIGENCE", "THIRD", "PROPOSAL"] },
            { id: 'VIEWED', language1: 'Vist', language2: 'Visto', language3: 'VIEWED', types:["DOCUMENT","DOSSIER","THIRD"] },
            { id: 'DOWNLOADED', language1: 'Descarregat', language2: 'Descargado', language3: 'DOWNLOADED', types:["DOCUMENT"] },
            { id: 'MODIFIED', language1: 'Modificat', language2: 'Modificado', language3: 'MODIFIED', types:["DOSSIER","DOSSIER_TRANSACTION","DOCUMENT", "REGISTER_ENTRY", "DILIGENCE", "THIRD", "PROPOSAL"] },
            { id: 'SIGN_START', language1: 'Enviat a signar', language2: 'Enviado a firmar', language3: 'SIGN_START', types:["DOCUMENT"] },
            { id: 'SIGNED', language1: 'Signat', language2: 'Firmado', language3: 'SIGNED', types:["DOCUMENT"] },
            { id: 'VALIDATED', language1: 'Validat', language2: 'Validado', language3: 'VALIDATED', types:["DOCUMENT"] },
            { id: 'REFUSED', language1: 'Rebutjat', language2: 'Rechazado', language3: 'REFUSED', types:["DOCUMENT"] },
            { id: 'REMOVED', language1: 'Eliminat', language2: 'Eliminado', language3: 'REMOVED', types:["DOSSIER","DOSSIER_TRANSACTION","DOCUMENT", "REGISTER_ENTRY", "DILIGENCE", "THIRD"] },
            { id: 'SENDMAIL', language1: 'Enviat per mail', language2: 'Enviado por mail', language3: 'SENDMAIL', types:["DOCUMENT"] },
            { id: 'EXTERNAL_EDIT_DOCUMENT', language1: 'Obert per edició online', language2: 'Abierto para edición online', language3: 'EXTERNAL_EDIT_DOCUMENT', types:["DOCUMENT"] },
            { id: 'ENDED', language1: 'Finalitzat', language2: 'Finalizado', language3: 'ENDED', types:["DOSSIER"] },
            { id: 'COMPLETE_TRANSACTION', language1: 'Tràmit finalitzat', language2: 'Trámite finalizado', language3: 'COMPLETE_TRANSACTION', types:["DOSSIER_TRANSACTION"] },
            { id: 'GO_BACKWARDS_TRANSACTION', language1: 'Rebutjar tràmit', language2: 'Rechazar trámite', language3: 'GO_BACKWARDS_TRANSACTION', types:["DOSSIER_TRANSACTION"] },
            { id: 'ACCEPT', language1: 'Acceptar', language2: 'Aceptar', language3: 'ACCEPT', types:["DILIGENCE"] },
            { id: 'SENDTO', language1: 'Enviar', language2: 'Enviar', language3: 'SENDTO', types:["DILIGENCE"] },
            { id: 'REOPEN', language1: 'Reobrir', language2: 'Reabrir', language3: 'REOPEN', types:["DOSSIER_TRANSACTION"] },
            { id: 'AUTHENTIC_COPY', language1: 'Copia autèntica', language2: 'Copia auténtica', language3: 'AUTHENTIC_COPY', types:["DOCUMENT"] },
            { id: 'DRAFT', language1: 'Tornar a esborrany', language2: 'Devolver a borrador', language3: 'DRAFT', types:["DOCUMENT"] },
            { id: 'DEFINITIVE', language1: 'Definitiu', language2: 'Definitivo', language3: 'DEFINITIVE', types:["DOCUMENT"] },
            { id: 'ENI', language1: 'Generat ENI', language2: 'Generado ENI', language3: 'ENI', types:["DOCUMENT"] },
            { id: 'PENDING', language1: 'Pendent', language2: 'Pendiente', language3: 'PENDING', types:["PROPOSAL"] },
            { id: 'ACCEPTED', language1: 'Acceptada', language2: 'Aceptada', language3: 'ACCEPTED', types:["PROPOSAL"] },
            { id: 'CANCELLED', language1: 'Cancel·lada', language2: 'Cancelada', language3: 'CANCELLED', types:["PROPOSAL"] },
            { id: 'AMENDMENT', language1: 'Esmenada', language2: 'Enmendada', language3: 'AMENDMENT', types:["PROPOSAL"] }
        ];
        factory.auditTypes = [
            { id: 'DOCUMENT', language1: 'Document', language2: 'Documento', language3: 'DOCUMENT' },
            { id: 'REGISTER_ENTRY', language1: 'Registre', language2: 'Registro', language3: 'REGISTER_ENTRY' },
            { id: 'THIRD', language1: 'Tercers', language2: 'Terceros', language3: 'THIRD' },
            { id: 'DOSSIER', language1: 'Expedient', language2: 'Expediente', language3: 'DOSSIER' },
            { id: 'DOSSIER_TRANSACTION', language1: 'Tràmit', language2: 'Trámite', language3: 'DOSSIER_TRANSACTION' },
            { id: 'TASK', language1: 'Tasques', language2: 'Tareas', language3: 'TASK' },
            { id: 'DILIGENCE', language1: 'Diligència', language2: 'Diligencia', language3: 'DILIGENCE' }
        ];
        factory.notificationTypes = [];
        factory.presetSignCircuit = [];
        factory.entryTypes = [
            { id: 'INPUT', name:'global.literals.INPUT' },
            { id: 'OUTPUT', name: 'global.literals.OUTPUT' }
        ]
        factory.documentRelationTypes = [
            { id: 'DOCUMENT', language1: 'Document', language2: 'Documento', language3: 'DOCUMENT' },
            { id: 'INTERNAL_DOCUMENT', language1: 'Document intern', language2: 'Documento interno', language3: 'INTERNAL_DOCUMENT' },
            { id: 'WORK_DOCUMENT', language1: 'Document de treball', language2: 'Documento de trabajo', language3: 'WORK_DOCUMENT' }
        ]
        factory.loadGlobalData = function() {

            var fixDocumentTypes = function(documentTypes) {
                var fixedDocumentTypes = [];

                _.forEach(documentTypes, function (item) {
                    _.forEach(item.customFields, function(cf, index) {
                        item.customFields[index].documentType = undefined;
                    });

                    fixedDocumentTypes.push({
                        id: item.id,
                        isPublic: item.isPublic,
                        documentFileAttachment: item.documentFileAttachment,
                        documentTemplateSelection: item.documentTemplateSelection,
                        archiveClassification: item.archiveClassification,
                        language1: item.language1,
                        language2: item.language2,
                        language3: item.language3,
                        customFields: item.customFields,
                        acronym: item.acronym,
                        profiles: item.profiles,
                        eniOrigenCiudadanoAdministracion: item.eniOrigenCiudadanoAdministracion,
                        tipoDocumental: item.tipoDocumental,
                        allowGenerateFromContentDocumentTypes: item.allowGenerateFromContentDocumentTypes
                    });
                });

                return fixedDocumentTypes;
            };
            
            var promises = [];

            if(factory.documentTypes.length == 0) {
                promises.push(RestService.findAll('DocumentType', undefined, CacheFactory.get('globalDataCache')));
            }

            if(factory.templateTypes.length == 0) {
                promises.push(RestService.findAll('TemplateType'));
            }

            if(factory.templates.length == 0) {
                promises.push(RestService.findAll('Template'));
            }

            if(factory.archiveClassifications.length == 0) {
                promises.push(RestService.findAll('ArchiveClassification', undefined, CacheFactory.get('globalDataCache')));
            }

            if(factory.documentViewModeTypes.length == 0) {
                promises.push(RestService.findAll('documentViewModeType', undefined, CacheFactory.get('globalDataCache')));
            }

            if(factory.sections.length == 0) {
                promises.push(RestService.findAll('Section', undefined, CacheFactory.get('globalDataCache')));
            }

            if(factory.modules.length == 0) {
                promises.push(RestService.findAll('Module', undefined, CacheFactory.get('globalDataCache')));
            }

            if(factory.signActionTypes.length == 0) {
                promises.push(RestService.findAll('SignActionType', undefined, CacheFactory.get('globalDataCache')));
            }

            if(factory.registerDiligenceTypes.length == 0) {
                promises.push(RestService.findAll('RegisterDiligenceType', undefined, CacheFactory.get('globalDataCache')));
            }

            if(factory.publishPlugins.length == 0) {
                promises.push($http({ method: 'GET', url: './api/plugin/publish/list', headers: {'Content-Type': 'application/json'}, cache: CacheFactory.get('globalDataCache') }));
            }

            if(factory.allProfiles.length == 0) {
                promises.push(RestService.findAll('Profile', 'allProfiles', CacheFactory.get('globalDataCache')));
            }

            if(factory.registerEntryOffices.length == 0) {
                promises.push(RestService.findAll('RegisterEntryOffice', 'registerEntryOffices', CacheFactory.get('globalDataCache')));
            }

            if(factory.registerEntryChannelsInput.length == 0) {
                 promises.push($http({ url: './api/reg/register_entry_channel/entry_type/INPUT', method: 'GET', headers: { cache: CacheFactory.get('globalDataCache') } }));
            }
            
            if(factory.allRegisterEntryChannelsInput.length == 0) {
                promises.push($http({ url: './api/reg/register_entry_channel/entry_type/all/INPUT', method: 'GET', headers: { cache: CacheFactory.get('globalDataCache') } }));
           }

            if(factory.registerEntryChannelsOutput.length == 0) {
                 promises.push($http({ url: './api/reg/register_entry_channel/entry_type/OUTPUT', method: 'GET', headers: { cache: CacheFactory.get('globalDataCache') } }));
            }
            
            if(factory.allRegisterEntryChannelsOutput.length == 0) {
                promises.push($http({ url: './api/reg/register_entry_channel/entry_type/all/OUTPUT', method: 'GET', headers: { cache: CacheFactory.get('globalDataCache') } }));
           }

            if(factory.registerEntryDocumentTypesInput.length == 0) {
                promises.push($http({ url: './api/reg/register_entry_document_type/entry_type/INPUT', method: 'GET', headers: { cache: CacheFactory.get('globalDataCache') } }));
            }

            if(factory.registerEntryDocumentTypesOutput.length == 0) {
                promises.push($http({ url: './api/reg/register_entry_document_type/entry_type/OUTPUT', method: 'GET', headers: { cache: CacheFactory.get('globalDataCache') } }));
            }

            if(factory.registerEntryClassificationsInput.length == 0) {
                promises.push($http({ url: './api/reg/register_entry_classification/entry_type/INPUT', method: 'GET', headers: { cache: CacheFactory.get('globalDataCache') } }));
            }

            if(factory.registerEntryClassificationsOutput.length == 0) {
                promises.push($http({ url: './api/reg/register_entry_classification/entry_type/OUTPUT', method: 'GET', headers: { cache: CacheFactory.get('globalDataCache') } }));
            }

            if(factory.familyProcedures.length == 0) {
                promises.push(RestService.findAll('FamilyProcedure', undefined, CacheFactory.get('globalDataCache')));
            }

            if(factory.procedures.length == 0) {
                promises.push(RestService.findAll('Procedure', 'globalData', CacheFactory.get('globalDataCache')));
            }

            if( factory.organs.length == 0 ){
                promises.push(RestService.findAll('Organ', 'third', CacheFactory.get('globalDataCache')));
            }

            if(factory.presetSignCircuit.length == 0) {
                promises.push(RestService.findAll('PresetSignCircuit', undefined, CacheFactory.get('globalDataCache')));
            }

            /*if(factory.proposals.length == 0) {
                promises.push(RestService.findAll('Proposal', undefined, CacheFactory.get('globalDataCache')));
            }*/

            var isThirdIdentificationDocumentTypesTranslated = $linq(factory.thirdIdentificationDocumentTypes).count("x => x.id == 'OTHER' && x.language1 == 'OTHER'") == 0;

            if(!isThirdIdentificationDocumentTypesTranslated) {
                promises.push(Language.getAllTranslations('global.thirds.literals.passport'));
                promises.push(Language.getAllTranslations('global.literals.other'));
            }

            var isThirdTypesTranslated = $linq(factory.thirdTypes).count("x => x.id == 'PHISICAL' && x.language1 == 'PHISICAL'") == 0;

            if(!isThirdTypesTranslated) {
                promises.push(Language.getAllTranslations('global.thirds.literals.phisical'));
                promises.push(Language.getAllTranslations('global.thirds.literals.legal'));
                promises.push(Language.getAllTranslations('global.thirds.literals.personwithoutlegalpersonality'));
            }

            var isDocMouslesTranslated = $linq(factory.docModules).count("x => x.id == 'DOC' && x.language1 == 'DOC'") == 0;

            if(!isDocMouslesTranslated) {
                promises.push(Language.getAllTranslations('global.literals.documents'));
                promises.push(Language.getAllTranslations('global.literals.trams'));
                promises.push(Language.getAllTranslations('global.literals.thirds'));
                promises.push(Language.getAllTranslations('global.literals.register_entry_input'));
                promises.push(Language.getAllTranslations('global.literals.register_entry_output'));
                promises.push(Language.getAllTranslations('global.sec.toptitle'));
            }

            if(factory.notificationTypes.length == 0) {
                promises.push(RestService.findAll('NotificationType', undefined, CacheFactory.get('globalDataCache')));
            }

            return $q.all(promises)
                .then(function(data) {
                    var dataPos = 0;

                    if(factory.documentTypes.length == 0) {
                        factory.documentTypes = new SortedArray($linq(fixDocumentTypes(JSOG.decode(data[dataPos].data))).where("x => x.isPublic").toArray(), Language.getActiveColumn()).sort();
                        factory.allDocumentTypes = new SortedArray(fixDocumentTypes(JSOG.decode(data[dataPos].data)), Language.getActiveColumn()).sort();
                        dataPos++;
                    }

                    if(factory.templateTypes.length == 0) {
                        factory.templateTypes = JSOG.decode(data[dataPos].data);
                        factory.templateTree = factory.templateTypes;
                        dataPos++;
                    }

                    if(factory.templates.length == 0) {
                        factory.templates = JSOG.decode(data[dataPos].data);

                        _.forEach(angular.copy(factory.templates), function(value) {
                            value.parent = value.templateType;
                            value.idOriginal = value.id;
                            value.id = '-'+value.id;
                            value[Language.getActiveColumn()] = value.name;
                            factory.templateTree.push(value);
                        });

                        dataPos++;
                    }

                    if(factory.archiveClassifications.length == 0) {
                        factory.archiveClassifications = JSOG.decode(data[dataPos].data);
                        dataPos++;
                    }

                    if(factory.documentViewModeTypes.length == 0) {
                        factory.documentViewModeTypes = JSOG.decode(data[dataPos].data);
                        if(factory.documentViewModeTypes.length == 0) {
                            factory.documentViewModeTypes.push('empty');
                        }
                        dataPos++;
                    }

                    if(factory.sections.length == 0) {
                        factory.sections = JSOG.decode(data[dataPos].data);
                        dataPos++;
                    }

                    if(factory.modules.length == 0) {
                        factory.modules = JSOG.decode(data[dataPos].data);
                        dataPos++;
                        var AnnexaPendingCounterFactory = angular.element(document.body).injector().get('AnnexaPendingCounterFactory');
                        AnnexaPendingCounterFactory.updateModuleCounters();
                    }

                    if(factory.signActionTypes.length == 0) {
                        factory.signActionTypes = JSOG.decode(data[dataPos].data);
                        dataPos++;
                    }

                    if(factory.registerDiligenceTypes.length == 0) {
                        factory.registerDiligenceTypes = JSOG.decode(data[dataPos].data);
                        dataPos++;
                    }

                    if(factory.publishPlugins.length == 0) {
                        factory.publishPlugins = JSOG.decode(data[dataPos].data);
                        dataPos++;
                    }

                    if(factory.allProfiles.length == 0) {
                        factory.allProfiles = JSOG.decode(data[dataPos].data);
                        dataPos++;
                    }

                    if(factory.registerEntryOffices.length == 0) {
                        var haveProfile = function (profiles) {
                            var userProfiles = $linq($rootScope.LoggedUser.userProfiles).selectMany("x => x.profile", "(p, obj) => p").toArray();
                            userProfiles = $linq(userProfiles).distinctBy("x => x.id").toArray();

                            profiles = Array.isArray(profiles) ? profiles : [profiles];

                            var intersectedProfiles = $linq(userProfiles).intersect(profiles, "(x,y) => x.id == y.id").toArray();

                            return intersectedProfiles.length != 0;
                        };

                        factory.registerEntryOffices = [];

                        var regEntryOffices = JSOG.decode(data[dataPos].data);

                        _.forEach(regEntryOffices, function (item) {
                            var registerEntryOfficeProfiles = [];

                            _.forEach(item.profiles, function (value) {
                                registerEntryOfficeProfiles.push(value.profile);
                            });

                            if(haveProfile(registerEntryOfficeProfiles)) {
                                factory.registerEntryOffices.push(item);
                            }
                        });
                        
                        factory.registerEntryOffices = $linq(factory.registerEntryOffices).orderBy("x => x." + Language.getActiveColumn(), linq.caseInsensitiveComparer).toArray();

                        dataPos++;
                    }

                    if(factory.registerEntryChannelsInput.length == 0){
                        factory.registerEntryChannelsInput = JSOG.decode(data[dataPos].data);
                        factory.registerEntryChannelsInput = $linq(factory.registerEntryChannelsInput).orderBy("x => x." + Language.getActiveColumn(), linq.caseInsensitiveComparer).toArray();
                        dataPos++;
                    }
                    
                    if(factory.allRegisterEntryChannelsInput.length == 0){
                        factory.allRegisterEntryChannelsInput = JSOG.decode(data[dataPos].data);
                        factory.allRegisterEntryChannelsInput = $linq(factory.allRegisterEntryChannelsInput).orderBy("x => x." + Language.getActiveColumn(), linq.caseInsensitiveComparer).toArray();
                        dataPos++;
                    }

                    if(factory.registerEntryChannelsOutput.length == 0){
                        factory.registerEntryChannelsOutput = JSOG.decode(data[dataPos].data);
                        dataPos++;
                    }
                    
                    if(factory.allRegisterEntryChannelsOutput.length == 0){
                        factory.allRegisterEntryChannelsOutput = JSOG.decode(data[dataPos].data);
                        dataPos++;
                    }

                    if(factory.registerEntryDocumentTypesInput.length == 0) {
                        factory.registerEntryDocumentTypesInput = JSOG.decode(data[dataPos].data);
                        factory.registerEntryDocumentTypesInput = $linq(factory.registerEntryDocumentTypesInput).orderBy("x => x." + Language.getActiveColumn(), linq.caseInsensitiveComparer).toArray();
                        dataPos++;
                    }

                    if(factory.registerEntryDocumentTypesOutput.length == 0) {
                        factory.registerEntryDocumentTypesOutput = JSOG.decode(data[dataPos].data);
                        factory.registerEntryDocumentTypesOutput = $linq(factory.registerEntryDocumentTypesOutput).orderBy("x => x." + Language.getActiveColumn(), linq.caseInsensitiveComparer).toArray();
                        dataPos++;
                    }

                    if(factory.registerEntryClassificationsInput.length == 0) {
                        factory.registerEntryClassificationsInput = JSOG.decode(data[dataPos].data);
                        factory.registerEntryClassificationsInput = $linq(factory.registerEntryClassificationsInput).orderBy("x => x." + Language.getActiveColumn(), linq.caseInsensitiveComparer).toArray();
                        dataPos++;
                    }

                    if(factory.registerEntryClassificationsOutput.length == 0) {
                        factory.registerEntryClassificationsOutput = JSOG.decode(data[dataPos].data);
                        factory.registerEntryClassificationsOutput = $linq(factory.registerEntryClassificationsOutput).orderBy("x => x." + Language.getActiveColumn(), linq.caseInsensitiveComparer).toArray();
                        dataPos++;
                    }

                    if(factory.familyProcedures.length == 0) {
                        factory.familyProcedures = JSOG.decode(data[dataPos].data);
                        dataPos++;
                    }

                    if(factory.procedures.length == 0) {
                        factory.procedures = JSOG.decode(data[dataPos].data);
                        dataPos++;
                    }

                    if( factory.organs.length == 0 ){
                        factory.organs = JSOG.decode(data[dataPos].data);
                        dataPos++;
                    }

                    if( factory.presetSignCircuit.length == 0 ){
                        factory.presetSignCircuit = JSOG.decode(data[dataPos].data);
                        dataPos++;
                    }

                   /* if( factory.proposals.length == 0 ){
                        factory.proposals = JSOG.decode(data[dataPos].data);
                        dataPos++;
                    }*/

                    if(!isThirdIdentificationDocumentTypesTranslated) {
                        var indexPassport = $linq(factory.thirdIdentificationDocumentTypes).indexOf("x => x.id == 'PASSPORT'");

                        if(indexPassport != -1) {
                            factory.thirdIdentificationDocumentTypes[indexPassport].language1 = data[dataPos].language1;
                            factory.thirdIdentificationDocumentTypes[indexPassport].language2 = data[dataPos].language2;
                            factory.thirdIdentificationDocumentTypes[indexPassport].language3 = data[dataPos].language3;
                        }

                        dataPos++;

                        var indexOther = $linq(factory.thirdIdentificationDocumentTypes).indexOf("x => x.id == 'OTHER'");

                        if(indexOther != -1) {
                            factory.thirdIdentificationDocumentTypes[indexOther].language1 = data[dataPos].language1;
                            factory.thirdIdentificationDocumentTypes[indexOther].language2 = data[dataPos].language2;
                            factory.thirdIdentificationDocumentTypes[indexOther].language3 = data[dataPos].language3;
                        }

                        dataPos++;
                    }

                    if(!isThirdTypesTranslated) {
                        var indexPhisical = $linq(factory.thirdTypes).indexOf("x => x.id == 'PHISICAL'");

                        if(indexPhisical != -1) {
                            factory.thirdTypes[indexPhisical].language1 = data[dataPos].language1;
                            factory.thirdTypes[indexPhisical].language2 = data[dataPos].language2;
                            factory.thirdTypes[indexPhisical].language3 = data[dataPos].language3;
                        }

                        dataPos++;

                        var indexLegal = $linq(factory.thirdTypes).indexOf("x => x.id == 'LEGAL'");

                        if(indexLegal != -1) {
                            factory.thirdTypes[indexLegal].language1 = data[dataPos].language1;
                            factory.thirdTypes[indexLegal].language2 = data[dataPos].language2;
                            factory.thirdTypes[indexLegal].language3 = data[dataPos].language3;
                        }

                        dataPos++;

                        var indexPersonalWithoutLegalPersonality = $linq(factory.thirdTypes).indexOf("x => x.id == 'PERSONWITHOUTLEGALPERSONALITY'");

                        if(indexPersonalWithoutLegalPersonality != -1) {
                            factory.thirdTypes[indexPersonalWithoutLegalPersonality].language1 = data[dataPos].language1;
                            factory.thirdTypes[indexPersonalWithoutLegalPersonality].language2 = data[dataPos].language2;
                            factory.thirdTypes[indexPersonalWithoutLegalPersonality].language3 = data[dataPos].language3;
                        }

                        dataPos++;
                    }

                    if(!isDocMouslesTranslated) {
                        var indexDoc = $linq(factory.docModules).indexOf("x => x.id == 'DOC'");

                        if(indexDoc != -1) {
                            factory.docModules[indexDoc].language1 = data[dataPos].language1;
                            factory.docModules[indexDoc].language2 = data[dataPos].language2;
                            factory.docModules[indexDoc].language3 = data[dataPos].language3;
                        }

                        dataPos++;

                        var indexExped = $linq(factory.docModules).indexOf("x => x.id == 'EXPED'");

                        if(indexExped != -1) {
                            factory.docModules[indexExped].language1 = data[dataPos].language1;
                            factory.docModules[indexExped].language2 = data[dataPos].language2;
                            factory.docModules[indexExped].language3 = data[dataPos].language3;
                        }

                        dataPos++;

                        var indexThirds = $linq(factory.docModules).indexOf("x => x.id == 'THIRDS'");

                        if(indexThirds != -1) {
                            factory.docModules[indexThirds].language1 = data[dataPos].language1;
                            factory.docModules[indexThirds].language2 = data[dataPos].language2;
                            factory.docModules[indexThirds].language3 = data[dataPos].language3;
                        }

                        dataPos++;

                        var indexRegEntrada = $linq(factory.docModules).indexOf("x => x.id == 'REGENTRADA'");

                        if(indexRegEntrada != -1) {
                            factory.docModules[indexRegEntrada].language1 = data[dataPos].language1;
                            factory.docModules[indexRegEntrada].language2 = data[dataPos].language2;
                            factory.docModules[indexRegEntrada].language3 = data[dataPos].language3;
                        }

                        dataPos++;

                        var indexRegSalida = $linq(factory.docModules).indexOf("x => x.id == 'REGSALIDA'");

                        if(indexRegSalida != -1) {
                            factory.docModules[indexRegSalida].language1 = data[dataPos].language1;
                            factory.docModules[indexRegSalida].language2 = data[dataPos].language2;
                            factory.docModules[indexRegSalida].language3 = data[dataPos].language3;
                        }

                        dataPos++;

                        var indexSec = $linq(factory.docModules).indexOf("x => x.id == 'SEC'");

                        if(indexSec != -1) {
                            factory.docModules[indexSec].language1 = data[dataPos].language1;
                            factory.docModules[indexSec].language2 = data[dataPos].language2;
                            factory.docModules[indexSec].language3 = data[dataPos].language3;
                        }

                        dataPos++;
                    }

                    if(factory.notificationTypes.length == 0) {
                        factory.notificationTypes = JSOG.decode(data[dataPos].data);
                        dataPos++;
                    }
                }).catch(function (error) {
                    console.error(error);

                    factory.documentTypes = [];
                    factory.templateTypes = [];
                    factory.templates = [];
                    factory.archiveClassifications = [];
                    factory.documentsViewModeTypes = [];
                    factory.sections = [];
                    factory.modules = [];
                    factory.signActionTypes = [];
                    factory.registerDiligenceTypes = [];
                    factory.allProfiles = [];
                    factory.registerEntryChannelsInput = [];
                    factory.allRegisterEntryChannelsInput = [];
                    factory.registerEntryChannelsOutput = [];
                    factory.allRegisterEntryChannelsOutput = [];
                    factory.registerEntryDocumentTypesInput = [];
                    factory.registerEntryDocumentTypesOutput = [];
                    factory.registerEntryClassificationsInput = [];
                    factory.registerEntryClassificationsOutput = [];
                    factory.registerEntryDocumentTypesInput = [];
                    factory.registerEntryDocumentTypesOutput = [];
                    factory.registerEntryClassificationsInput = [];
                    factory.registerEntryClassificationsOutput = [];
                    factory.familyProcedures = [];
                    factory.procedures = [];
                    factory.organs = [];
                    factory.notificationTypes = [];
                    factory.presetSignCircuit = [];
                    factory.proposals = [];
                });
        };

        return factory;
    }]);