angular
    .module('annexaApp')
    .factory('UIButtonFactory',['$rootScope', 'Language', '$http', 'AnnexaFormlyFactory', 'RegFactory', 'globalModals', 'DialogsFactory', '$filter','$state','DccumentsFactory', 'TramFactory', function($rootScope, Language, $http, AnnexaFormlyFactory, RegFactory, globalModals, DialogsFactory, $filter, $state, DccumentsFactory, TramFactory) {
        var factory = {};

        factory.clickButton = function(id, objectId, returnType, dropdownButton) {
        	if (id == 'actions_button_dossier_view') {
        		var clickButtonSubmit = function() {
        			var self = this;
                    var dataModel = this.annexaFormly.model.modal_body;

                    if (this.annexaFormly.form.$valid) {
                    	var dataModelFormFields = angular.copy(dataModel);
                    	
                    	_.forEach(dataModelFormFields, function(value, index, dataModelFormFields){
                    		if (dataModelFormFields[index] && dataModelFormFields[index] instanceof Date) {
                        		var momentDate = moment(dataModelFormFields[index]).utc(true); //treure zona horària
                        		dataModelFormFields[index] = momentDate.format();                    			
                    		} else if (dataModelFormFields[index] == null) {
                    			dataModelFormFields[index] = '';
                    		}
                    	});
                    	
                    	$http({
        					url: './api/plugin/uibutton/' + id + '/click/' + objectId + '/' + dropdownButton.eClick,
        					method: 'POST',
        					data: dataModelFormFields
        				}).then(function(data) {
    						modal.close();
        					if (data.status == 200) {
        						if(data.data){
        							DialogsFactory.notify(data.data, $filter('translate')('DIALOGS_NOTIFICATION'));
        							console.log({msg: data.data});
        						} else {
        							DialogsFactory.error( 'global.errors.unknownNew', $filter('translate')('DIALOGS_ERROR_MSG'));
        							console.log({msg: 'global.errors.unknownNew'});
        						}
        					}
        					//refrescar
        					$state.reload();
        				}).catch(function(error) {
        					console.log(error);
        				});
                    }
        		}
        		
                var data = {
                    row: true,
                    colClass: 'col-sm-12',
                    labelClass: 'label-strong'
                };

        		var modal = {
                    title: dropdownButton.btText,
                    size: '',
                    icon: '',
                    submitModal: function () {}
                };
                modal.annexaFormly = new AnnexaFormly();
                modal.alerts = [];

                var formFields;
                var fields = [];
                if (dropdownButton.formFields) {
                	formFields = JSON.parse(dropdownButton.formFields);
                }

                if(formFields && formFields.length){
	   				_.forEach(formFields, function(formField){
	   					var formlyField;
	   					
	   					if (formField.value && formField.type && formField.type === 'text') {
	   						var formFieldValue = formField.value;
	   						// Si hi ha valors que s'han de subtituir per alguna dada de l'expedient, s'han de comprovar que existeixin aquests valors
	   						formFieldValue = formFieldValue.replace("${procedure}", TramFactory.Dossier.procedure[Language.getActiveColumn()]);
	   						formFieldValue = formFieldValue.replace("${dossierNumber}", TramFactory.Dossier.dossierNumber);
	   						
	   						formField.value = formFieldValue;
	   					}
	   					
	   					switch (formField.type) {
		                    case 'text':
		                    case 'number':
	                    		formlyField = modal.annexaFormly.createField(
	                    			formField.key, 'annexaInputRow',
		                            formField.type == 'number' ? 'col-sm-6' : 'col-sm-12',
		                            new AnnexaFormlyFieldTemplateOptions(
		                            	formField.type == 'number' ? 'number': 'text',
		                                formField.label,
		                                formField.required ? formField.required : false
		                            ),
		                            data, undefined, formField.value);
		                        break;
		                    case 'date':
		                    	var datepickerOptions = {
		                            format: 'dd/MM/yyyy',
		                            initDate: new Date(),
		                            showWeeks: false,
		                            startingDay: 1
		                        }
		                        var dateParts = formField.value.split("/");
		                        var dateObject = undefined;
		                        if (dateParts && dateParts.length > 1) {
		                        	dateObject = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]); // month is 0-based
		                        }

		                    	formlyField = modal.annexaFormly.createField(
		                    		formField.key, 'annexaDatepickerRow', 'col-sm-6',
		                            new AnnexaFormlyFieldDatepickerTemplateOptions(
		                                'text', formField.label,
		                                formField.required ? formField.required : false,
		                                datepickerOptions
		                            ),
		                            data, undefined, dateObject);
		                    	break;
		                    case 'select':
		                        var optionsSelect = [];
		                        if (formField.options && formField.options == 'documents') {
			                        _.forEach(TramFactory.Dossier.dossierTransactions, function(dt){
			                        	if (dt.documents && dt.documents.length) {
			                        		_.forEach(dt.documents, function(dtd){ if (dtd.document) optionsSelect.push(dtd.document); });
			                        	}
			                        });
		                        } else if (formField.customOptions) {
		                        	optionsSelect = formField.customOptions;
		                        }

		                    	formlyField = modal.annexaFormly.createField(
		                    		formField.key, 'annexaSelectRow', 
		                    		'col-sm-12', 
		                    		new AnnexaFormlyFieldSelectTemplateOptions(
		                    			formField.label,
		                    			'id', 'name',
		                    			optionsSelect,
		                    			formField.required ? formField.required : false
		                    		), 
		                    		data, undefined, formField.value);
		                    	break;
	   					}
	   					
	   					if (formField.readOnly) {
                    		formlyField = modal.annexaFormly.createField(
                				formField.key, 'annexaLabelRow', 'col-sm-12',
	                            new AnnexaFormlyFieldLabelTemplateOptions(formField.label, formField.value),
	                            data);
	   					}
	   					
		                if (formlyField) {
		                	// afegim la propietat defaultValue per als casos on formField.value='' 
		                	// ja que si no s'informa el camp al formulari i es un camp opcional, necessitem passar el valor buit
		                	if (formField.value == undefined || formField.value === '') formlyField.defaultValue = '';
		                	fields.push(formlyField);
		                }
	   				});
   			 	}
                modal.annexaFormly.addGroup('modal_body','modal-body p-lg',fields);
                modal.annexaFormly.options = {};
                modal.annexaFormly.options.formState = {readOnly: false};

        		AnnexaFormlyFactory.showModal("modalActionsButtonDossierView", modal, clickButtonSubmit, false);
        	} else {
        		var fileContentsForDownload = function(headers, data) {
        			var retDownload = {
        					file: undefined,
        					fileName: undefined,
        					linkElement: undefined,
        					clickEvent: undefined
        			}
        			
        			var contentType = headers['content-type'];
        			retDownload.file = new Blob([data], { type: contentType });
        			retDownload.fileName = headers['content-disposition'].replace('attachment; filename="','').replace('"', '');
        			
        			try {
        				if (window.navigator.msSaveOrOpenBlob) {
        					window.navigator.msSaveOrOpenBlob(retDownload.file, retDownload.fileName);
        				} else {
        					retDownload.linkElement = document.createElement('a');
        					var url = window.URL.createObjectURL(retDownload.file);
        					retDownload.linkElement.setAttribute('href', url);
        					retDownload.linkElement.setAttribute("download", retDownload.fileName);
        					retDownload.clickEvent = new MouseEvent("click", {
        						"view": window,
        						"bubbles": true,
        						"cancelable": false
        					});
        					retDownload.linkElement.dispatchEvent(retDownload.clickEvent);
        				}
        				$rootScope.loading(false);
        			} catch(e) {
        				$rootScope.loading(false);
        			}
        		}
        		
        		var responseType = returnType == 'FILE_CONTENTS_FOR_DOWNLOAD' ? 'arraybuffer' : undefined; 
        		
        		var literalConfirm = 'global.literals.generate_dossier_ENI';
        		var docConvertiblePDF = true;
        		if (id == 'document_eni_button_document_view') {
        			literalConfirm = 'global.literals.generate_document_ENI'
        				var AnnexaDocumentActionsButtonFactory = angular.element(document.body).injector().get('AnnexaDocumentActionsButtonFactory');
        			docConvertiblePDF = AnnexaDocumentActionsButtonFactory.isDocumentConvertibleToPdf(DccumentsFactory.document, false);            	
        		}
        		
        		if (docConvertiblePDF) {
        			DialogsFactory.confirm('global.documents.new.audit.actionType.ENI', literalConfirm)
        			.then(function (data) {
        				$http({
        					url: './api/plugin/uibutton/' + id + '/click/' + objectId,
        					method: 'POST',
        					responseType: responseType
        				}).then(function(data) {
        					if(data.status == 406 || data.status == 500) {
        						if(data.data){
        							var dec = new TextDecoder();
        							var message = angular.fromJson(dec.decode(data.data));
        							if(message.message) {
        								var message = angular.fromJson(message.message);
        								DialogsFactory.error(message[Language.getActiveColumn()], $filter('translate')('DIALOGS_ERROR_MSG'));
        								console.log({msg: message[Language.getActiveColumn()]});
        							}else{
        								DialogsFactory.error( 'global.errors.unknownNew', $filter('translate')('DIALOGS_ERROR_MSG'));
        								console.log({msg: 'global.errors.unknownNew'});
        							}
        						} else {
        							DialogsFactory.error( 'global.errors.unknownNew', $filter('translate')('DIALOGS_ERROR_MSG'));
        							console.log({msg: 'global.errors.unknownNew'});
        						}
        					}else {
        						switch (returnType) {
        						case 'FILE_CONTENTS_FOR_DOWNLOAD':
        							fileContentsForDownload(data.headers(), data.data);
        							break;
        							
        						}
        					}
        					//refrescar
        					$state.reload();
        				}).catch(function(error) {
        					console.log(error);
        				});
        			}).catch(function(error) {
        			});
        		} else {
        			DialogsFactory.error('global.literals.generate_document_ENI_KO');
        		}
        	}
        }

        factory.updateHeaderButtons = function (state, $scope, stateParams, $location) {
            var ubication = undefined;
            var dataTarget = undefined;


            switch (state.name) {
                case 'annexa.tram.pending.viewdossier':
                    ubication = 'DOSSIER_VIEW';
                    dataTarget = '#view-dossier';
                    break;
            }

            if(ubication) {
                var buttons = $linq($rootScope.app.UIButtons).where("x => x.ubication == '" + ubication + "'").toArray();
                var currentURL = $location.absUrl();

                _.forEach(buttons, function (button) {
                	if (button.dropdownButtons && typeof button.dropdownButtons === 'string') {
                		var lstDropdownButtons = angular.fromJson(button.dropdownButtons);
                		if (lstDropdownButtons.listActionButton) {
                			button.dropdownButtons = lstDropdownButtons.listActionButton;
                			button.permissions = lstDropdownButtons.permissions;
                		} else {
                			button.dropdownButtons = undefined;
                			button.permissions = undefined;
                		}
                	}
                	
                	if (button.dropdownButtons) {
                		var buttonClass = 'breadDivButton btn dropdown ml-sm-pt p-a-0 primary';
                	} else {
                		var buttonClass = 'btn primary breadDivButton ml-sm-pt';
                	}
                	
                    var headButton = new HeadButton(buttonClass, undefined, dataTarget, button.icon, button[Language.getActiveColumn()], undefined,
                        'clickButton' + button.id,undefined, undefined, button.permissions, button.dropdownButtons, undefined, button.id);
                    
                    if (button.dropdownButtons) {
                    	headButton.stateParams = stateParams;
                    	headButton.stateParams.idLoggedUser = $rootScope.LoggedUser.id;
                    	headButton.stateParams.currentURL = currentURL;
                    }

                    var position = undefined;

                    switch (button.position) {
                        case 'HEADER':
                            position = 'headButtons';
                            break;
                        case 'SUBHEADER':
                            position = 'subHeadButtons';
                            break;
                    }

                    if(position) {
                        if($linq($rootScope[position]).count("x => x.id == '" + button.id + "'") == 0) {
                            $rootScope[position].push(headButton);
                            $rootScope['clickButton' + button.id] = function() {
                                if(state.name == 'annexa.tram.pending.viewdossier' && stateParams.hasOwnProperty('dossier') && stateParams.dossier) {
                                    factory.clickButton(button.id, stateParams.dossier, button.returnType, null);
                                }
                            }
                            if (button.dropdownButtons) {
                            	_.forEach(button.dropdownButtons, function (dropdownButton) {
                            		if (dropdownButton.eClick) {
                            			$rootScope[dropdownButton.eClick] = function() {
                            				if(state.name == 'annexa.tram.pending.viewdossier' && stateParams.hasOwnProperty('dossierTransaction') && stateParams.dossierTransaction) {
                            					factory.clickButton(dropdownButton.id, stateParams.dossierTransaction, button.returnType, dropdownButton);
                            				}
                            			}
                            		}
                            	});
                            }
                        }
                    }
                })
            }
        }

        return factory;
    }])