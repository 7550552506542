/**
 * Created by osirvent on 14/03/2016.
 */

var TableFilterItem = function(name,type,order,label,dataType,listValues,originValues,originAddAll,iconElement,nameElement,isTranslate, aditionalElements) {
    this.name = name;
    this.type = type;
    this.label = label;
    this.dataType = dataType;
    if(listValues && type == 'select') {
        this.model = listValues[0];
    } else if(type == 'select-tree') {
        this.model = {};
    } else if(type == 'select-multiple') {
        this.model = [];
    } else {
        this.model = '';
    }
    this.listValues = listValues;
    this.originValues = originValues;
    this.originAddAll = originAddAll;
    this.order = order;
    this.iconElement = iconElement;
    this.nameElement = nameElement;
    this.isTranslate = isTranslate;
    this.filterCallAux = false;
    this.prefilter = undefined;
    this.aditionalElements = aditionalElements;

    //Control de filtres definits en pantalla
    this.userChosenFilter = false;

    this.setFilterCallAux = function (filterCallAux) {
        this.filterCallAux = filterCallAux;
        return this;
    }

    this.setUserChosenFilter = function (userChosenFilter) {
        this.userChosenFilter = userChosenFilter;
        return this;
    }
};
