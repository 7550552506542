/**
 * Created by osirvent on 27/09/2016.
 */
angular
    .module('annexaApp')
    .factory('AnnexaEntityFactory',['$rootScope', 'AnnexaFormsFactory', 'BoxFactory', 'ThirdFactory', 'AnnexaFormlyFactory', 'globalModals', 'HelperService', '$state', 'ModalService', 'ErrorFactory', '$q', 'Language', '$filter', 'RegFactory', 'RestService', 'TramFactory', 'CustomFieldFactory', 'dialogs', 'AnnexaPermissionsFactory', 'CommonService', 'TaskFactory','NotificationFactory', 'SecFactory', 'AnnexaPlugins', 'GlobalDataFactory', 'DccumentsFactory', 'DialogsFactory', 'apiAdmin', '$http',
    function ($rootScope, AnnexaFormsFactory, BoxFactory, ThirdFactory, AnnexaFormlyFactory, globalModals, HelperService, $state, ModalService, ErrorFactory, $q, Language, $filter, RegFactory, RestService, TramFactory, CustomFieldFactory, dialogs, AnnexaPermissionsFactory, CommonService, TaskFactory, NotificationFactory, SecFactory, AnnexaPlugins, GlobalDataFactory, DccumentsFactory, DialogsFactory, apiAdmin, $http) {
        var factory = {};

        //region General
        var thirdModule = $linq(GlobalDataFactory.modules).where("x => x.url == 'annexa.thirds'").toArray();
        var docModule = $linq(GlobalDataFactory.modules).where("x => x.url == 'annexa.doc.documents.all'").toArray();
        var secModule = $linq(GlobalDataFactory.modules).where("x => x.url == 'annexa.sec'").toArray();
        var dossierModule = $linq(GlobalDataFactory.modules).where("x => x.url == 'annexa.tram'").toArray();
        var regInputModule = $linq(GlobalDataFactory.modules).where("x => x.url == 'annexa.reg.input'").toArray();
        var regOutputModule = $linq(GlobalDataFactory.modules).where("x => x.url == 'annexa.reg.output'").toArray();
        var tasksModule = $linq(GlobalDataFactory.modules).where("x => x.url == 'annexa.tasks'").toArray();

        var showModal = function(modal, entity, pluginId, pluginObject) {
            modal.annexaFormly = entity.form;
            modal.boxes = entity.boxes;
            modal.submitModal = modal.annexaFormly.onSubmit;
            modal.alerts = [];
            entity.modal = modal;

            ModalService.showModal({
                template: '<annexa-entity id="' + entity.id + '" entity-definition="modalObj" class="modal black-overlay" data-backdrop="false"/>',
                controller: 'AnnexaFormlyModalController',
                modalObj: entity
            }).then(function(modalThen) {
                modal.modal = modalThen;
                modal.close = modalThen.element.scope().close;
                modal.annexaFormly.close = modalThen.element.scope().close;
                modalThen.element.modal();
                angular.element(".modal-title").focus();
                if(pluginId && pluginObject){
                    AnnexaPlugins.addPlugin(modalThen.element.scope(), pluginObject, pluginId);
                }
            });
        };

        var getBox = function(type, renderType, box_title, placeholder, advanced_search_title, newButton, searchButton, advancedSearchButton, searchAddButton, entryType, documents, receivers, aditional_filter, byEntryType) {
            var box ;

            switch (type) {
                case 'document':
                    box = angular.copy(BoxFactory.DocumentBox).initialize(box_title, placeholder, advanced_search_title, newButton, searchButton, advancedSearchButton, searchAddButton, factory);
                    break;
                case 'receiver':
                    box = angular.copy(BoxFactory.ThirdBox).initialize(box_title, placeholder, advanced_search_title, newButton, searchButton, advancedSearchButton, searchAddButton, factory, "receiver", "x => x.third.id");
                    break;
                case 'representant':
                    box = angular.copy(BoxFactory.ThirdBox).initialize(box_title, placeholder, advanced_search_title, newButton, searchButton, advancedSearchButton, searchAddButton, factory, "representant", "x => x.agent.id", aditional_filter);
                    break;
                case 'dossier':
                    box = angular.copy(BoxFactory.DossierBox).initialize(box_title, placeholder, advanced_search_title, newButton, searchButton, advancedSearchButton, searchAddButton, factory, undefined, aditional_filter);
                    break;
                case 'registerEntry':
                    box = angular.copy(BoxFactory.RegisterBox).initialize(box_title, placeholder, advanced_search_title, entryType, newButton, searchButton, advancedSearchButton, searchAddButton, factory, byEntryType);
                    break;
                case 'documentChild':
                    box = angular.copy(BoxFactory.DocumentChildBox).initialize(box_title, placeholder, advanced_search_title, newButton, searchButton, advancedSearchButton, searchAddButton, factory);
                    break;
                case 'task':
                    box = angular.copy(BoxFactory.TaskBox).initialize(box_title, placeholder, advanced_search_title, newButton, searchButton, factory, aditional_filter);
                    break;
                case 'dossierTransaction':
                    box = angular.copy(BoxFactory.DossierTransactionBox).initialize(box_title, placeholder, advanced_search_title,false,false,false, Language.getActiveColumn());
                    break;
            }

            if(box) {
                box.boxDefinition.content = [];
                box.boxDefinition.saveSearch = undefined;
                box.boxDefinition.deleteFunction = function (objectId, index) {
                    box.boxDefinition.content.splice(index, 1);
                };
                box.boxDefinition.renderType = renderType;
            }

            return box;
        };

        //endregion

        //region Documents

        var documentFormFields = function (tram_templates, document_types, thirds, transaction) {
            var form = new AnnexaFormly();

            var fields = {};

            var data = {
                row: true,
                colClass: 'col-sm-12',
                labelClass: 'label-strong',
                extralabel: true,
                clear: function($event,model,key, $select) {
                    $event.stopPropagation();
                    model[key] = undefined;
                    if($select) {
                        $select.selected = undefined;
                        $select.search = undefined;
                    }
                }
            };

            var field1 = form.createField(
                'tramTemplate',
                'annexaSelectGroupRow',
                'col-sm-12',
                new AnnexaFormlyFieldSelectTemplateOptions(
                    'global.literals.template',
                    'id',
                    Language.getActiveColumn(),
                    [],
                    true
                ),
                data
            );
            fields.tramTemplate = field1;
//            field_key, 
//            field_type, 
//            field_class, 
//            field_templateOptions, 
//            field_data, 
//            field_hideExpression, 
//            field_defaultValue, 
//            field_controller
            var field2 = form.createField(
                'documentTitle',
                'annexaInputRow',
                'col-sm-12',
                new AnnexaFormlyFieldTemplateOptions(
                    'text',
                    'global.literals.title',
                    true,
                    undefined,
                    undefined,
                    200
                ),
                data
            );
            field2.templateOptions.updateOnBlur = true;
            fields.documentTitle = field2;

            var sections = $linq($rootScope.LoggedUser.userSections).select("x => x.section").toArray();
            sections = new SortedArray(sections, Language.getActiveColumn()).sort();
            var field3 = form.createField(
                'section',
                'annexaSelectRow',
                'col-sm-12',
                new AnnexaFormlyFieldSelectTemplateOptions(
                    'global.literals.section',
                    'id',
                    Language.getActiveColumn(),
                    sections,
                    true
                ),
                data
            );
            fields.section = field3;

            var profiles = $linq($rootScope.LoggedUser.userProfiles).distinctBy("x => x.profile.id").select("x => x.profile").toArray();
            var profilesExp = $linq(profiles).intersect($linq(transaction.processProfiles).select("x => x.profile").toArray(), "(x, y) => x.id == y.id").toArray();

            var field4 = form.createField(
                'profiles',
                'annexaMultipleSelectRow',
                'col-sm-12',
                new AnnexaFormlyFieldSelectTemplateOptions(
                    'global.literals.profiles',
                    'id',
                    Language.getActiveColumn(),
                    profilesExp,
                    true
                ),
                data
            );
            fields.profiles = field4;

            if(thirds && thirds.length > 0){
                var field5 = form.createField('thirdsName',
                    'annexaLabel',
                    'col-sm-12',
                    new AnnexaFormlyFieldTemplateOptions(undefined, $filter('translate')('global.literals.receivers'), false, false, undefined),
                    data);
                fields.thirdLabel = field5;
                fields.thirds = [];
                var i = 0;
                angular.forEach(thirds, function (value, key) {
                    var receiver = angular.copy(value);
                    var name = ((receiver.third.name)?receiver.third.name+" ":"") + ((receiver.third.surename1)?receiver.third.surename1+" ":"") + ((receiver.third.surename2)? receiver.third.surename2+" " : "" ) + ((receiver.third.corporateName)?receiver.third.corporateName:"");
                    var destinatariType = '';
                    if(receiver.interested){
                        destinatariType = $filter('translate')('global.literals.interested');
                    }else{
                        var representated = '';
                        if(value.representated && value.representated.identificationDocument){
                            if(value.representated.name){
                                representated= '(' + value.representated.name + ' ' + value.representated.surename1 + ((value.representated.surename2)?' '+value.representated.surename2 : '' ) + ' (' + value.representated.identificationDocument + ')';
                            }else{
                                representated= '(' + value.representated.corporateName + ' (' + value.representated.identificationDocument + ')';
                            }
                        }
                        destinatariType = $filter('translate')('global.literals.representative', { representated: representated });
                    }
                    var field = form.createField(
                        'receivers' + i,
                        'annexaRadioCheckboxRow',
                        'col-sm-12',
                        new AnnexaFormlyFieldSelectTemplateOptionsHtml(
                            '',
                            'value',
                            'label',
                            [{value: receiver.third.id, label: name, labelHtml:destinatariType}],
                            false,
                            'checkHTML',
                            undefined,
                            'labelHtml'
                        ),
                        data
                    );
                    fields.thirds.push(field);
                    i++;
                });
            }

            var documentTypesOptions = [];
            if(document_types && document_types.length > 0){
            	_.forEach (document_types, function(docType){
                    if(docType.profiles && docType.profiles.length > 0){
                        // intersection de perfil de usuario y los perfiles de documentTypeProfile
                        var profiles = $linq(docType.profiles).intersect($rootScope.LoggedUser.userProfiles, function(x,y){
                            if(y.profile){
                                return (x.profile.id == y.profile.id);
                            }
                            else{
                                return true;
                            }
                        }).toArray();
                        if(profiles && profiles.length > 0){
                            documentTypesOptions.push(angular.copy(docType));
                        }
                    }
                    else{
                        documentTypesOptions.push(angular.copy(docType));
                    }
                });
            }else if(GlobalDataFactory.documentTypes && GlobalDataFactory.documentTypes.length > 0) {
                _.forEach (GlobalDataFactory.documentTypes, function(docType){
                    if(docType.profiles && docType.profiles.length > 0){
                        // intersection de perfil de usuario y los perfiles de documentTypeProfile
                        var profiles = $linq(docType.profiles).intersect($rootScope.LoggedUser.userProfiles, function(x,y){
                            if(y.profile){
                                return (x.profile.id == y.profile.id);
                            }
                            else{
                                return true;
                            }
                        }).toArray();
                        if(profiles && profiles.length > 0){
                            documentTypesOptions.push(angular.copy(docType));
                        }
                    }
                    else{
                        documentTypesOptions.push(angular.copy(docType));
                    }
                });
            }

            var field6 = form.createField(
                'typeSelect',
                'annexaSelectRow',
                'col-sm-12',
                new AnnexaFormlyFieldSelectTemplateOptions(
                    'global.literals.type',
                    'id',
                    Language.getActiveColumn(),
                    documentTypesOptions,
                    true,
                    undefined,
                    function(item, options) {
                        var $rootScope = angular.element(document.body).injector().get('$rootScope');
                        $rootScope.$broadcast('newDocumentTypeSelectedTramTemplate', { item: item });
                    }
                ),
                data
            );
            fields.documentType = field6;
            
            var field7 = form.createField(
        		'archiveClassification',
        		'annexaSelectTreeRow',
        		'col-sm-12',
        		new AnnexaFormlyFieldSelectTemplateOptions(
    				'global.literals.classificationBox',
    				'id',
    				Language.getActiveColumn(),
    				CommonService.getTreeData(TramFactory.archiveClassifications, Language.getActiveColumn())
    				,false
        		)
        		,data
            );
            fields.archiveClassification = field7;
            
            return fields;
        };

        var documentFromTemplateForm = function(transaction,tram_templates,document_types, modal, boxDefinition, dossier, idModal, isShowTransaction, relatedDocument) {
            var fields = new documentFormFields(tram_templates,document_types, ((dossier && dossier.thirds)?dossier.thirds:[]), transaction);
            var newModel = { row1: {}, row2: {} };

            var form = new AnnexaFormly();
            form.addGroup('row7', '', [fields.documentType], 'annexaRow');

            form.tramTemplates = tram_templates;
            fields.tramTemplate.controller = ['$scope','$rootScope', 'Language',function ($scope, $rootScope, Language) {
            	var setOptions = function(documentType){
            		if(documentType && $scope.to.tramTemplates){
        				$scope.to.options = $linq($scope.form && $scope.to.tramTemplates).where("x => !x.documentType || !x.documentType.id || x.documentType.id == "+documentType).toArray();
        			}else{
      					$scope.to.options = [];
        			}
            	}
            	$scope.$on('newDocumentTypeSelectedTramTemplate', function(event, args){ 
            			if(args && args.item && args.item.id){
            				setOptions(args.item.id);
            			}else{
          					$scope.to.options = [];
            			}
            		}
            	)
            	setOptions($scope.to.typeSelect);
            	$scope.to.groupFunction = function(item){
            		if(item.documentType && item.documentType.id){
            			return $filter('translate')("global.tram.literals.templatesOf")+" "+item.documentType[Language.getActiveColumn()];
            		}else{
            			return $filter('translate')("global.tram.literals.templateGeneric");
            		}
            	}
            	$scope.to.orderByFunction = function(item){
            		if(item.documentType){
            			return "[ !"+item.documentType+", -"+item.documentType+" ]";
            		}else{
            			return null;
            		}
            	}
            }];
            
            fields.documentTitle.controller = ['$scope','$rootScope',function ($scope, $rootScope) {
            	$scope.to.onFocus = function(viewValue){
            		if($rootScope && ($rootScope.esetMode || ($rootScope.app &&  $rootScope.app.configuration &&  $rootScope.app.configuration.validate_title_document && $rootScope.app.configuration.validate_title_document.value))){
            			var typeSelect = angular.element('#'+idModal).scope().modalObj.form.model.row7.typeSelect;
                        if(typeSelect) {
                        	var selectedType = $linq(GlobalDataFactory.documentTypes).singleOrDefault(undefined, "x => x.id == " + typeSelect);
                        	if(selectedType) {                                                	
                            	if ($scope.lastSelectedType) {
                            		//Ya hay un tipo de documento seleccionado
                            		var pos = $scope.model.documentTitle.indexOf($scope.lastSelectedType);
                            		if (pos >= 0) {
                            			//Encuentra el acronimo del tipo de documento anterior, lo reemplaza por el nuevo acronimo
                            			$scope.model.documentTitle = $scope.model.documentTitle.replace($scope.lastSelectedType, selectedType.acronym);
                            		} else {
                            			//No encuentra el acronimo del tipo de documento anterior, pone el nuevo acronimo al principio
                            			$scope.model.documentTitle = selectedType.acronym + ' ' + $scope.model.documentTitle;
                            		}                                                		                                                		
                            	} else {
                            		//No hay un tipo de documento seleccionado
                            		if (viewValue) {
                            			if (viewValue.startsWith(selectedType.acronym + ' ')) {
                            				$scope.model.documentTitle = viewValue;
                            			} else {
                            				$scope.model.documentTitle = selectedType.acronym + ' ' + viewValue;
                            			}
                            		} else {
                            			$scope.model.documentTitle = selectedType.acronym + ' ';
                                    }
                            	}
                            	$scope.lastSelectedType = selectedType.acronym;
                            }
                        }
            		}
            	}
            }];
            
            fields.tramTemplate.templateOptions.tramTemplates = tram_templates;
            form.addGroup('row1', '', [fields.tramTemplate], 'annexaRow');
            form.addGroup('row2', '', [fields.documentTitle], 'annexaRow');
            if(fields.section.templateOptions.options.length>1) {
                newModel.row3 = {};
                form.addGroup('row3', '', [fields.section], 'annexaRow');
            }
            if(fields.profiles.templateOptions.options.length>1) {
                newModel.row4 = {};

                var hideProfile = function($viewValue, $modelValue, scope) {
                    return scope.model && scope.model.row4 && scope.model.row4.profile;
                };

                //form.addGroup('row4', '', [fields.profile], 'annexaRow', hideProfile);
                form.addGroup('row4', '', [fields.profiles], 'annexaRow');

                var defaultProfile ;

                if ($rootScope.LoggedUser.userDefaultEntityProfiles) {
                    var profiles = $linq($rootScope.LoggedUser.userDefaultEntityProfiles).where("x => x.entityType == 'DOC'").select("x => x.profile").toArray();

                    if (profiles && profiles.length > 0) {
                        defaultProfile = profiles[0];
                    }
                }

                if(transaction.processProfiles == 1) {
                    newModel.row4.profiles = [transaction.processProfiles[0].profile.id];
                } else {
                    if(defaultProfile) {
                        if($linq($linq(transaction.processProfiles).select("x => x.profile").toArray()).count("x => x.id == " + defaultProfile.id) > 0) {
                            newModel.row4.profiles = [defaultProfile.id];
                        }
                    }
                }
            }
            form.addGroup('row8', '', [fields.archiveClassification], 'annexaRow');
            if(dossier && dossier.archiveClassification && dossier.archiveClassification.model && dossier.archiveClassification.model.$selected ){
            	newModel.row8 = {archiveClassification:{$selected:angular.copy(dossier.archiveClassification.model.$selected)}};
            }else{
            	newModel.row8 = {archiveClassification:{}};
            }
            if(fields.thirdLabel){
                var hideFunction = function($viewValue, $modelValue, scope){
                    var hide = true;
                    if(scope.model && scope.model.row1 && scope.model.row1.tramTemplate && form.tramTemplates && form.tramTemplates.length > 0){
                        var defaults = $linq(form.tramTemplates).where('x => x.id == '+scope.model.row1.tramTemplate+' && x.allowThirds == true').toArray();
                        if(defaults && defaults.length > 0){
                            hide = false;
                        }
                    }
                    return hide;
                };
                form.addGroup('row5', '', [fields.thirdLabel], 'annexaRow', hideFunction);
                form.addGroup('row6', '', fields.thirds, 'annexaRow', hideFunction);
                newModel.row5 = {};
                newModel.row6 = {};
            }

            var typeSelect = {};
            newModel.row7 = {};
            if(fields.documentType && fields.documentType.templateOptions && fields.documentType.templateOptions.options && fields.documentType.templateOptions.options.length > 0) {
                if ($rootScope.app.configuration && $rootScope.app.configuration.sec_document_types && $rootScope.app.configuration.sec_document_types.SPR.id
                		&& transaction && transaction.transactionType && transaction.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[1].id) {
                	typeSelect = $linq(fields.documentType.templateOptions.options).singleOrDefault(undefined, "x => x.id == " + $rootScope.app.configuration.sec_document_types.SPR.id[0]);
            	} else if ($rootScope.app.configuration && $rootScope.app.configuration.sec_document_types && $rootScope.app.configuration.sec_document_types.STR.id
            			&& transaction && transaction.transactionType && transaction.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[3].id) {
            		typeSelect = $linq(fields.documentType.templateOptions.options).singleOrDefault(undefined, "x => x.id == " + $rootScope.app.configuration.sec_document_types.STR.id);
            	} else if ($rootScope.app.configuration && $rootScope.app.configuration.general_document_type && $rootScope.app.configuration.general_document_type.value) {
                    typeSelect = $linq(fields.documentType.templateOptions.options).singleOrDefault(undefined, "x => x.id == " + $rootScope.app.configuration.general_document_type.value);
                }
                if (!typeSelect) {
                    typeSelect = fields.documentType.templateOptions.options[0].id;
                }else{
                    typeSelect = typeSelect.id;
                }
                _.forEach(fields.documentType.templateOptions.options, function (type) {
                    var custom_fields = $linq(type.customFields).orderBy("x => x.viewOrder").toArray();
                    var fieldsCF = [];
                    _.forEach(custom_fields, function (field) {
                        var formCF = new AnnexaFormly();
                        formCF = CustomFieldFactory.addFormlyFieldRow(formCF, field.customField, field.required, type.id + '_' + field.customField.id);
                        formCF.fields[0].className = '';
                        formCF.fields[0].hideExpression = function ($viewValue, $modelValue, scope) {
                            if (!field.noEditable) {
                                if (!scope.model  || !scope.model.row7 || !scope.model.row7.typeSelect || type.id != scope.model.row7.typeSelect) {
                                    return true;
                                }
                            } else {
                                return true;
                            }
                        }


                        fieldsCF.push(formCF.fields[0]);
                        newModel['cf_' + type.id + '_' + field.customField.id] = CustomFieldFactory.calculateValueCustomField(field, newModel, ('cf_' + type.id + '_' + field.customField.id));
                    });
                    form.fields.push.apply(form.fields, fieldsCF);
                });
            }
            newModel.row7.typeSelect = typeSelect;
            fields.tramTemplate.templateOptions.typeSelect = typeSelect;

            form.model = newModel;

            var submitNewDocumentFromDossierTemplate = function () {
                form.form.$setSubmitted();

                if(form.form.$valid) {
                    var hide = true;
                    var thirdsSelected = [];
                    if(form.model && form.model.row1 && form.model.row1.tramTemplate && form.tramTemplates && form.tramTemplates.length > 0){
                        var defaults = $linq(form.tramTemplates).where('x => x.id == '+form.model.row1.tramTemplate+' && x.allowThirds == true').toArray();
                        if(defaults && defaults.length > 0){
                            hide = false;
                        }
                    }

                    if(!hide && dossier && dossier.thirds){
                        angular.forEach(dossier.thirds,function(value, key) {
                            if(form.model && form.model.row6 && form.model.row6['receivers'+key+'_'+value.third.id]){
                                thirdsSelected.push({id:value.id, dossier:{id:dossier.id}, third:{id:value.third.id}});
                            }
                        });
                    }
                    
                    if (!DccumentsFactory.validDocumentName(form.model.row2.documentTitle, form.model.row7.typeSelect)) {
                    	modal.alerts.push({msg: 'global.errors.docNameFormat'});
                    } else if(!hide && thirdsSelected.length == 0){
                        modal.alerts.push({msg: 'global.errors.noDestinataris'}); 
                    } else {
                        $rootScope.showLoadingdivSignin = true;
                        var fields = new documentFormFields(tram_templates, document_types, ((dossier && dossier.thirds)?dossier.thirds:[]), transaction);
                        var documentModel = {};

                        if (form.model.row1) {
                            documentModel.tramTemplate = form.model.row1.tramTemplate;
                        }

                        if (form.model.row2) {
                            documentModel.documentTitle = form.model.row2.documentTitle;
                        }

                        if (form.model.row3) {
                            documentModel.section = form.model.row3.section;
                        } else {
                            if (fields.section.templateOptions.options.length > 0) {
                                documentModel.section = fields.section.templateOptions.options[0][fields.section.templateOptions.valueProp];
                            } else {
                                documentModel.section = null;
                            }
                        }

                        if (form.model.row4) {
                            if(form.model.row4.profile) {
                                documentModel.profile = form.model.row4.profile;
                                dcoumentModel.profiles = undefined;
                            } else if(form.model.row4.profiles) {
                                documentModel.profile = undefined;
                                documentModel.profiles = form.model.row4.profiles;
                            }
                        } else {
                            documentModel.profile = undefined;
                            if (fields.profiles.templateOptions.options.length > 0) {
                                documentModel.profiles = [fields.profiles.templateOptions.options[0][fields.profiles.templateOptions.valueProp]];
                            } else {
                                documentModel.profiles = null;
                            }
                        }
                        if(form.model && form.model.row8.archiveClassification && form.model.row8.archiveClassification.$selected && form.model.row8.archiveClassification.$selected.id){
                        	documentModel.archiveClassification = { id: form.model.row8.archiveClassification.$selected.id };
                        }
                        documentModel.thirdsSelected = thirdsSelected;
                        if(form.model && form.model.row7 && form.model.row7.typeSelect) {
                            documentModel.type = {id:  form.model.row7.typeSelect};
                        }

                        var cf = CustomFieldFactory.getModelValues(form.model);
                        documentModel.customFields = [];
                        var documentType = $linq(GlobalDataFactory.documentTypes).singleOrDefault(undefined, "x => x.id == " + documentModel.type.id);
                        if(documentType && cf && cf.length > 0) {
                            _.forEach(documentType.customFields, function(docTypeField) {
                                var docCustomField = {
                                    customField: { id: docTypeField.customField.id },
                                    required: docTypeField.required,
                                    viewOrder: docTypeField.viewOrder,
                                    value: docTypeField.value,
                                    noEditable: docTypeField.noEditable
                                };

                                var cfValue = $linq(cf).singleOrDefault(undefined, "x => x.id == 'cf_" + documentType.id  + "_" + docTypeField.customField.id + "'");

                                if(cfValue) {
                                    if(docTypeField.customField.frontendType == 'CHECKBOX'){
                                        var custom_field_selected = $linq(cf).where(function(x){
                                            if(x.id.startsWith(cfValue.id+"_")){return true}else{return false}}
                                        ).toArray();
                                        if(custom_field_selected && custom_field_selected.length > 0){
                                            docCustomField.value = '[';
                                            _.forEach(custom_field_selected, function (item, key) {
                                                if(item.value) {
                                                    if(docTypeField.customField && docTypeField.customField.listValues && docTypeField.customField.listValues.length > 0) {
                                                        var custom_field_value_selected = $linq(docTypeField.customField.listValues).where(function(x){
                                                            if(item.id.endsWith("_"+x.value)){return true}else{return false}}
                                                        ).toArray();
                                                        if(custom_field_value_selected && custom_field_value_selected.length > 0){
                                                            docCustomField.value += ((docCustomField.value == '[') ?custom_field_value_selected[0].value : ',' + custom_field_value_selected[0].value);
                                                        }
                                                    }
                                                }
                                            });
                                            docCustomField.value += ']';
                                        }
                                    }else if(Array.isArray(cfValue.value)) {
                                        if(docTypeField.customField.frontendType == 'MULTIPLESELECT') {
                                            docCustomField.value = '[';

                                            _.forEach(cfValue.value, function (item) {
                                                docCustomField.value += ((docCustomField.value == '[') ? item : ',' + item);
                                            });

                                            docCustomField.value += ']';
                                        } else {
                                            docCustomField.value = cfValue.value[0];
                                        }
                                    } else {
                                        if(cfValue.value instanceof Date) {
                                            docCustomField.value = $filter('date')(cfValue.value,'yyyy-MM-ddTHH:mm:ss');
                                        } else {
                                            docCustomField.value = cfValue.value;
                                        }
                                    }
                                }

                                documentModel.customFields.push(docCustomField);
                            });
                        }


                        newDocumentFromTemplate(transaction, documentModel, processNewDocumentFromTemplate, relatedDocument)
                            .then(function (data) {
                            }).catch(function (error) {
                            $rootScope.showLoadingdivSignin = false;
                            modal.alerts.push({msg: ErrorFactory.getErrorMessage('dossier', 'template', error.data)});
                        });
                    }
                }
            };

            var processNewDocumentFromTemplate = function(documents) {
                if(boxDefinition){
	            	if(documents && documents.length > 0){
	                    angular.forEach(documents, function(value, key){
	                        boxDefinition.content.push(value);
	                    });
	                }
	                $rootScope.$broadcast('annexaBoxDocumentsTramUpdateCounter', { content: boxDefinition.content, relationType: 'DOCUMENT', transactionId:((transaction)?transaction.id:undefined) });
                }else{
                	if(documents && documents.length > 0){
	                    angular.forEach(documents, function(value, key){
	                        transaction.documents.push(value);
	                    });
	                }
                	if(isShowTransaction){
                		$rootScope.$broadcast('annexaBoxDocumentsTramUpdateCounter', { content: transaction.documents, relationType: 'DOCUMENT', transactionId:((transaction)?transaction.id:undefined)  });
                	}
                }
                $rootScope.$broadcast('annexaBoxDocumentsTramUpdateCounterReloadTable', { reload:true });
                $rootScope.showLoadingdivSignin = false;
                form.close();
            };

            form.addSubmitCallback(submitNewDocumentFromDossierTemplate);

            return form;
        };

        var newDocumentFromTemplate = function(transaction, model, processFunc, relatedDocument) {
            var deferrend = $q.defer();

            TramFactory.fromTemplate(transaction, model.tramTemplate, model.documentTitle, model.section, model.profile, model.thirdsSelected, model.profiles, model.type, model.customFields, model.archiveClassification, relatedDocument)
                .then(function (data) {
                    processFunc(data);
                    deferrend.resolve(data);
                }).catch(function (error) {
                    deferrend.reject(error);
            });

            return deferrend.promise;
        };

        factory.showNewDocumentFromTemplateModal = function (transaction, tram_templates, document_types, boxDefinition, isShowTransaction, relatedDocument) {
            var date = new Date();
            var modal = angular.copy(globalModals.dossierDocFromTemplate);
            modal.alerts = [];
            var templatesOk = tram_templates;
            if(TramFactory.Dossier && (!TramFactory.Dossier.thirds || TramFactory.Dossier.thirds.length == 0)){
                templatesOk = $linq(templatesOk).where("x => !x.allowThirds").toArray();
            }
            templatesOk = $linq(templatesOk).where(function(x) {
	            if(x.profiles && x.profiles.length > 0){
	                var profiles = $linq(x.profiles).intersect($rootScope.LoggedUser.userProfiles, function(x,y){
	                    if(y.profile){
	                        return (x.profile.id == y.profile.id);
	                    }
	                    else{
	                        return true;
	                    }
	                }).toArray();
	                if(profiles && profiles.length > 0){
	                   return true;
	                } else {
	                    return false;
	                }
	            } else {
	            	return true;
	            }
            }).toArray();
            var idModal = date.getTime() + '';
            var entity = new AnnexaEntity(idModal,'modal', documentFromTemplateForm(transaction, templatesOk, document_types, modal, boxDefinition, TramFactory.Dossier, idModal, isShowTransaction, relatedDocument), []);

            showModal(modal, entity);
        };

        //endregion

        //region Tercers

        var getThirdBoxesModel = function (formExtra) {
            var indexContacts = $linq(formExtra).indexOf("x => x.boxDefinition.title == 'global.thirds.literals.form.contactData'");
            var indexCosents = $linq(formExtra).indexOf("x => x.boxDefinition.title == 'global.literals.consents'");
            var indexDocuments = $linq(formExtra).indexOf("x => x.boxDefinition.title == 'global.thirds.literals.thirdDocs'");
            var indexReceivers = $linq(formExtra).indexOf("x => x.boxDefinition.title == 'global.thirds.literals.repr'");
            
            return {
                contacts: indexContacts != -1 ? formExtra[indexContacts].boxDefinition.content : [],
                consents: indexCosents != -1 ? formExtra[indexCosents].boxDefinition.content : [],
                documents: indexDocuments != -1 ? formExtra[indexDocuments].boxDefinition.content : [],
                receivers: indexReceivers != -1 ? formExtra[indexReceivers].boxDefinition.content : []
            };
        };

        factory.thirdFormFields = function(colClass) {
            var form = new AnnexaFormly();

            var fields = {};

            var thirdTypes = [
                {"id": "PHISICAL", "name": "global.thirds.literals.phisical"},
                {"id": "LEGAL", "name": "global.thirds.literals.legal"},
                {"id": "PERSONWITHOUTLEGALPERSONALITY", "name": "global.thirds.literals.personwithoutlegalpersonality"}
            ];

            var thirdIdentificationDocumentTypes = [
                {"id": "DNI", "name": "DNI/NIF"},
                {"id": "NIE", "name": "NIE/NIF"},
                {"id": "NIF", "name": "NIF"}
            ];
            
            var thirdIdentificationDocumentTypesLegal = [
        		{"id": "NIF", "name": "NIF"},
        		{"id": "OTHER", "name": "global.literals.other"}
        	];

            //En modo ESET, en caso de personas físicas debe mantenerse el literal Pasaporte, si no debe ser Otros
            if($rootScope.esetMode) {
            	thirdIdentificationDocumentTypes.push({"id": "OTHER", "name": "global.thirds.literals.passport"});
            } else { 
                thirdIdentificationDocumentTypes.push({"id": "OTHER", "name": "global.literals.other"});
            }

            var data = {
                row: true,
                colClass: 'col-sm-12',
                labelClass: 'label-strong',
                extralabel: true
            };

            // Tipus tercer
            var field1 = form.createField(
                'thirdType',
                'annexaSelectRow',
                colClass,
                new AnnexaFormlyFieldSelectTemplateOptions(
                    'global.literals.type',
                    'id',
                    'name',
                    thirdTypes,
                    true,
                    undefined,
                    function(item, options) {
                        if ((item.id == 'LEGAL' || item.id == 'PERSONWITHOUTLEGALPERSONALITY') || (item == 'LEGAL' || item == 'PERSONWITHOUTLEGALPERSONALITY')) {
                        	loadFieldTemplateOptions(fields.identificationDocumentType, thirdIdentificationDocumentTypesLegal);
                        } else {
                        	loadFieldTemplateOptions(fields.identificationDocumentType, thirdIdentificationDocumentTypes);
                        }
                        
                        if (fields.identificationDocument) {
                        	//Valida el campo con el numero de documento
                        	runValidators(fields.identificationDocument);
                        }
                    }
                ),
                data
            );
            field1.hideExpression = function($viewValue, $modelValue, scope) {
            	if (scope.$parent.model.row2 && (scope.$parent.model.row2.counter == undefined || scope.$parent.model.row2.counter > 1)) {            		
            		scope.$parent.model.row2.identificationDocumentType = undefined;
            	}
            	if (scope.$parent.model.row2 && scope.$parent.model.row2.counter != undefined) {             		
            		scope.$parent.model.row2.counter = scope.$parent.model.row2.counter + 1;
            	}
            	if (fields.identificationDocument) {            		
            		//Valida el campo con el numero de documento
                	runValidators(fields.identificationDocument);
            	}
            	return false; 
            };
            fields.thirdType = field1;

            // Tipus identificacio
            var field2 = form.createField(
                'identificationDocumentType',
                'annexaSelectRow',
                colClass,
                new AnnexaFormlyFieldSelectTemplateOptions(
                    'global.thirds.literals.identificationdocumenttype',
                    'id',
                    'name',
                    thirdIdentificationDocumentTypes,
                    true,
                    undefined,
                    function(item, options) {
                    	if (fields.identificationDocument) {
                    		//Valida el campo con el numero de documento
                    		runValidators(fields.identificationDocument);
                    	}
                    }
                ),
                data
            );
            field2.hideExpression = function($viewValue, $modelValue, scope) {
            	if (fields.identificationDocument) {            		
            		//Valida el campo con el numero de documento
                	runValidators(fields.identificationDocument);
            	}
            	return false; 
            };
            fields.identificationDocumentType = field2;

            // Nombre identificador
            var field3 = form.createField(
                'identificationDocument',
                'annexaInputRow',
                colClass,
                new AnnexaFormlyFieldTemplateOptionsValidator(
                    'text',
                    'global.thirds.literals.identificationdocument',
                    true,
                    undefined,
                    undefined,
                    {
                        "documentTypeInvalid": {
                            "expression": function (viewValue, modelValue, scope) {                            	
                                if (modelValue && fields.identificationDocumentType.value() && fields.thirdType.value()) {
                                    if (HelperService.validateDocumentByType(modelValue, fields.identificationDocumentType.value(), fields.thirdType.value())) {
                                        return true;
                                    } else {
                                        return false;
                                    }
                                } else {
                                    return true;
                                }
                            }
                        }
                    },
                    255
                ),
                data
            );
            fields.identificationDocument = field3;

            // Nom
            var field4 = form.createField(
                'name',
                'annexaInputRow',
                colClass,
                new AnnexaFormlyFieldTemplateOptions(
                    'text',
                    'global.literals.name',
                    true,
                    undefined,
                    undefined,
                    255
                ),
                data
            );
            fields.name = field4;

            // Cognom1
            var field5 = form.createField(
                'surename1',
                'annexaInputRow',
                colClass,
                new AnnexaFormlyFieldTemplateOptions(
                    'text',
                    'global.literals.surename1',
                    true,
                    undefined,
                    undefined,
                    255
                ),
                data
            );
            fields.surename1 = field5;

            // Cognom2
            var field7 = form.createField(
                'surename2',
                'annexaInputRow',
                colClass,
                new AnnexaFormlyFieldTemplateOptions(
                    'text',
                    'global.literals.surename2',
                    false,
                    undefined,
                    undefined,
                    255
                ),
                data
            );
            fields.surename2 = field7;

            // Nom empresa
            var field6 = form.createField(
                'corporateName',
                'annexaInputRow',
                colClass,
                new AnnexaFormlyFieldTemplateOptions(
                    'text',
                    'global.literals.corporateName',
                    true,
                    undefined,
                    undefined,
                    255
                ),
                data
            );
            fields.corporateName = field6;

            return fields;
        };
        
        function loadFieldTemplateOptions(field, load){
        	field.templateOptions.options = [];
        	angular.forEach(load, function (element){
        		field.templateOptions.options.push(element);
        	});
    	}
        
        function runValidators(field) {
        	if (field.formControl && field.formControl.length > 0) {        		
        		angular.forEach(field.formControl, function (value, key) {
        			value.$$runValidators(field.value(), field.value(), angular.noop);
                });        		
        	}
        }

        var thirdNewForm = function(saveOkState) {
            var fields = factory.thirdFormFields('col-sm-12');

            var form = new AnnexaFormly();
            form.addGroup('row1', '', [fields.thirdType], 'annexaRow');
            form.addGroup('row2', '', [fields.identificationDocumentType], 'annexaRow');
            form.addGroup('row3', '', [fields.identificationDocument], 'annexaRow');
            form.addGroup('row4', '', [fields.name], 'annexaRow', 'model.row1.thirdType != "PHISICAL"');
            form.addGroup('row5', '', [fields.surename1], 'annexaRow', 'model.row1.thirdType != "PHISICAL"');
            form.addGroup('row6', '', [fields.surename2], 'annexaRow', 'model.row1.thirdType != "PHISICAL"');
            form.addGroup('row7', '', [fields.corporateName], 'annexaRow', 'model.row1.thirdType == "PHISICAL" || !model.row1.thirdType');

            var submitNewThird = function() {
                form.form.$setSubmitted();

                if(form.form.$valid) {
                    var thirdModel = { third: {}};

                    if(form.model.row1) {
                        thirdModel.third.thirdType = form.model.row1.thirdType;
                    }

                    if(form.model.row2) {
                        thirdModel.third.identificationDocumentType = form.model.row2.identificationDocumentType;
                    }

                    if(form.model.row3) {
                        thirdModel.third.identificationDocument = form.model.row3.identificationDocument;
                    }

                    if(form.model.row4) {
                        thirdModel.third.name = form.model.row4.name;
                    }

                    if(form.model.row5) {
                        thirdModel.third.surename1 = form.model.row5.surename1;
                    }

                    if(form.model.row6) {
                        thirdModel.third.surename2 = form.model.row6.surename2;
                    }

                    if(form.model.row7) {
                        thirdModel.third.corporateName = form.model.row7.corporateName;
                    }

                    var boxesModel = getThirdBoxesModel(form.extra);

                    thirdModel.contacts = boxesModel.contacts;
                    thirdModel.documents = boxesModel.documents;
                    thirdModel.receivers = boxesModel.receivers;
                    thirdModel.consents = boxesModel.consents;

                    newThird(thirdModel, saveOkState)
                        .then(function(data){
                        }).catch(function (error) {
                            form.addAlert({ msg: error });
                    });
                }
            };

            form.addSubmitCallback(submitNewThird);

            return form;
        };

        factory.getThirdBoxNewForm = function(modal, processFunc) {
            return thirdBoxNewForm(undefined, modal, processFunc);
        };

        var thirdBoxNewForm = function(boxDefinition, modal, processFunc) {
            var fields = factory.thirdFormFields('col-sm-6');

            var form = new AnnexaFormly();
            form.addGroup('row1', '', [fields.thirdType], 'annexaRow');
            form.addGroup('row2', '', [fields.identificationDocumentType, fields.identificationDocument], 'annexaRow');
            form.addGroup('row3', '', [fields.name, fields.surename1, fields.surename2], 'annexaRow', "model.row1.thirdType != 'PHISICAL'");
            form.addGroup('row4', '', [fields.corporateName], 'annexaRow', "model.row1.thirdType == 'PHISICAL' || !model.row1.thirdType");

            if(boxDefinition && boxDefinition.createToUser && boxDefinition.user && boxDefinition.user.id){
                form.model = {
                    row1:{thirdType: "PHISICAL"},
                    row2:{identificationDocumentType:"NIF"},
                    row3:{name:boxDefinition.user.name,
                        surename1:boxDefinition.user.surename1,
                        surename2:(boxDefinition.user.surename2)?boxDefinition.user.surename2:""
                    }
                };

            }

            var submitNewThird = function() {
                form.form.$setSubmitted();

                if(form.form.$valid) {
                    var thirdModel = {third: {}};

                    if(form.model.row1) {
                        thirdModel.third.thirdType = form.model.row1.thirdType;
                    }

                    if(form.model.row2) {
                        thirdModel.third.identificationDocumentType = form.model.row2.identificationDocumentType;
                        thirdModel.third.identificationDocument = form.model.row2.identificationDocument;
                    }

                    if(form.model.row3) {
                        thirdModel.third.name = form.model.row3.name;
                        thirdModel.third.surename1 = form.model.row3.surename1;
                        thirdModel.third.surename2 = form.model.row3.surename2;
                    }

                    if(form.model.row4) {
                        thirdModel.third.corporateName = form.model.row4.corporateName;
                    }

                    var boxesModel = getThirdBoxesModel(form.extra);

                    thirdModel.contacts = boxesModel.contacts;
                    thirdModel.documents = boxesModel.documents;
                    thirdModel.receivers = boxesModel.receivers;
                    thirdModel.consents = boxesModel.consents;

                    newThird(thirdModel,undefined, processFunc ? processFunc : processNewThird, boxDefinition, form)
                        .then(function (data) {
                        }).catch(function (error) {
                            modal.alerts.push({ msg: error });
                    });


                }
            };

            var processNewThird = function(third) {
                boxDefinition.searchedValue = { id: third.third.id, object: third.third };
                boxDefinition.searchAdd();
                form.close();
            };

            form.addSubmitCallback(submitNewThird);

            return form;
        };

        var newThird = function(model, okstate, processFunc, boxDefinition, form) {
            var deferrend = $q.defer();

            ThirdFactory.existThirdByIdentificationDocument(model.third.identificationDocument)
                .then(function(data2){
                    if(data2.data && data2.data.length > 0) {
                    	
                    	if ($rootScope.app.configuration.can_duplicate_third_by_document_number.value) {
                			//Se pueden duplicar terceros por numero de documento
	                        if(boxDefinition && boxDefinition.createToUser && boxDefinition.user && boxDefinition.user.id){
	                            boxDefinition.actionIfThirdExist(data2.data, deferrend, model, $rootScope.LoggedUser, form);
	                        }else {
	                            var dlg = dialogs.confirm(
	                                $filter('translate')('global.literals.existThird'),
	                                $filter('translate')('global.literals.existThirdConfirm'),
	                                {
	                                    animation: true,
	                                    backdrop: 'static',
	                                    keyboard: true,
	                                    size: '',
	                                    backdropClass: 'zMax',
	                                    windowClass: 'zMax2'
	                                }
	                            );
	
	                            dlg.result.then(function (btn) {
	                                ThirdFactory.newThird(model.third, $rootScope.LoggedUser, model.contacts, model.documents, model.receivers, model.consents)
	                                    .then(function (data) {
	                                        if (okstate) {
	                                            $state.transitionTo(okstate);
	                                        }
	                                        if (processFunc) {
	                                            processFunc(data, form);
	                                        }
	                                        deferrend.resolve(data);
	                                    }).catch(function (error) {
	                                    deferrend.reject(ErrorFactory.getErrorMessage('thirds', 'new', error));
	                                });
	                            }, function (btn) {
	                            });
	                        }
                    	} else {
                    		//No se pueden duplicar terceros por numero de documento
                			DialogsFactory.notify('DIALOGS_EXIST_THIRD');
                			deferrend.resolve(false);
                    	}                        
                        
                    } else {
                        ThirdFactory.newThird(model.third, $rootScope.LoggedUser, model.contacts, model.documents, model.receivers, model.consents)
                            .then(function (data) {
                                if (okstate) {
                                    $state.transitionTo(okstate);
                                }
                                if (processFunc) {
                                    processFunc(data, form);
                                }
                                deferrend.resolve(data);
                            }).catch(function (error) {
                            deferrend.reject(ErrorFactory.getErrorMessage('thirds', 'new', error));
                        });
                    }
                }).catch(function (error) {
                    deferrend.reject(error);
            });

            return deferrend.promise;
        };

        var contactBox = function(permissions) {
            var contactBox = angular.copy(ThirdFactory.ContactBox)
                .initialize('global.thirds.literals.form.contactData', 'global.literals.placeholder_search_receiver', 'global.thirds.literals.advanced_search_repr', AnnexaPermissionsFactory.haveOnePermission(permissions), factory);
            contactBox.boxDefinition.content = [];
            contactBox.boxDefinition.saveSearch = undefined;

            contactBox.boxDefinition.deleteFunction = function(objectId, index){
                var key = contactBox.boxDefinition.content[index].key;

                var indexConsent = $linq(angular.element('#new-third').scope().$parent.entity.boxes[1].boxDefinition.content).indexOf("x => x.thirdAddress.key == '" + key + "'");
                while(indexConsent != -1) {
                    angular.element('#new-third').scope().$parent.entity.boxes[1].boxDefinition.content.splice(indexConsent, 1);
                    indexConsent = $linq(angular.element('#new-third').scope().$parent.entity.boxes[1].boxDefinition.content).indexOf("x => x.thirdAddress.key == '" + key + "'");
                }
                var entityBoxes = angular.element('#new-third').scope().$parent.entity.boxes;
                contactBox.boxDefinition.content.splice(index, 1);
            };
            
            contactBox.boxDefinition.canEditThird = function(){
                return AnnexaPermissionsFactory.haveSinglePermission('edit_third');
            };

            contactBox.boxDefinition.editFunction = function(objectId, index){
                var key = contactBox.boxDefinition.content[index].key;
            };

            contactBox.boxDefinition.defaultFunction = function(contact, index){
                if(contactBox.boxDefinition.content == 1){
                    contact.default = true;
                }else{
                    var defaults = $linq(contactBox.boxDefinition.content).where('x => x.default == true').toArray();
                    if(defaults && defaults.length > 0){
                        angular.forEach(defaults, function(value, key){value.default = false;});
                    }
                    contact.default = true;
                }
            };
            contactBox.boxDefinition.renderType = 'contact-third';
            contactBox.boxDefinition.rederPermissions = permissions;

            return contactBox;
        };

        var consentBox = function (permissions) {
            var consentBox = angular.copy(ThirdFactory.ConsentBox)
                .initialize('global.literals.consents', '', '', AnnexaPermissionsFactory.haveOnePermission(permissions));
            consentBox.boxDefinition.content = [];
            consentBox.boxDefinition.saveSearch = undefined;
            consentBox.boxDefinition.deleteFunction = function(objectId, index) {
                consentBox.boxDefinition.content.splice(index, 1);
            };
            consentBox.boxDefinition.renderType = 'consent-third';
            consentBox.boxDefinition.rederPermissions = permissions;

            return consentBox;
        };

        var receiverBox = function (permissions, additionalThirdFilter) {
            var receiverBox = getBox('representant', 'receiver-third', 'global.thirds.literals.repr', 'global.literals.placeholder_search_receiver', 'global.thirds.literals.advanced_search_repr', 
            		AnnexaPermissionsFactory.haveOnePermission(permissions), AnnexaPermissionsFactory.haveOnePermission(permissions), AnnexaPermissionsFactory.haveOnePermission(permissions), AnnexaPermissionsFactory.haveOnePermission(permissions),
            		undefined, undefined, undefined, additionalThirdFilter);
            receiverBox.boxDefinition.addSearchAdd(
                globalModals.representationAdd,
                function(selected) {
                    return { third: selected.id, startValidityPeriod: undefined, endValidityPeriod: undefined, object: selected.object };
                },
                function() {
                    var modal_data = this.annexaFormly.model.modal_body;
                    var objThird = JSOG.decode(modal_data.object);

                    var newContent = {
                        agent: objThird,
                        startValidityPeriod: modal_data.startValidityPeriod,
                        endValidityPeriod: modal_data.endValidityPeriod,
                        revoked: false
                    };

                    this.extra.content.push(newContent);
                    this.extra.searchedValue = { id: -1, value: ''};
                    if(this.extra.saveSearch) {
                        this.extra.saveSearch(newContent);
                    }
                    this.close();
                },
                AnnexaFormlyFactory);

            return receiverBox;
        };

        factory.getThirdBoxes = function() {
            return thirdBoxes();
        };

        var thirdBoxes = function () {
            var retBoxes = [];

            if (thirdModule && thirdModule.length == 1) {
                retBoxes.push(contactBox(['new_third']));
            }

            //retBoxes.push(consentBox(['new_third']));

            var documentBox = getBox('document', 'document-third', 'global.thirds.literals.thirdDocs', 'global.literals.placeholder_search_dossiers', 'global.literals.advanced_search_documents', AnnexaPermissionsFactory.haveSinglePermission('new_third'), AnnexaPermissionsFactory.haveSinglePermission('new_third'), AnnexaPermissionsFactory.haveSinglePermission('new_third'), AnnexaPermissionsFactory.haveSinglePermission('new_third'));
            documentBox.boxDefinition.module = "THIRDS";
            if (docModule && docModule.length == 1) {
                retBoxes.push(documentBox);
            }

            if (thirdModule && thirdModule.length == 1) {
                retBoxes.push(receiverBox(['new_third']));
            }

            return retBoxes;
        };

        factory.getEditThirdBox = function (type) {
            var box;

            switch (type) {
                case 'document':
                    box = getBox('document', 'document-third', 'global.thirds.literals.thirdDocs', 'global.literals.placeholder_search_dossiers', 'global.literals.advanced_search_documents', AnnexaPermissionsFactory.haveSinglePermission('edit_third'), AnnexaPermissionsFactory.haveSinglePermission('edit_third'), AnnexaPermissionsFactory.haveSinglePermission('edit_third'), AnnexaPermissionsFactory.haveSinglePermission('edit_third'));
                    box.boxDefinition.saveSearch = function (document) {
                        ThirdFactory.updateThird(ThirdFactory.Third).then(function(data) {
                        }).catch(function (error) {
                            box.boxDefinition.content.pop();                            
                            var err = dialogs.error(
                                $filter('translate')('DIALOGS_ERROR_MSG'),
                                $filter('translate')(ErrorFactory.getErrorMessage('thirds', 'update', error.data )),
                                {
                                    animation: true,
                                    backdrop: 'static',
                                    keyboard: true,
                                    size: ''
                                }
                            );
                        });
                    };
                    box.boxDefinition.deleteFunction = function(objectId, index) {
                        var deletedElement = box.boxDefinition.content[index];
                        box.boxDefinition.content.splice(index, 1);
                        ThirdFactory.updateThird(ThirdFactory.Third).catch(function (error) {
                            box.boxDefinition.content.splice(index, 1, deletedElement);
                        });
                    };
                    break;
                case 'consent':
                    box = consentBox(['edit_third']);
                    box.boxDefinition.saveSearch = function (consent) {
                        var consents = [];
                        if(ThirdFactory.Third.consents) {
                            consents = ThirdFactory.Third.consents;
                        }

                        consents.push(
                            {
                                consentType: consent.consentType,
                                deleted: false,
                                dossier: consent.dossier ? consent.dossier : undefined,
                                familyProcedure: consent.familyProcedure ? consent.familyProcedure : undefined,
                                procedure: consent.procedure ? consent.procedure : undefined,
                                thirdAddress: { id: consent.thirdAddress.id }
                            }
                        );

                        ThirdFactory.Third.consents = consents;

                        ThirdFactory.updateThird(ThirdFactory.Third)
                            .then(function(data) {
                                ThirdFactory.Third.consents = data.consents;
                            }).catch(function(error) {
                            	if (box.boxDefinition.content && box.boxDefinition.content.length > 0) {
                            		box.boxDefinition.content.pop();
                            	}
                            	var err = dialogs.error(
                                    $filter('translate')('DIALOGS_ERROR_MSG'),
                                    $filter('translate')(ErrorFactory.getErrorMessage('thirds', 'update', error.data )),
                                    {
                                        animation: true,
                                        backdrop: 'static',
                                        keyboard: true,
                                        size: ''
                                    }
                                );
                        });
                    };
                    box.boxDefinition.deleteFunction = function(objectId, index) {
                        ThirdFactory.Third.consents[index].removedDate = new Date();
                        ThirdFactory.Third.consents[index].deleted = true;
                        ThirdFactory.updateThird(ThirdFactory.Third)
                            .then(function(data) {
                                box.boxDefinition.content.splice(index, 1);
                                ThirdFactory.Third.consents.splice(index, 1);
                            }).catch(function(error) {
                            ThirdFactory.Third.consents[index].removedDate = undefined;
                            ThirdFactory.Third.consents[index].deleted = false;
                        });
                    };
                    break;
                case 'contact':
                    box = contactBox(['edit_third']);
                    box.boxDefinition.saveSearch = function (document) {
                        ThirdFactory.updateThird(ThirdFactory.Third).then(function(data) {
                            ThirdFactory.Third.addresses.length = 0;
                            angular.forEach(data.addresses, function(value, key){
                                ThirdFactory.Third.addresses.push(value);
                            });
                        }).catch(function (error) {
                        	if (box.boxDefinition.content && box.boxDefinition.content.length > 0) {
                        		box.boxDefinition.content.pop();
                        	}
                            var err = dialogs.error(
                                $filter('translate')('DIALOGS_ERROR_MSG'),
                                $filter('translate')(ErrorFactory.getErrorMessage('thirds', 'update', error.data )),
                                {
                                    animation: true,
                                    backdrop: 'static',
                                    keyboard: true,
                                    size: ''
                                }
                            );
                        });
                    };

                    box.boxDefinition.deleteFunction = function(objectId, index)
                    {
                        if(box.boxDefinition.content && box.boxDefinition.content.length > 1) {
                            box.boxDefinition.content[index].removedDate = new Date();
                            box.boxDefinition.content[index].deleted = true;
                            ThirdFactory.updateThird(ThirdFactory.Third).then(function (data) {
                                box.boxDefinition.content.splice(index, 1);
                            }).catch(function (error) {
                                box.boxDefinition.content[index].removedDate = undefined;
                                box.boxDefinition.content[index].deleted = false;
                                var err = dialogs.error(
                                    $filter('translate')('DIALOGS_ERROR_MSG'),
                                    $filter('translate')(ErrorFactory.getErrorMessage('thirds', 'update', error.data )),
                                    {
                                        animation: true,
                                        backdrop: 'static',
                                        keyboard: true,
                                        size: ''
                                    }
                                );
                            });
                        }else{
                            var err = dialogs.error(
                                $filter('translate')('DIALOGS_ERROR_MSG'),
                                $filter('translate')('global.errors.oneContactRequired'),
                                {
                                    animation: true,
                                    backdrop: 'static',
                                    keyboard: true,
                                    size: ''
                                }
                            );
                        }
                    };

                    box.boxDefinition.editFunction = function(objectId, index)
                    {
                        var editedAddress = box.boxDefinition.content[index];
                        var modal = angular.copy(globalModals.contactsAdd);

                        var completedEdit = function(modal){
                            if(editedAddress.addressType == 'TELEMATIC_MOBILEPHONE'){
                                box.boxDefinition.content[index].telematicValue = box.boxDefinition.content[index].contactPhone;
                                box.boxDefinition.content[index].phoneInternationalPrefix = box.boxDefinition.content[index].contactPrefix;
                            }
                            if(editedAddress.addressType == 'TELEMATIC_EMAIL') {
                                box.boxDefinition.content[index].telematicValue = box.boxDefinition.content[index].contactEmail;
                            }

                            ThirdFactory.updateThird(ThirdFactory.Third).then(function(data){
                                box.boxDefinition.content[index]=data.addresses[index];
                                //$rootScope.$broadcast('contactChangedEvent', { contact: editedAddress, index: index });
                                modal.close();
                            }).catch(function (error) {
                                var err = dialogs.error(
                                    $filter('translate')('DIALOGS_ERROR_MSG'),
                                    $filter('translate')(ErrorFactory.getErrorMessage('thirds', 'update', error.data )),
                                    {
                                        animation: true,
                                        backdrop: 'static',
                                        keyboard: true,
                                        size: ''
                                    }
                                );
                            });
                        };

                        if(editedAddress) {
                            var defaultCountry = $rootScope.app.configuration.default_country;

                            if(defaultCountry && defaultCountry.value){
                                defaultCountry = defaultCountry.value;
                            }else{
                                defaultCountry = '';
                            }

                            var showDefaultByTypeMobile = false;
                            var defaults;
                            defaults = $linq(this.content).where('x => x.defaultByType == true && x.addressType == \'TELEMATIC_MOBILEPHONE\'').toArray();
                            if(defaults && defaults.length > 0){
                                showDefaultByTypeMobile = true;
                            }
                            var showDefaultByTypeEmail = false;
                            defaults = $linq(this.content).where('x => x.defaultByType == true && x.addressType == \'TELEMATIC_EMAIL\'').toArray();
                            if(defaults && defaults.length > 0){
                                showDefaultByTypeEmail = true;
                            }
                            var showDefaultByTypePostal = false;
                            defaults = $linq(this.content).where('x => x.defaultByType == true && x.addressType == \'POSTAL\'').toArray();
                            if(defaults && defaults.length > 0){
                                showDefaultByTypePostal = true;
                            }
                            var showDefault = true;
                            defaults = $linq(this.content).where('x => x.default == true').toArray();
                            if(defaults && defaults.length > 0){
                                showDefault = false;
                            }

                            modal.annexaFormly.model = {};
                            modal.annexaFormly.model.modal_body = editedAddress;

                            modal.annexaFormly.model.modal_body.addressType = editedAddress.addressType;
                            modal.annexaFormly.model.modal_body.hasStates = false;
                            modal.annexaFormly.model.modal_body.hasCities = false;
                            modal.annexaFormly.model.modal_body.hasStreetTypes = true;
                            modal.annexaFormly.model.modal_body.hasStreets = false;
                            if(editedAddress.validState && editedAddress.validState.id){
                                modal.annexaFormly.model.modal_body.hasStates = true;
                            }
                            if(editedAddress.validCity && editedAddress.validCity.id){
                                modal.annexaFormly.model.modal_body.hasCities = true;
                            }
                            modal.annexaFormly.model.modal_body.streetType = editedAddress.streetType;

                            if(editedAddress.validStreet && editedAddress.validStreet.id){
                                modal.annexaFormly.model.modal_body.hasStreets = true;
                                if(editedAddress.validStreet.streetType && editedAddress.validStreet.streetType.id){
                                    modal.annexaFormly.model.modal_body.streetType = editedAddress.validStreet.streetType;
                                }
                            }

                            modal.annexaFormly.model.modal_body.showDefaultByTypeMobile = showDefaultByTypeMobile;
                            modal.annexaFormly.model.modal_body.showDefaultByTypeEmail = showDefaultByTypeEmail;
                            modal.annexaFormly.model.modal_body.showDefaultByTypePostal = showDefaultByTypePostal;
                            modal.annexaFormly.model.modal_body.showDefault = showDefault;

                            if(editedAddress.addressType == 'TELEMATIC_MOBILEPHONE'){
                                modal.annexaFormly.model.modal_body.contactPhone = editedAddress.telematicValue;
                                modal.annexaFormly.model.modal_body.contactPrefix = editedAddress.phoneInternationalPrefix;
                            }

                            if(editedAddress.addressType == 'TELEMATIC_EMAIL'){
                                modal.annexaFormly.model.modal_body.contactEmail = editedAddress.telematicValue;
                            }

                            if(!modal.annexaFormly.model.modal_body.country) {
                                if (defaultCountry) {
                                    modal.annexaFormly.model.modal_body.country = {id: defaultCountry};
                                } else {
                                    modal.annexaFormly.model.modal_body.country = {};
                                }
                            }
                            modal.extra = this;
                            AnnexaFormlyFactory.showModal("modalNewContact", modal, completedEdit, false);
                        }else {
                            var err = dialogs.error(
                                $filter('translate')('DIALOGS_ERROR_MSG'),
                                $filter('translate')('global.errors.oneContactRequired'),
                                {
                                    animation: true,
                                    backdrop: 'static',
                                    keyboard: true,
                                    size: ''
                                }
                            );
                        }
                    };

                    box.boxDefinition.defaultFunction = function(contact, index){
                        if(box.boxDefinition.content == 1){
                            contact.default = true;
                        }else{
                            var defaults = $linq(box.boxDefinition.content).where('x => x.default == true').toArray();
                            if(defaults && defaults.length > 0){
                                angular.forEach(defaults, function(value, key){value.default = false;});
                            }
                            contact.default = true;
                        }
                        ThirdFactory.updateThird(ThirdFactory.Third).then(function(){
                        }).catch(function (error) {
                            var err = dialogs.error(
                                $filter('translate')('DIALOGS_ERROR_MSG'),
                                $filter('translate')(ErrorFactory.getErrorMessage('thirds', 'update', error.data )),
                                {
                                    animation: true,
                                    backdrop: 'static',
                                    keyboard: true,
                                    size: ''
                                }
                            );
                        });
                    };
                    break;
                case 'receiver':
                	var additionalThirdFilter = ThirdFactory.Third.id ? {entityId:ThirdFactory.Third.id} : undefined;
                    box = receiverBox(['edit_third'], additionalThirdFilter);
                    box.boxDefinition.saveSearch = function (receiver) {
                        var representations = [];
                        if(ThirdFactory.Third.representations) {
                            representations = ThirdFactory.Third.representations;
                        }
                        representations.push({
                            represented: ThirdFactory.Third,
                            agent: receiver.agent,
                            startValidityPeriod: receiver.startValidityPeriod,
                            endValidityPeriod: receiver.endValidityPeriod,
                            revoked: receiver.revoked
                        });
                        ThirdFactory.Third.representations = representations;
                        ThirdFactory.updateThird(ThirdFactory.Third).then(function (data) {
                            ThirdFactory.Third.representations = data.representations;
                        }).catch(function (error) {
                        	if (box.boxDefinition.content && box.boxDefinition.content.length > 0) {
                        		box.boxDefinition.content.pop();
                        	}                            
                            var err = dialogs.error(
                                $filter('translate')('DIALOGS_ERROR_MSG'),
                                $filter('translate')(ErrorFactory.getErrorMessage('thirds', 'update', error.data )),
                                {
                                    animation: true,
                                    backdrop: 'static',
                                    keyboard: true,
                                    size: ''
                                }
                            );
                        });
                    };
                    box.boxDefinition.deleteFunction = function(objectId, index)
                    {
                        ThirdFactory.Third.representations[index].removedDate = new Date();
                        ThirdFactory.Third.representations[index].deleted = true;
                        ThirdFactory.updateThird(ThirdFactory.Third).then(function(){
                            box.boxDefinition.content.splice(index, 1);
                            ThirdFactory.Third.representations.splice(index, 1);
                        }).catch(function (error) {
                            ThirdFactory.Third.representations[index].removedDate = undefined;
                            ThirdFactory.Third.representations[index].deleted = false;
                        });
                    };
                    break;
                case 'dossier':
                    box = getBox('dossier', 'dossier-third', 'global.literals.related_dossiers','global.literals.placeholder_search_dossiers', 'global.literals.advanced_search_dossiers', false, false, false, false);
                    break;
                case 'registerEntry':
                    box = getBox('registerEntry', 'registerEntry-third', 'global.literals.assentaments', 'global.literals.placeholser_search_registerEntry', 'global.literals.advanced_search_registerEntry', false, false, false, false);
                    break;
            }

            return box;
        };

        factory.getNewThirdForm = function () {
            var entity = new AnnexaEntity('new-third', 'form', thirdNewForm('annexa.thirds'), thirdBoxes()).setTitle('global.literals.basicInfo').setCancelState('annexa.thirds');

            return entity;
        };

        factory.showNewThirdModal = function (boxDefinition) {
            var date = new Date();
            var modal = globalModals.receiverNew;
            modal.title = 'global.literals.new_receiver_modal_title';
            if(boxDefinition && boxDefinition.entryType){
                if(boxDefinition.entryType == 'INPUT'){
                    modal.title = 'global.literals.newRemitent';
                }else if(boxDefinition.entryType == 'OUTPUT'){
                    modal.title = 'global.literals.new_receiver_modal_title';
                }
            }
            var entity = new AnnexaEntity(date.getTime() + '', 'modal', thirdBoxNewForm(boxDefinition, modal), thirdBoxes());
            if(boxDefinition && boxDefinition.createToUser && boxDefinition.user && boxDefinition.user.id){
                var contactMail = null;
                if(boxDefinition.user.email){
                    contactMail = {};
                    contactMail.addressType = 'TELEMATIC_EMAIL';
                    contactMail.key = CommonService.guid();
                    contactMail.default =  true;
                    contactMail.defaultByType = true;
                    contactMail.telematicValue = boxDefinition.user.email;
                    entity.boxes[0].boxDefinition.content.push(contactMail);
                }
                var contactCellPhone = null;
                if(boxDefinition.user.cellPhone){
                    contactCellPhone = {};
                    contactCellPhone.addressType = 'TELEMATIC_MOBILEPHONE';
                    contactCellPhone.key = CommonService.guid();
                    contactCellPhone.default = (!contactMail)?true:false;
                    contactCellPhone.defaultByType = true;
                    contactCellPhone.telematicValue = boxDefinition.user.cellPhone;
                    entity.boxes[0].boxDefinition.content.push(contactCellPhone);
                }
                var contactPhone = {};
                if(boxDefinition.user.phone){
                    contactPhone.addressType = 'TELEMATIC_MOBILEPHONE';
                    contactPhone.key = CommonService.guid();
                    contactPhone.default =  (!contactMail && !contactCellPhone)?true:false;
                    contactPhone.defaultByType = (!contactCellPhone)?true:false;
                    contactPhone.telematicValue = boxDefinition.user.phone;
                    entity.boxes[0].boxDefinition.content.push(contactPhone);
                }
            }
            showModal(modal, entity);
        };

        //endregion

        //region Tram

        var getTramBoxesModel = function (formExtra) {
            return {
                receivers: formExtra[0].boxDefinition.content,
                documents: formExtra[1].boxDefinition.content,
                registerInputs: formExtra[2].content,
                registerOutputs: formExtra[3].content,
                dossiers: formExtra[4].boxDefinition.content,
            };
        };

        var tramFormFields = function(colClass) {
            var form = new AnnexaFormly();
            var fields = {};
            var data = {
                row: true,
                colClass: 'col-sm-12',
                labelClass: 'label-strong'
            };
            if(!TramFactory.Procedure.guided && TramFactory.Procedure.allowUserChangeMaxDays) {
                fields.allowUserChangeMaxDays = form.createField('maxDays','annexaInputRow', '', new AnnexaFormlyFieldTemplateOptions('number', 'global.literals.days', true, false), data);
            } else {
                fields.allowUserChangeMaxDays = undefined;
            }
            if(!TramFactory.Procedure.guided && TramFactory.Procedure.transactionTypes && TramFactory.Procedure.transactionTypes.length > 1) {
                var transactionTypesAux = [];
                angular.forEach(TramFactory.Procedure.transactionTypes,function(value, key){
                    transactionTypesAux.push(value.transactionType);
                });
                fields.transactionTypes = form.createField('transactionType','annexaSelectRow', '', new AnnexaFormlyFieldSelectTemplateOptions('global.literals.initialTransactionType','id',Language.getActiveColumn(),transactionTypesAux,true), data);
            } else {
                fields.transactionTypes = undefined;
            }
            if(!TramFactory.Procedure.guided){
                fields.tramitationType = form.createField('tramitationType','annexaSelectRow', '', new AnnexaFormlyFieldSelectTemplateOptions('global.literals.tramitationType','id',Language.getActiveColumn(),TramFactory.dossierTramitationTypes,true), data);
            }
            switch (TramFactory.Procedure.showSubject) {
                case "OPTIONAL":
                    fields.subject = form.createField('subject','annexaInputRow','',new AnnexaFormlyFieldTemplateOptions('text','global.literals.subject',false,false,undefined,255),data);
                    break;
                case "REQUIRED":
                    fields.subject = form.createField('subject','annexaInputRow','',new AnnexaFormlyFieldTemplateOptions('text','global.literals.subject',true,false,undefined,255),data);
                    break;
            }
            fields.archiveClassification = form.createField('archiveClassification','annexaSelectTreeRow','',new AnnexaFormlyFieldSelectTemplateOptions('global.literals.classificationBox','id',Language.getActiveColumn(),CommonService.getTreeData(TramFactory.archiveClassifications, Language.getActiveColumn()),false),data);
            switch (TramFactory.Procedure.showClassification) {
                case "OPTIONAL":
                    fields.classification = form.createField(
                        'classification',
                        'annexaSelectRow',
                        '',
                        new AnnexaFormlyFieldSelectTemplateOptions(
                            'global.literals.classification',
                            'id',
                            Language.getActiveColumn(),
                            TramFactory.Procedure.procedureClassifications,
                            false
                        ),
                        data
                    );
                    break;
                case "REQUIRED":
                    fields.classification = form.createField(
                        'classification',
                        'annexaSelectRow',
                        '',
                        new AnnexaFormlyFieldSelectTemplateOptions(
                            'global.literals.classification',
                            'id',
                            Language.getActiveColumn(),
                            TramFactory.Procedure.procedureClassifications,
                            true
                        ),
                        data
                    );
                    break;
            }

            fields.observations = form.createField('observations', 'annexaTextAreaRow','', new AnnexaFormlyFieldTextAreaTemplateOptions('global.literals.observations'), data);
            
            //Perfiles, dependiendo si es o no E-SET
            if($rootScope.esetMode){
            	//Modo E-SET
                var profilesResponsibleTransaction = [];
                var responsiblesTransaction = [];
                if(TramFactory.Procedure && TramFactory.Procedure.dossierResponsibleProfiles && TramFactory.Procedure.dossierResponsibleProfiles.length > 0){
                    profilesResponsibleTransaction = TramFactory.Procedure.dossierResponsibleProfiles;
                }else if(TramFactory.Procedure && TramFactory.Procedure.procedureStartProfiles && TramFactory.Procedure.procedureStartProfiles.length > 0){
                    profilesResponsibleTransaction = TramFactory.Procedure.procedureStartProfiles;
                }
                if(profilesResponsibleTransaction && profilesResponsibleTransaction.length == 1){
                    _.forEach(profilesResponsibleTransaction[0].profile.userProfiles, function(value){
                    	if(!value.user.deleted && !value.user.expiryDate){
                    		responsiblesTransaction.push({id:value.user.id, name:value.user.name+" "+value.user.surename1+((value.user.surename2)?" "+value.user.surename2:"")});
                    	}
                    });
                }else {
                    fields.profilesTransaction = form.createField(
                        'profilesTransaction',
                        'annexaSelectRow',
                        '',
                        new AnnexaFormlyFieldSelectTemplateOptions(
                            'global.literals.initialProfile',
                            'id',
                            Language.getActiveColumn(),
                            $linq(profilesResponsibleTransaction).select("x => x.profile").toArray(),
                            true,
                            undefined,
                            function($item, $model) {
                                if($item.userProfiles && $item.userProfiles.length > 0) {
                                    responsiblesTransaction.length = 0;
                                    _.forEach($item.userProfiles, function(value){
                                        responsiblesTransaction.push({id:value.user.id, name:value.user.name+" "+value.user.surename1+((value.user.surename2)?" "+value.user.surename2:"")});
                                    });
                                }else{
                                    responsiblesTransaction.length = 0;
                                }
                            }
                        ),
                        data
                    );
                }
                fields.responsibleTransaction = form.createField(
                    'responsibleTransaction',
                    'annexaRadioCheckboxRow',
                    '',
                    new AnnexaFormlyFieldSelectTemplateOptions(
                        'global.literals.initialuserResponsible',
                        'id',
                        'name',
                        responsiblesTransaction,
                        true,
                        'radio'
                    ),
                    data
                );
                
            } else {
            	
            	//Modo NO E-SET
	            // MRS-I
	            /**
	             *  El usuario tiene un perfile predeterminado (REG y DOC y EXP), entonces se quiere contar con ese tipo de perfil
	             *  y tambien con otros si estan en el procedimiento que se inicializa o responsables. Para el primer tramite.
	             *
	             *  1. Si el procedimiento tiene definidos perfiles responsables, al crear el expediente se debe dejar escoger entre estos
	             *     perfiles y los que se escojan se asignan al primer trámite del expediente y como perfiles responsables del expediente
	             *
	             *  2. Si el procedimiento no tiene definidos perfiles responsables, al crear el expediente se debe dejar escoger entre los perfiles
	             *     iniciadores y los que se escojan se asignan al primer trámite del expediente y como perfiles responsables del expediente
	             */
	            var entityProfiles = $rootScope.LoggedUser.userDefaultEntityProfiles;
	            fields.profiles = [];
	
	            // si el usuario tiene perfil por defecto (DOC, REG, EXP)
	            if( entityProfiles ){
	
	                // nos quedamos con Expedientes
	                var defaultProfileUserEXP = $linq(entityProfiles).where("x => x.entityType == 'EXP'").select("x => x.profile").toArray();
	
	                if(defaultProfileUserEXP && defaultProfileUserEXP.length > 0) {
	                    if (TramFactory.Procedure && TramFactory.Procedure.procedureResponsibleProfiles && TramFactory.Procedure.procedureResponsibleProfiles.length > 0) {
	
	                        var tramResponsible = TramFactory.Procedure.procedureResponsibleProfiles;
	                        var tramResponsibleProfile = $linq(tramResponsible).select("x => x.profile").toArray();
	
	                        fields.profiles = form.createField(
	                            'profiles', 'annexaMultipleSelectRow', '',
	                            new AnnexaFormlyFieldSelectTemplateOptions('global.literals.initialProfiles', 'id',
	                                Language.getActiveColumn(), tramResponsibleProfile, true),
	                            data);
	                    }
	                    else {
	                        if (TramFactory.Procedure && TramFactory.Procedure.procedureStartProfiles && TramFactory.Procedure.procedureStartProfiles.length > 0) {
	
	                            var tramStart = TramFactory.Procedure.procedureStartProfiles;
	                            var tramStartProfile = $linq(tramStart).select("x => x.profile").toArray();
	                            var userProfiles = $linq($rootScope.LoggedUser.userProfiles).distinctBy("x => x.profile.id").select("x => x.profile").toArray();
	                            //Si el procedimiento no tiene definidos perfiles responsables, solo los perfiles iniciadores que tiene el usuario. 
	                            tramStartProfile = $linq(tramStartProfile).intersect(userProfiles, "(x,y) => x.id == y.id").toArray();
	
	                            fields.profiles = form.createField(
	                                'profiles', 'annexaMultipleSelectRow', '',
	                                new AnnexaFormlyFieldSelectTemplateOptions('global.literals.initialProfiles', 'id',
	                                    Language.getActiveColumn(), tramStartProfile, true),
	                                data);
	                        }
	                        // si no existen los que inicializa y tampoco responsables.
	                        else {
	                            fields.profiles = form.createField(
	                                'profiles', 'annexaLabelRow', 'col-sm-6 col-md-6 col-xl-6 col-lg-6',
	                                new AnnexaFormlyFieldLabelTemplateOptions('global.literals.initialProfiles',
	                                    defaultProfileUserEXP[0].profile[Language.getActiveColumn()]),
	                                data);
	                        }
	                    }
	                }
	                else{
	                    if (TramFactory.Procedure && TramFactory.Procedure.procedureResponsibleProfiles && TramFactory.Procedure.procedureResponsibleProfiles.length > 0) {
	
	                        var tramRespon = TramFactory.Procedure.procedureResponsibleProfiles;
	                        var tramResponProfile = $linq(tramRespon).select("x => x.profile").toArray();
	
	                        fields.profiles = form.createField(
	                            'profiles', 'annexaMultipleSelectRow', '',
	                            new AnnexaFormlyFieldSelectTemplateOptions('global.literals.initialProfiles', 'id',
	                                Language.getActiveColumn(), tramResponProfile, true),
	                            data);
	                    }
	                    else {
	                        if (TramFactory.Procedure && TramFactory.Procedure.procedureStartProfiles && TramFactory.Procedure.procedureStartProfiles.length > 0) {
	
	                            var tramInicia = TramFactory.Procedure.procedureStartProfiles;
	                            var tramIniciaProfile = $linq(tramInicia).select("x => x.profile").toArray();
	                            var userProfiles = $linq($rootScope.LoggedUser.userProfiles).distinctBy("x => x.profile.id").select("x => x.profile").toArray();
	                            //Si el procedimiento no tiene definidos perfiles responsables, solo los perfiles iniciadores que tiene el usuario. 
	                            tramIniciaProfile = $linq(tramIniciaProfile).intersect(userProfiles, "(x,y) => x.id == y.id").toArray();
	
	                            fields.profiles = form.createField(
	                                'profiles', 'annexaMultipleSelectRow', '',
	                                new AnnexaFormlyFieldSelectTemplateOptions('global.literals.initialProfiles', 'id',
	                                    Language.getActiveColumn(), tramIniciaProfile, true),
	                                data);
	                        }
	                    }
	                }
	            }
            }
            
            // MRS-F
            var userSections = $linq($rootScope.LoggedUser.userSections).distinctBy("x => x.section.id").select("x => x.section").toArray();
            var addSection = false;

            if(userSections && userSections.length == 1) {
                var sectionValue = userSections[0];
                var field13 = form.createField(
                    'section',
                    'annexaHidden',
                    colClass,
                    new AnnexaFormlyFieldTemplateOptions(
                        'hidden',
                        '',
                        true
                    ),
                    data,
                    undefined,
                    sectionValue
                )
                fields.section2 = field13;
            } else {
                fields.section1 = form.createField(
                    'section',
                    'annexaSelectRow',
                    '',
                    new AnnexaFormlyFieldSelectTemplateOptions(
                        'global.literals.section',
                        'id',
                        Language.getActiveColumn(),
                        ((userSections)?userSections:[]),
                        true
                    ),
                    data
                );
            }
            return fields;
        };

        var tramNewForm = function(saveOkState) {
            var form = new AnnexaFormly();
            if(form && !form.model){
                form.model = {};
            }
            var fields = tramFormFields('col-sm-12');

            if(fields.subject) {
                form.fields.push(fields.subject);
            }
            if(fields.archiveClassification){
                form.fields.push(fields.archiveClassification);
            }
            if(fields.classification) {
                form.fields.push(fields.classification);
            }
            if(fields.allowUserChangeMaxDays) {
                form.fields.push(fields.allowUserChangeMaxDays);
            }
            if(fields.transactionTypes) {
                form.fields.push(fields.transactionTypes);
            }
            if(fields.tramitationType && !$rootScope.esetMode){
                form.fields.push(fields.tramitationType);
            }

            // MRS-I
            // Para el formulario de Iniciar Nuevo expediente, agregamos perfiles por defecto si tiene.
            if( fields.profiles ) {
                var entityProfiles = $rootScope.LoggedUser.userDefaultEntityProfiles;
                var defaultProfileUserEXP = $linq(entityProfiles).where("x => x.entityType == 'EXP'").select("x => x.profile").toArray();
                form.model.profiles = [];

                if (defaultProfileUserEXP && defaultProfileUserEXP.length > 0) {

                    if (TramFactory.Procedure && TramFactory.Procedure.procedureResponsibleProfiles && TramFactory.Procedure.procedureResponsibleProfiles.length > 0) {

                        var tramResponsible = TramFactory.Procedure.procedureResponsibleProfiles;
                        var tramResponsibleProfile = $linq(tramResponsible).select("x => x.profile").toArray();

                        // si alguno de los responsables es tambien el de por defecto del usuario, hacemos ese por defecto en el campo del Select.
                        for (var i = 0; i < tramResponsibleProfile.length; i++) {
                            if (tramResponsibleProfile[i].id === defaultProfileUserEXP[0].id) {
                                form.model.profiles.push(defaultProfileUserEXP[0].id);
                            }
                        }
                    }
                    else {
                        if (TramFactory.Procedure && TramFactory.Procedure.procedureStartProfiles && TramFactory.Procedure.procedureStartProfiles.length > 0) {

                            var tramStart = TramFactory.Procedure.procedureStartProfiles;
                            var tramStartProfile = $linq(tramStart).select("x => x.profile").toArray();

                            // si alguno de los inicializa es tambien el de por defecto del usuario, hacemos ese por defecto en el campo del Select.
                            for (var cont = 0; cont < tramStartProfile.length; cont++) {
                                if (tramStartProfile[cont].id === defaultProfileUserEXP[0].id) {
                                    form.model.profiles.push(defaultProfileUserEXP[0].id);
                                }
                            }
                        }
                    }
                }
                
                if (form.model.profiles.length == 0 &&
                		fields.profiles.templateOptions &&
                		fields.profiles.templateOptions.options && 
                		fields.profiles.templateOptions.options.length == 1) {
                	//Si solo puedo asignar un perfil se selecciona automáticamente
                	form.model.profiles.push(fields.profiles.templateOptions.options[0].id);
                } else {
                	//Solo se muestra el campo si hay mas de un perfil para serleccionar
                	form.fields.push(fields.profiles);
                }
            }
            // MRS-F

            if(fields.section1) {
                form.fields.push(fields.section1);
            }else if(fields.section2){
                form.fields.push(fields.section2);
            }
            
            if($rootScope.esetMode) {
                if(fields.profilesTransaction) {
                    form.fields.push(fields.profilesTransaction);
                }
                if(fields.responsibleTransaction) {
                    form.fields.push(fields.responsibleTransaction);
                }
            }
            
            form.fields.push(fields.observations);
            
            if(TramFactory.initialTransaction) {
                var cfields = $linq(TramFactory.initialTransaction.customFields).orderBy("x => x.viewOrder").toArray();

                angular.forEach(cfields,function (value, key) {
                   if(value && !value.hiddenField) {
                       form = CustomFieldFactory.addFormlyFieldRow(form, value.customField, value.required, value.customField.id, value.noEditable);
                       form.model['cf_' + value.customField.id] = CustomFieldFactory.calculateValueCustomField(value, form.model, ('cf_' +  value.customField.id));
                   }
                });

            }

            //modificacion el valor de archiveClassification para que no nos solape el form.model
            form.model.archiveClassification={};
            if(fields.allowUserChangeMaxDays) {
                form.model.maxDays = TramFactory.Procedure.maxDays;
            }
            if(TramFactory.Register) {
                if((TramFactory.Procedure.showSubject == 'OPTIONAL' || TramFactory.Procedure.showSubject == 'REQUIRED') && TramFactory.Register.extract) {
                    form.model.subject = TramFactory.Register.extract;
                }

                if(TramFactory.Register.observations) {
                    form.model.observations = TramFactory.Register.observations;
                }
            }
            if(TramFactory.Procedure && TramFactory.Procedure.archiveClassification){
                form.model.archiveClassification.$selected = {id:TramFactory.Procedure.archiveClassification.id, title: TramFactory.Procedure.archiveClassification[Language.getActiveColumn()]};
            }
            if(fields.tramitationType) {
                form.model.tramitationType = TramFactory.Procedure.tramitationType;
            }

            var submitNewTram = function() {
                form.form.$setSubmitted();

                if(form.form.$valid) {
                    var boxesModel = getTramBoxesModel(form.extra);
                    var archiveClassification ;
                    if(form.model.archiveClassification && form.model.archiveClassification.$selected && form.model.archiveClassification.$selected.id){
                        archiveClassification = {id:form.model.archiveClassification.$selected.id};
                    }
                    if(TramFactory.Procedure.guided || $rootScope.esetMode){
                        form.model.tramitationType = TramFactory.Procedure.tramitationType;
                    }
                    
                    if ($rootScope.esetMode && fields.profilesTransaction) {
                    	form.model.profiles = [];
                    	form.model.profiles.push(fields.profilesTransaction.value());
                    }
                    
                    // MRS-I
                    // var newDossier = new dossier(TramFactory.Procedure, form.model.subject, form.model.observations, form.model.classification, archiveClassification, form.model.tramitationType);
                    var newDossier = new dossier(TramFactory.Procedure, form.model.subject, form.model.observations, form.model.classification, archiveClassification, form.model.tramitationType, form.model.profiles);
                    if(fields.profiles){
                        newDossier.profiles = [];
                        if(form.model && form.model.profiles) {
                            _.forEach(form.model.profiles, function (profile) {
                                newDossier.profiles.push({"id": profile});
                            });
                        }
                    }
                    else{
                        newDossier.profiles = [];
                        if(form.model && form.model.profiles) {
                            _.forEach(form.model.profiles, function (profile) {
                                newDossier.profiles.push({"id": profile});
                            });
                        }
                    }
                    if(form.model.section) {
                        if(form.model.section.id) {
                            newDossier.section = {id: form.model.section.id};
                        } else {
                            newDossier.section= {id: form.model.section};
                        }
                    }
                    // MRS-F

                    if(fields.allowUserChangeMaxDays) {
                        newDossier.maxDays = form.model.maxDays;
                    }
                    var cf = CustomFieldFactory.getModelValues(form.model);

                    var custom_fields = $linq(TramFactory.initialTransaction.customFields).where("x => x.customField && x.customField.frontendType == 'CHECKBOX'").toArray();
                    if(custom_fields && custom_fields.length > 0){
                        _.forEach(custom_fields, function(field) {
                            var index_custom_field =  $linq(cf).indexOf("x => x.id == 'cf_" + field.customField.id+"'");
                            var custom_field = undefined;
                            if(index_custom_field == -1){
                                custom_field = {id:"cf_" + field.customField.id, value:[]};
                                cf.push(custom_field);
                            }else{
                                custom_field = cf[index_custom_field];
                                if(custom_field){
                                    custom_field.value = [];
                                }
                            }
                            var custom_field_selected = $linq(cf).where(function(x){
                                if(x.id.startsWith("cf_" + field.customField.id+"_")){return true;}else{return false;}}
                            ).toArray();
                            if(custom_field_selected && custom_field_selected.length > 0){
                                custom_field.value = [];
                                _.forEach(custom_field_selected, function(value){
                                    if(value.value) {
                                        if(field.customField && field.customField.listValues && field.customField.listValues.length > 0) {
                                            var custom_field_value_selected = $linq(field.customField.listValues).where(function(x){
                                                if(value.id.endsWith("_"+x.value)){return true;}else{return false;}}
                                            ).toArray();
                                            if(custom_field_value_selected && custom_field_value_selected.length > 0){
                                                custom_field.value.push(custom_field_value_selected[0].value);
                                            }
                                        }
                                    }
                                });
                            }
                        });
                    }

                    var dossierForm = {};
                    dossierForm.newDossier = newDossier;
                    dossierForm.cf =  cf;
                    dossierForm.receivers =  boxesModel.receivers;
                    dossierForm.registerInputs =  boxesModel.registerInputs;
                    dossierForm.registerOutputs =  boxesModel.registerOutputs;
                    dossierForm.dossiers =  boxesModel.dossiers;
                    dossierForm.documents =  boxesModel.documents;
                    if(form.model.transactionType){
                        dossierForm.transactionType = form.model.transactionType;
                    }
                    if(form.model.responsibleTransaction){
                        dossierForm.responsibleTransactionUser = form.model.responsibleTransaction;
                    }

                    newTram(dossierForm, saveOkState);
                }
            };

            form.addSubmitCallback(submitNewTram);

            return form;
        };

        var tramBoxes = function (scope) {
            var retBoxes = [];
            var receiverBox = getBox('receiver', 'receiver-tram', 'global.literals.receivers', 'global.literals.placeholder_search_receiver', 'global.literals.advanced_search_receivers', true, true, true, true);
            receiverBox.boxDefinition.setPrincipal = function (objectId, index) {
                var actualPrincipal = -1;
                if (receiverBox.boxDefinition && receiverBox.boxDefinition.content && receiverBox.boxDefinition.content.length > index) {
                    angular.forEach(receiverBox.boxDefinition.content, function (val, key) {
                        if (val.principal) {
                            receiverBox.boxDefinition.content[key].principal = false;
                        }
                    });
                    receiverBox.boxDefinition.content[index].principal = true;
                }
            };
            receiverBox.boxDefinition.editFunction = function (objectId, index) {
                openEditReciverModal(index, receiverBox.boxDefinition.content);
            }
            receiverBox.boxDefinition.deleteFunction = function (objectId, index) {
                receiverBox.boxDefinition.content.splice(index, 1);
                if (receiverBox.boxDefinition.content && receiverBox.boxDefinition.content.length > 0) {
                    receiverBox.boxDefinition.content[0].principal = true;
                }
            };
            if (thirdModule && thirdModule.length == 1) {
                retBoxes.push(receiverBox);
            }


            var documentBox = getBox('documentChild', 'document-tram', 'global.literals.documents', 'global.literals.placeholser_search_documents', 'global.literals.advanced_search_documents', true, true, true, true);
            documentBox.boxDefinition.fromTemplate = function () {
                factory.showNewDocumentFromTemplateModal(TramFactory.initialTransaction, new SortedArray(TramFactory.TramTemplates, Language.getActiveColumn()).sort(), new SortedArray(TramFactory.DocumentTypes, Language.getActiveColumn()).sort(), documentBox.boxDefinition);
            };
            documentBox.boxDefinition.actualTransaction = TramFactory.initialTransaction;

            var updateDocumentInList = function (document) {
                if (document && document.id && documentBox && documentBox.boxDefinition && documentBox.boxDefinition.content) {
                    var index = $linq(documentBox.boxDefinition.content).indexOf("x => x.document.id == " + document.id);

                    if (index != -1) {
                        documentBox.boxDefinition.content[index].document.urgent = document.urgent;
                        documentBox.boxDefinition.content[index].document.docStatus = document.docStatus;
                        documentBox.boxDefinition.content[index].document.idPDFDocumentManager = document.idPDFDocumentManager;
                        documentBox.boxDefinition.content[index].document.filePDFTypeDoc = document.filePDFTypeDoc;
                        documentBox.boxDefinition.content[index].document.sizePDFDoc = document.sizePDFDoc;
                    }
                }
            };
            if (scope) {
                scope.$on('SendToSignModalFinished', function (event, args) {
                    if (args.data && args.data.length > 0) {
                        updateDocumentInList(args.data[0]);
                    }
                });
                scope.$on('documentActionButtonExecuted', function(event, args) {
                    if(args.button) {
                        switch (args.button) {
                            case 'returnToDraft':
                            case 'complete':
                                updateDocumentInList(args.data);
                                break;
                            case 'createRegisterEntry':
                                if(args.data){
                                    if(args.data.entryType == 'INPUT' && registerInputBox && registerInputBox.boxDefinition){
                                        registerInputBox.boxDefinition.searchedValue = {id: args.data.id, object: args.data};
                                        registerInputBox.boxDefinition.searchAdd();
                                    }else if(args.data.entryType == 'OUTPUT' && registerOutputBox && registerOutputBox.boxDefinition){
                                        registerOutputBox.boxDefinition.searchedValue = {id: args.data.id, object: args.data};
                                        registerOutputBox.boxDefinition.searchAdd();
                                    }
                                }
                                break;

                        }
                    }
                });
            }
            if (docModule && docModule.length == 1) {
                retBoxes.push(documentBox);
            }

            var registerEntryInputBox = {
                boxTitle: 'global.literals.reg_input',
                permissions: { new: 'new_input_entry_register', view: ['view_input_register','view_all_input_register'] },
                search: { placeholder: 'global.literals.placeholser_search_registerEntry', advanced: true, saveSearch: undefined },
                new: {},
                content:  [],
                config: { thirds: receiverBox.boxDefinition.content, documents: documentBox.boxDefinition.content },
                origin: 'register-entry',
                searchByEntryType: true,
                entryType: 'INPUT'
            };

            retBoxes.push(registerEntryInputBox);

            var registerEntryOutputBox = {
                boxTitle: 'global.literals.reg_output',
                permissions: { new: 'new_output_entry_register', view: 'view_output_register' },
                search: { placeholder: 'global.literals.placeholser_search_registerEntry', advanced: true, saveSearch: undefined },
                new: {},
                content:  [],
                config: { thirds: receiverBox.boxDefinition.content, documents: documentBox.boxDefinition.content },
                origin: 'register-entry',
                searchByEntryType: true,
                entryType: 'OUTPUT'
            };

            retBoxes.push(registerEntryOutputBox);

            var dossierBox = getBox('dossier', 'dossier-tram', 'global.literals.related_dossiers', 'global.literals.placeholder_search_dossiers', 'global.literals.advanced_search_dossiers', false, true, true);
            retBoxes.push(dossierBox);


            if(TramFactory.Register) {
                if (TramFactory.Register.thirds) {
                    angular.forEach(TramFactory.Register.thirds, function (value, key) {
                        // modificat per apop 10/01/2018
                        var dossierThird = new ThirdAddress(value.third, Language.getActiveColumn());

                        receiverBox.boxDefinition.content.push({
                            third: value.third,
                            interested: value.interested,
                            roleInterested: value.roleInterested,
                            representative: value.representative,
                            principal: value.principal,
                            addresses: dossierThird.getAdressesHtml(),
                            representated: value.representated
                        });
                    });
                }

                if (TramFactory.Register.entryType == 'INPUT') {
                    registerEntryInputBox.content.push(TramFactory.Register);
                } else if (TramFactory.Register.entryType == 'OUTPUT') {
                    registerEntryOutputBox.content.push(TramFactory.Register);
                }
                if(TramFactory.Register.documents && TramFactory.Register.documents.length > 0) {
                    angular.forEach(TramFactory.Register.documents, function(value, key){
                    	var relType;
                    	if(value.relationType){
                    		relType = value.relationType;
                    	} else {
                    		relType = "DOCUMENT";
                    	}
                        var doc = {
                    		document:value.document,
                            publicDocument: false,
                            relationType: relType
                        };
                        documentBox.boxDefinition.content.push(doc);
                    });
                }

            }

            return retBoxes;
        };

        var newTram = function(model, okstate, processFunc) {
            var deferrend = $q.defer();
            _.forEach(model.receivers, function(value, key) {
                if(value.representated) {
                    delete value.representated.label;
                }
            });

            // MRS-I
            // TramFactory.newDossier(model.newDossier, model.cf, $rootScope.LoggedUser, model.receivers, model.registerInputs, model.dossiers, model.registerOutputs, model.transactionType, model.documents, model.responsibleTransactionUser).then(function(data) {
            TramFactory.newDossier(model.newDossier, model.cf, $rootScope.LoggedUser, model.receivers, model.registerInputs, model.dossiers, model.registerOutputs, model.transactionType, model.documents, model.responsibleTransactionUser, model.profiles).then(function(data) {
            // MRS-F

                if(okstate) {
                    $state.transitionTo(okstate);
                }
                if(processFunc) {
                    processFunc(data);
                }
            }).catch(function (error) {
                deferrend.reject(ErrorFactory.getErrorMessage('register', 'new', error));
            });
            return deferrend.promise;
        };
        
        var openEditReciverModal = function(index, content) {
        	var receiver = content[index];
            if (receiver.interested) {
        		var receiverThirdType = 'interested';
        	} else if (receiver.representative) {
        		var receiverThirdType = 'representative';	        		
        	}
        	if (receiver.representated) {        		
        		var idReceiverRepresentated = receiver.representated.id;
        		if (!receiver.representated.label) {
                    if(receiver.representated.name) {
                    	receiver.representated.label = receiver.representated.name + ' ' + receiver.representated.surename1 + ((receiver.representated.surename2)? ' ' + receiver.representated.surename2 : '' ) + ' (' + receiver.representated.identificationDocument + ')';
                    }else{
                    	receiver.representated.label = receiver.representated.corporateName + ' (' + receiver.representated.identificationDocument + ')';
                    }
        		}
        	}
            
        	var modalReceiverAdd = globalModals.receiverAdd;
        	modalReceiverAdd.alerts = [];
            modalReceiverAdd.size = 'modal-md';
            modalReceiverAdd.annexaFormly.model = {};
            modalReceiverAdd.annexaFormly.model.modal_body = {
            		idReceiver: receiver.id,
            		thirdType: receiverThirdType, 
            		representated: receiver.representated, 
            		roleInterested: receiver.roleInterested
            };                            
            modalReceiverAdd.annexaFormly.options = {};
            modalReceiverAdd.annexaFormly.options.formState = {
            		readOnly: false, 
            		data: content, 
            		editReceiver: true, 
            		index: index,
            		idReceiver: receiver.id, 
            		idThird: receiver.third.id,
            		idReceiverRepresentated: idReceiverRepresentated
            };
         
            var submitFunction = function() {            	
            	var content = this.annexaFormly.options.formState.data;
            	var index = this.annexaFormly.options.formState.index;
            	var modal_data = this.annexaFormly.model.modal_body;
            	
            	
            	var idReceiver = modal_data.idReceiver;
            	var thirdType = modal_data.thirdType;
            	var representated = modal_data.representated;
            	var roleInterested = modal_data.roleInterested;            	
            	
            	if (idReceiver) {
            		//Guarda en BBDD
            		return $http({
                        url: './api/dossier_third/' + idReceiver,
                        method: 'GET'
                    }).then(function (data) {                                                
                        var dossierThird = JSOG.decode(data.data);
                        
                        if (thirdType == 'interested') {
                        	dossierThird.interested = true;
                        	dossierThird.representated = undefined;
                        	if (roleInterested) {
                            	dossierThird.roleInterested = roleInterested;
                            }
                        } else {
                        	dossierThird.interested = false;
                        }
                        
                        if (thirdType == 'representative') {
                        	dossierThird.representative = true;                        	
                        	if (representated) {
                            	dossierThird.representated = representated;
                            }
                        	dossierThird.roleInterested = undefined;
                        } else {
                        	dossierThird.representative = false;                        	
                        }                                                  
                                                
                        TramFactory.updateDossierThird(idReceiver, dossierThird)
                            .then(function(data) {
                            	var receiver = data;
                            	receiver.addresses = content[index].addresses;                           	
                            	
                            	//Guarda el destinatario en angular
                            	content[index] = receiver;
                            	TramFactory.Dossier.thirds[index] = receiver;
                            	
                            	modalReceiverAdd.close();
                            }).catch(function (error) {
                                console.error(error);
                                modalReceiverAdd.alerts.push({ msg: ErrorFactory.getErrorMessage('registerEntryThird', 'update', error) });
                        });                    	                    	                        
                        
                    }).catch(function (error) {
                        console.error(error);
                        modalReceiverAdd.alerts.push({ msg: ErrorFactory.getErrorMessage('registerEntryThird', 'update', error) });
                    });
            	} else {
            		//Guarda en angular
            		var receiver = content[index];
            		
            		 if (thirdType == 'interested') {
            			 receiver.interested = true;
            			 receiver.representated = undefined;
                     	if (roleInterested) {
                     		receiver.roleInterested = roleInterested;
                         }
                     } else {
                    	 receiver.interested = false;
                     }
                     
                     if (thirdType == 'representative') {
                    	 receiver.representative = true;                        	
                     	if (representated) {
                     		receiver.representated = representated;
                         }
                     	receiver.roleInterested = undefined;
                     } else {
                    	 receiver.representative = false;                        	
                     }
                     
                     modalReceiverAdd.close();
            	}
            };
            
            AnnexaFormlyFactory.showModal("receiverAdd", modalReceiverAdd, submitFunction, false);
        }

        factory.getEditTramBox = function (isUpdate, type, entryType) {
            var box ;
            switch (type) {
                case 'receiver':
                    if(isUpdate) {
                        box = getBox('receiver', 'receiver-tram', 'global.literals.receivers', 'global.literals.placeholder_search_receiver', 'global.literals.advanced_search_receivers',true, true, true, true);
                        box.boxDefinition.saveSearch = function (third) {
                            var thirds = [];
                            if (TramFactory.Dossier.thirds) {
                                thirds = TramFactory.Dossier.thirds;
                            }
                            thirds.push({
                                dossier: TramFactory.Dossier,
                                third: third.third,
                                interested: third.interested,
                                representative: third.representative,
                                principal: third.principal,
                                representated: third.representated,
                                roleInterested: third.roleInterested
                            });
                            TramFactory.Dossier.thirds = thirds;
                            
                            if (thirds.length > 0) {
                            	//Elimina del box de destinatarios (terceros) las direcciones que se muestran y las genera nuevamente 
                            	//teniendo en cuenta los concentimientos
                            	var self = this;                            	
                            	this.content = [];                            	
                                angular.forEach(thirds, function (value, key) {
                                    var dossierThird = new ThirdAddress(value.third, Language.getActiveColumn(), TramFactory.Dossier);
                                    var newContent = {
                                        id: value.id,
                                        third: value.third,
                                        interested: value.interested,
                                        representative: value.representative,
                                        principal: value.principal,
                                        addresses: dossierThird.getAdressesHtml(),
                                        representated: value.representated,
                                        roleInterested: value.roleInterested
                                    };  
                                    self.content.push(newContent);
                                });
                            }                            
                            
                            TramFactory.updateDossier(TramFactory.Dossier).then(function(data) {                            	
                            	var dossierThirds = JSOG.decode(data).thirds;
                                                            
                            	//Guarda el id de la relacion del tercero con el expediente
                                $linq(box.boxDefinition.content).foreach(function (dossierThird) {
                                	if (!dossierThird.id) {
                                		dossierThird.id = $linq(dossierThirds).where("x => x.third.id == " + dossierThird.third.id).select("x => x.id").toArray()[0];
                                	}
                                });                                
                                $linq(TramFactory.Dossier.thirds).foreach(function (dossierThird) {
                                	if (!dossierThird.id) {
                                		dossierThird.id = $linq(dossierThirds).where("x => x.third.id == " + dossierThird.third.id).select("x => x.id").toArray()[0];
                                	}
                                });
                                
                            }).catch(function (error) {
                            });
                        };
                        box.boxDefinition.setPrincipal = function(objectId, index) {
                            var actualPrincipal = -1;
                            angular.forEach(TramFactory.Dossier.thirds, function(val, key) {
                                if(val.principal) {
                                    TramFactory.Dossier.thirds[key].principal = false;
                                    actualPrincipal = key;
                                }
                            });
                            TramFactory.Dossier.thirds[index].principal = true;
                            TramFactory.updateDossier(TramFactory.Dossier)
                                .then(function(data){
                                    box.boxDefinition.content[index].principal = true;
                                    if(actualPrincipal != -1) {
                                        box.boxDefinition.content[actualPrincipal].principal = false;
                                    }
                                }).catch(function (error) {
                                    TramFactory.Dossier.thirds[index].principal = false;
                                    if(actualPrincipal != -1) {
                                        TramFactory.Dossier.thirds[actualPrincipal].principal = true;
                                    }
                            });
                        };
                        box.boxDefinition.editFunction = function (objectId, index) {
                            openEditReciverModal(index, box.boxDefinition.content);
                        }
                        box.boxDefinition.deleteFunction = function (objectId, index) {
                            var third = TramFactory.Dossier.thirds[index];
                            var inNotification = false;
                            _.forEach(TramFactory.Dossier.notifications, function(notif){
                                if(notif.thirds) {
                                    _.forEach(notif.thirds, function (th) {
                                        if (th.third && third && third.third && th.third.id == third.third.id) {
                                            inNotification = true;
                                        }
                                    });
                                }
                            });
                            if(inNotification){
                                dialogs.error(
                                    $filter('translate')('DIALOGS_ERROR_MSG'),
                                    $filter('translate')('global.errors.thirdInNotification'),
                                    {
                                        animation: true,
                                        backdrop: 'static',
                                        keyboard: true,
                                        size: ''
                                    }
                                );
                            }else {
                                var haveRepresentants = false;
                                _.forEach(TramFactory.Dossier.thirds, function(th){
                                    if(th.representated && third && third.third && th.representated.id == third.third.id){
                                        haveRepresentants = true;
                                    }
                                });
                                if (haveRepresentants) {
                                    dialogs.error(
                                        $filter('translate')('DIALOGS_ERROR_MSG'),
                                        $filter('translate')('global.errors.thirdHaveRepresentated'),
                                        {
                                            animation: true,
                                            backdrop: 'static',
                                            keyboard: true,
                                            size: ''
                                        }
                                    );
                                } else {
                                    TramFactory.Dossier.thirds[index].removedDate = new Date();
                                    TramFactory.Dossier.thirds[index].deleted = true;
                                    TramFactory.Dossier.thirds[index].principal = false;
                                    TramFactory.updateDossier(TramFactory.Dossier).then(function (data) {
                                        box.boxDefinition.content.splice(index, 1);
                                        TramFactory.Dossier.thirds.splice(index, 1);
                                        var decodedData = JSOG.decode(data);

                                        var principal = $linq(decodedData)
                                            .join(decodedData.thirds, "x => x.id", "x => x.idDossier", "(outer, inner) => inner")
                                            .singleOrDefault(undefined, "x => x.principal == true");

                                        if (principal) {
                                            angular.forEach(TramFactory.Dossier.thirds, function (val, key) {
                                                if (val.id == principal.id && !val.principal) {
                                                    box.boxDefinition.content[key].principal = true;
                                                    TramFactory.Dossier.thirds[key].principal = true;
                                                }
                                            });
                                        }
                                    }).catch(function (error) {
                                        TramFactory.Dossier.thirds[index].removedDate = undefined;
                                        TramFactory.Dossier.thirds[index].deleted = false;
                                    });
                                }
                            }
                        };
                    }else{
                        box = getBox('receiver', 'receiver-tram', 'global.literals.receivers', 'global.literals.placeholder_search_receiver', 'global.literals.advanced_search_receivers',false, false, false, false);
                        box.boxDefinition.saveSearch = undefined;
                        box.boxDefinition.deleteFunction = undefined;
                        box.boxDefinition.setPrincipal = undefined;
                    }
                    break;
                case 'dossier':
                    if(isUpdate) {
                    	var additionalDossierFilter = {entityId:TramFactory.Dossier.id};
                        box = getBox('dossier', 'dossier-tram', 'global.literals.related_dossiers','global.literals.placeholder_search_dossiers', 'global.literals.advanced_search_dossiers',false,true,true,undefined,undefined,undefined,undefined,additionalDossierFilter);
                        box.boxDefinition.saveSearch = function (document) {
                            TramFactory.updateDossier(TramFactory.Dossier).catch(function (error) {
                                box.boxDefinition.content.pop();
                            });
                        };
                        box.boxDefinition.deleteFunction = function (objectId, index) {
                            var deletedElement = box.boxDefinition.content[index];
                            box.boxDefinition.content.splice(index, 1);
                            TramFactory.updateDossier(TramFactory.Dossier).catch(function (error) {
                                box.boxDefinition.content.splice(index, 1, deletedElement);
                            });
                        };
                    }else{
                        box = getBox('dossier', 'dossier-tram', 'global.literals.related_dossiers','global.literals.placeholder_search_dossiers', 'global.literals.advanced_search_dossiers',false,false,false);
                        box.boxDefinition.saveSearch = undefined;
                        box.boxDefinition.deleteFunction = undefined;
                    }
                    break;
                case 'registerEntry':
                    if(isUpdate) {
                        if(entryType == 'INPUT') {
                            box = getBox('registerEntry', 'registerEntry-tram-input', 'global.literals.reg_input', 'global.literals.placeholser_search_documents', 'global.literals.advanced_search_input', true, true, true, true, entryType);
                        }else{
                            box = getBox('registerEntry', 'registerEntry-tram-output', 'global.literals.reg_output', 'global.literals.placeholser_search_documents', 'global.literals.advanced_search_output', true, true, true, true, entryType);
                        }
                        box.boxDefinition.saveSearch = function (document) {
                            TramFactory.updateDossier(TramFactory.Dossier).catch(function (error) {
                                box.boxDefinition.content.pop();
                            });
                        };
                        box.boxDefinition.deleteFunction = function (objectId, index) {
                            var deletedElement = box.boxDefinition.content[index];
                            box.boxDefinition.content.splice(index, 1);
                            TramFactory.deleteRegister(TramFactory.Dossier.id, objectId, entryType).catch(function (error) {
                                box.boxDefinition.content.splice(index, 1, deletedElement);
                            });
                        };
                    }else{
                        if(entryType == 'INPUT') {
                            box = getBox('registerEntry', 'registerEntry-tram-input', 'global.literals.reg_input', 'global.literals.placeholser_search_documents', 'global.literals.advanced_search_input', false, false, false, false, entryType);
                        }else{
                            box = getBox('registerEntry', 'registerEntry-tram-output', 'global.literals.reg_output', 'global.literals.placeholser_search_documents', 'global.literals.advanced_search_output', false, false, false, false, entryType);
                        }
                        box.boxDefinition.saveSearch = undefined;
                        box.boxDefinition.deleteFunction = undefined;
                    }
                    box.boxDefinition.typeAheadSelect = function(item){
                        if(item && item.value.indexOf('</i>') > 0){
                            item.value = item.value.substring(item.value.indexOf('</i>')+4, item.value.length);
                        }
                    };
                    break;
            }

            return box;
        };

        factory.getNewTramForm = function (scope) {
            var entity = new AnnexaEntity('new-dossier', 'form', tramNewForm('annexa.tram.pending'), tramBoxes(scope)).setTitle('global.literals.basicInfo').setCancelState('annexa.tram');
            return entity;
        };
        //endregion

        //region Task
        var getTaskBoxesModel = function (formExtra) {
            var indexOfComments = $linq(formExtra).indexOf("x => x.boxDefinition.title == 'global.literals.comments'");
            var indexOfDossierTransactions = $linq(formExtra).indexOf("x => x.boxDefinition.title == 'global.literals.related_dossiers'");
            var indexOfRegisterInputs = $linq(formExtra).indexOf("x => x.boxDefinition.title == 'global.literals.reg_input'");
            var indexOfRegisterOutputs = $linq(formExtra).indexOf("x => x.boxDefinition.title == 'global.literals.reg_output'");
            var indexOfDocuments = $linq(formExtra).indexOf("x => x.boxDefinition.title == 'global.literals.documents'");
            var indexOfRelatedTasks = $linq(formExtra).indexOf("x => x.boxDefinition.title == 'global.literals.tasks'");

            return {
                comments: indexOfComments != -1 ? formExtra[indexOfComments].boxDefinition.content : [],
                dossierTransactions: indexOfDossierTransactions != -1 ? formExtra[indexOfDossierTransactions].boxDefinition.content : [],
                registerInputs: indexOfRegisterInputs != -1 ? formExtra[indexOfRegisterInputs].boxDefinition.content : [],
                registerOutputs: indexOfRegisterOutputs != -1 ? formExtra[indexOfRegisterOutputs].boxDefinition.content : [],
                documents: indexOfDocuments != -1 ? formExtra[indexOfDocuments].boxDefinition.content : [],
                relatedTasks: indexOfRelatedTasks != -1 ? formExtra[indexOfRelatedTasks].boxDefinition.content : []
            };
        };

        var taskFromFields = function(colClass) {
            var form = new AnnexaFormly();

            var data = {
                row: true,
                colClass: 'col-sm-12',
                labelClass: 'label-strong small m-b-0 ',
                extralabel: true
            };

            var fields = {};
            var descriptionLanguageColumn = "descriptionLanguage" + Language.getActiveColumn().substr(Language.getActiveColumn().length - 1);
            var field1 = form.createField('template','annexaSelectRow','col-sm-12',new AnnexaFormlyFieldSelectTemplateOptions('global.literals.template','id',descriptionLanguageColumn,TaskFactory.templateTypes,false),data);
            fields.template = field1;

            var field2 = form.createField('user','annexaLoadUserRow','col-sm-12',new AnnexaFormlyLoadUserFieldTemplateOptions('text','global.literals.assign_to',true,false,[], CommonService.loadUsers),data);
            fields.user = field2;


            var field3 ;

            if($rootScope.esetMode) {
                field3 = form.createField(
                    'description',
                    'annexaInputRow',
                    'col-sm-12',
                    new AnnexaFormlyFieldTemplateOptions(
                        'text',
                        'global.literals.title',
                        true,
                        undefined,
                        undefined,
                        undefined,
                        true
                    ),
                    data
                );
            } else {
                field3 = form.createField('description', 'annexaTextAreaRow', 'col-sm-12', new AnnexaFormlyFieldTextAreaTemplateOptions('global.literals.description', 3, true, undefined, undefined, true), data);
            }

            fields.description = field3;

            var field4 ;

            if($rootScope.esetMode) {
                field4 = form.createField(
                    'taskEndDate',
                    'annexaDatepickerRow',
                    'col-sm-12',
                    new AnnexaFormlyFieldDatepickerTemplateOptions(
                        'text',
                        'global.literals.days',
                        true,
                        {
                            format: 'dd/MM/yyyy',
                            initDate: new Date(),
                            showWeeks: false,
                            startingDay: 1,
                            minDate: new Date()
                        }
                    ),
                    data
                );
            } else {
                field4 = form.createField('days', 'annexaInputRow', 'col-sm-12', new AnnexaFormlyFieldTemplateOptions('number', 'global.literals.days', true, true), data);
            }

            fields.days = field4;

            return fields;
        };

        factory.newTaskForm = function(saveOkState) {
            var form = new AnnexaFormly();

            form.model = {days:30, description:'', user:''};

            var fields = taskFromFields('col-sm-12');
            form.fields.push(fields.template);
            fields.template.templateOptions.onSelected = function(template){
                form.model.days = template.days;
                form.model.description = template[fields.template.templateOptions.labelProp];
            };
            form.fields.push(fields.user);
            form.fields.push(fields.description);
            form.fields.push(fields.days);

            var submitNewTask = function() {
                form.form.$setSubmitted();

                if(form.form.$valid) {
                    var boxesModel = getTaskBoxesModel(form.extra);
                    var task = {};
                    if($rootScope.esetMode) {
                        if($rootScope.LoggedUser && $rootScope.LoggedUser.entity && $rootScope.LoggedUser.entity.holidays) {
                            task.days = HelperService.getWorkingDays(new Date(), form.model.taskEndDate, $rootScope.LoggedUser.entity.holidays);
                        }else{
                            task.days =  HelperService.getWorkingDays(new Date(), form.model.taskEndDate, undefined);
                        }
                    } else {
                        task.days = form.model.days;
                    }
                    task.description = form.model.description;
                    task.user = {id:form.model.user.id};
                    task.finishOk = false;
                    task.endedTask = false;
                    task.createdUser = {id:$rootScope.LoggedUser.id};
                    task.taskComments = boxesModel.comments;
                    task.documents = boxesModel.documents;
                    task.registerEntryInputs = boxesModel.registerInputs;
                    task.registerEntryOutputs = boxesModel.registerOutputs;
                    task.dossierTransactions = boxesModel.dossierTransactions;
                    task.relatedTasks = getRelatedTasks(boxesModel.relatedTasks);
                    newTask(task, saveOkState);
                }
            };

            form.addSubmitCallback(submitNewTask);

            form.options = { watchAllExpressions: true };

            return form;
        };

        factory.getTaskBoxNewForm = function(modal, processFunc) {
            return taskBoxNewForm({}, modal, processFunc);
        };

        var taskBoxNewForm = function(boxDefinition, modal, processFunc) {
            var form = new AnnexaFormly();

            form.model = {days:30, description:'', user:''};

            var fields = taskFromFields('col-sm-6');
            form.fields.push(fields.template);
            fields.template.templateOptions.onSelected = function(template){
                form.model.days = template.days;
                form.model.description = template[fields.template.templateOptions.labelProp];
            };
            form.fields.push(fields.user);
            form.fields.push(fields.description);
            form.fields.push(fields.days);

            var submitNewTask = function() {
                form.form.$setSubmitted();

                if(form.form.$valid) {
                    var boxesModel = getTaskBoxesModel(form.extra);
                    var task = {};
                    if($rootScope.esetMode) {
                        if($rootScope.LoggedUser && $rootScope.LoggedUser.entity && $rootScope.LoggedUser.entity.holidays) {
                            task.days = HelperService.getWorkingDays(new Date(), form.model.taskEndDate, $rootScope.LoggedUser.entity.holidays);
                        }else{
                            task.days = HelperService.getWorkingDays(new Date(), form.model.taskEndDate, undefined);
                        }
                    } else {
                        task.days = form.model.days;
                    }
                    task.description = form.model.description;
                    task.user = {id:form.model.user.id};
                    task.finishOk = false;
                    task.endedTask = false;
                    task.createdUser = {id:$rootScope.LoggedUser.id};
                    task.taskComments = boxesModel.comments;
                    task.documents = boxesModel.documents;
                    task.registerEntryInputs = boxesModel.registerInputs;
                    task.registerEntryOutputs = boxesModel.registerOutputs;
                    task.dossierTransactions = boxesModel.dossierTransactions;
                    task.relatedTasks = getRelatedTasks(boxesModel.relatedTasks);
                    if(boxDefinition.isTramBox && boxDefinition.isTramBox.id && task.documents && task.documents.length > 0) {
                        var modalSelectProfile = angular.copy(globalModals.newTaskAddProfileToDocument);
                        var userProfiles = [];
                        if (form && form.model && form.model.user && form.model.user.user &&form.model.user.user.userProfiles) {
                            _.forEach(form.model.user.user.userProfiles, function(userProfile){
                                if(userProfile.profile){
                                    userProfiles.push({id:userProfile.profile.id, language1: userProfile.profile.language1, language2: userProfile.profile.language2, language3: userProfile.profile.language3});
                                }
                            });
                        }
                        modalSelectProfile.annexaFormly.model = {};
                        modalSelectProfile.annexaFormly.model.modal_body = {userProfiles:userProfiles};
                        modalSelectProfile.annexaFormly.options = {};
                        modalSelectProfile.annexaFormly.options.formState = {readOnly: false};
                        //modalSelectProfile.annexaFormly.fields[0].fieldGroup[0].templateOptions.placeholder = $filter('translate')('global.literals.commentReject');
                        //modalSelectProfile.annexaFormly.fields[0].fieldGroup[0].templateOptions.required = true;
                        var createNewTaskComplete = function(){
                            if(modalSelectProfile.annexaFormly.model.userModifyDocument == 1){
                                newTask(task, undefined, processNewTask, modalSelectProfile.annexaFormly.model.profile.id).then(function (data) {
                                    modalSelectProfile.close();
                                }).catch(function (error) {
                                    modalSelectProfile.alerts.push({msg: error});
                                });
                            }else{
                                newTask(task, undefined, processNewTask).then(function (data) {
                                    modalSelectProfile.close();
                                }).catch(function (error) {
                                    modalSelectProfile.alerts.push({msg: error});
                                });
                            }

                        };
                        AnnexaFormlyFactory.showModal("modalUpdateComentTask", modalSelectProfile, createNewTaskComplete, false);
                    }else{
                        newTask(task, undefined, processFunc ? processFunc : processNewTask, undefined, form ).then(function (data) {
                        }).catch(function (error) {
                            modal.alerts.push({msg: error});
                        });
                    }
                }
            };

            var processNewTask = function(task) {
                boxDefinition.searchedValue = { id: task.id, object: task };
                boxDefinition.searchAdd();
                form.close();
            };

            form.addSubmitCallback(submitNewTask);

            form.options = { watchAllExpressions: true };

            return form;
        };

        var commentTaskBox = function(permissions, enabled) {
            var initialState = '';
            var commentTaskBox = angular.copy(TaskFactory.CommentBox).initialize(AnnexaPermissionsFactory.haveOnePermission(permissions), enabled);
            commentTaskBox.boxDefinition.content = [];
            commentTaskBox.boxDefinition.saveSearch = undefined;
            commentTaskBox.boxDefinition.deleteFunction = function(objectId, index){
                commentTaskBox.boxDefinition.content.splice(index, 1);
            };
            commentTaskBox.boxDefinition.renderType = 'comment-task';
            commentTaskBox.boxDefinition.languageColumn = Language.getActiveColumn();
            return commentTaskBox;
        };

        var attachmentTaskBox = function(permissions, enabled) {
            var initialState = '';
            var attachmentTaskBox = angular.copy(TaskFactory.AttachmentBox).initialize(AnnexaPermissionsFactory.haveOnePermission(permissions), enabled);
            attachmentTaskBox.boxDefinition.content = [];
            attachmentTaskBox.boxDefinition.saveSearch = undefined;
            attachmentTaskBox.boxDefinition.deleteFunction = function(objectId, index){
                attachmentTaskBox.boxDefinition.content.splice(index, 1);
            };
            attachmentTaskBox.boxDefinition.renderType = 'attachment-task';
            attachmentTaskBox.boxDefinition.languageColumn = Language.getActiveColumn();
            return attachmentTaskBox;
        };

        var dossierTransactionTaskBox = function(enabled) {
            var initialState = '';
            var dossierTransactionTaskBox = angular.copy(BoxFactory.DossierTransactionBox).initialize('global.literals.related_transactions','global.literals.placeholder_search_dossiers', 'global.literals.advanced_search_dossiers',false,enabled,enabled, Language.getActiveColumn());
            dossierTransactionTaskBox.boxDefinition.content = [];
            dossierTransactionTaskBox.boxDefinition.saveSearch = undefined;
            dossierTransactionTaskBox.boxDefinition.deleteFunction = function(objectId, index){
                dossierTransactionTaskBox.boxDefinition.content.splice(index, 1);
            };
            dossierTransactionTaskBox.boxDefinition.renderType = 'dossierTransaction-task';
            dossierTransactionTaskBox.boxDefinition.languageColumn = Language.getActiveColumn();
            return dossierTransactionTaskBox;
        };

        factory.taskBoxes = function () {
            var retBoxes = [];

            retBoxes.push(commentTaskBox(['create_task'], true));

            var dossierTransactionBox = dossierTransactionTaskBox(true);
            dossierTransactionBox.boxDefinition.typeAheadSelect = function(item){
                if(item && item.value.indexOf('</i>') > 0){
                    item.value = item.value.substring(item.value.indexOf('</i>')+4, item.value.length);
                }
            };
            if (dossierModule.length == 1) {
                retBoxes.push(dossierTransactionBox);
            }

            var registerInputBox = getBox('registerEntry', 'registerEntry-task-input', 'global.literals.reg_input','global.literals.placeholser_search_documents', 'global.literals.advanced_search_input', true, true, true, true, 'INPUT', undefined, undefined, undefined, true);
                
            registerInputBox.boxDefinition.typeAheadSelect = function(item){
                if(item && item.value.indexOf('</i>') > 0){
                    item.value = item.value.substring(item.value.indexOf('</i>')+4, item.value.length);
                }
            };

            if(regInputModule.length == 1) {
                retBoxes.push(registerInputBox);
            }

            var registerOutputBox = getBox('registerEntry', 'registerEntry-task-output', 'global.literals.reg_output', 'global.literals.placeholser_search_documents', 'global.literals.advanced_search_output', true, true, true, true, 'OUTPUT', undefined, undefined, undefined, true);
            registerOutputBox.boxDefinition.typeAheadSelect = function(item){
                if(item && item.value.indexOf('</i>') > 0){
                    item.value = item.value.substring(item.value.indexOf('</i>')+4, item.value.length);
                }
            };
            if(regOutputModule.length == 1) {
                retBoxes.push(registerOutputBox);
            }


            var documentBox = getBox('document', 'document-task', 'global.literals.documents','global.literals.placeholser_search_documents', 'global.literals.advanced_search_documents',true,true,true, true);
            documentBox.boxDefinition.fromTemplate = function () {
                factory.showNewDocumentFromTemplateModal(TramFactory.initialTransaction, new SortedArray(TramFactory.TramTemplates, Language.getActiveColumn()).sort(), new SortedArray(TramFactory.DocumentTypes, Language.getActiveColumn()).sort(), documentBox.boxDefinition);
            };
            documentBox.boxDefinition.actualTransaction = TramFactory.initialTransaction;
            if (docModule.length == 1) {
                retBoxes.push(documentBox);
            }

            var taskBox = getBox('task','task-task','global.literals.tasks', '', '', true,true,true, true, undefined,undefined,undefined,{assignedRegisterEntry:true});
            taskBox.boxDefinition.typeAheadSelect = function(item){
                if(item && item.value.indexOf('</i>') > 0){
                    item.value = item.value.substring(item.value.indexOf('</i>')+4, item.value.length);
                }
            };
            taskBox.boxDefinition.searchTasks = function (value) {
                if(!value || !value.val) {
                    return [];
                }else if(value.val != '*' && value.val.length < 3){
                    return [];
                }else {
                    return TaskFactory.searchTasks(value);
                }
            };
            if(tasksModule.length == 1) {
                retBoxes.push(taskBox);
            }


            return retBoxes;
        };

        factory.getEditTaskBox = function (type, enabled, entryType) {
            var box ;
            switch (type) {
                case 'dossierTransaction':
                    box = dossierTransactionTaskBox(enabled);
                    box.boxDefinition.saveSearch = function (document) {
                        TaskFactory.updateTask(TaskFactory.task).catch(function (error) {
                            box.boxDefinition.content.pop();
                        });
                    };
                    box.boxDefinition.deleteFunction = function (objectId, index) {
                        var deletedElement = box.boxDefinition.content[index];
                        box.boxDefinition.content.splice(index, 1);
                        TaskFactory.updateTask(TaskFactory.task).catch(function (error) {
                            box.boxDefinition.content.splice(index, 1, deletedElement);
                        });
                    };
                    break;
                case 'registerEntry':
                    if(entryType == 'INPUT') {
                        box = getBox('registerEntry', 'registerEntry-task-input', 'global.literals.reg_input', 'global.literals.placeholser_search_documents', 'global.literals.advanced_search_input', enabled, enabled, enabled, enabled, entryType, undefined, undefined, undefined, true);
                    }else{
                        box = getBox('registerEntry', 'registerEntry-task-output', 'global.literals.reg_output', 'global.literals.placeholser_search_documents', 'global.literals.advanced_search_output', enabled, enabled, enabled, enabled, entryType, undefined, undefined, undefined, true);
                    }
                    box.boxDefinition.saveSearch = function (document) {
                        TaskFactory.updateTask(TaskFactory.task).catch(function (error) {
                            box.boxDefinition.content.pop();
                        });
                    };
                    box.boxDefinition.deleteFunction = function (objectId, index) {
                        var deletedElement = box.boxDefinition.content[index];
                        box.boxDefinition.content.splice(index, 1);
                        TaskFactory.updateTask(TaskFactory.task).catch(function (error) {
                            box.boxDefinition.content.splice(index, 1, deletedElement);
                        });
                    };
                    box.boxDefinition.typeAheadSelect = function(item){
                        if(item && item.value.indexOf('</i>') > 0){
                            item.value = item.value.substring(item.value.indexOf('</i>')+4, item.value.length);
                        }
                    };
                    break;
                case 'document':
                    box = getBox('document', 'document-task', 'global.literals.documents', 'global.literals.placeholser_search_documents', 'global.literals.advanced_search_documents', enabled, enabled, enabled, enabled);
                    box.boxDefinition.saveSearch = function (document) {
                        TaskFactory.updateTask(TaskFactory.task).catch(function (error) {
                            box.boxDefinition.content.pop();
                        });
                    };
                    box.boxDefinition.deleteFunction = function(objectId, index) {
                        var deletedElement = box.boxDefinition.content[index];
                        box.boxDefinition.content.splice(index, 1);
                        TaskFactory.updateTask(TaskFactory.task).catch(function (error) {
                            box.boxDefinition.content.splice(index, 1, deletedElement);
                        });
                    };
                    break;
                case 'comment':
                    box = commentTaskBox(['create_task'], enabled);
                    box.boxDefinition.saveSearch = function (comment) {
                        TaskFactory.updateTask(TaskFactory.task).catch(function (error) {
                            box.boxDefinition.content.pop();
                        });
                    };
                    box.boxDefinition.deleteFunction = function (objectId, index) {
                        var deletedElement = box.boxDefinition.content[index];
                        box.boxDefinition.content.splice(index, 1);
                        TaskFactory.updateTask(TaskFactory.task).catch(function (error) {
                            box.boxDefinition.content.splice(index, 1, deletedElement);
                        });
                    };
                    break;

                case 'attachment':
                    box = attachmentTaskBox(['create_task'], enabled);
                    box.boxDefinition.saveSearch = function (comment) {
                        TaskFactory.updateTask(TaskFactory.task).catch(function (error) {
                            box.boxDefinition.content.pop();
                        });
                    };
                    break;
                case 'task':
                	var additionalTaskFilter = {assignedRegisterEntry:true, entityId:TaskFactory.task.id};
                    box = getBox('task','task-task','global.literals.tasks', '', '', enabled, enabled, enabled, enabled, undefined, undefined, undefined, additionalTaskFilter);
                    box.boxDefinition.templateTasks = TaskFactory.templateTypes;
                    box.boxDefinition.saveSearch = function(task){
                        var tasks = [];
                        if(TaskFactory.task && TaskFactory.task.relatedTasks) {
                            tasks = TaskFactory.task.relatedTasks;
                        }

                        tasks.push({
                            task: TaskFactory.task,
                            relatedTask: task
                        });
                        TaskFactory.task.relatedTasks = tasks;
                        TaskFactory.updateTask(TaskFactory.task).then(function (data) {
                            TaskFactory.task.relatedTasks = data.relatedTasks;
                        }).catch(function (error) {
                            box.boxDefinition.content.pop();
                        });
                    };
                    box.boxDefinition.searchTasks = function (value) {
                        if(!value || !value.val) {
                            return [];
                        }else if(value.val != '*' && value.val.length < 3){
                            return [];
                        }else {
                            return TaskFactory.searchTasks(value);
                        }
                    };
                    box.boxDefinition.typeAheadSelect = function(item){
                        if(item && item.value.indexOf('</i>') > 0){
                            item.value = item.value.substring(item.value.indexOf('</i>')+4, item.value.length);
                        }
                    };
                    break;
            }

            return box;
        };

        factory.showNewTaskModal = function (boxDefinition) {
            var date = new Date();
            var modal = angular.copy(globalModals.receiverNew);
            modal.title = 'global.literals.new_task_title';
            modal.size = 'modal-lg';

            var entity = new AnnexaEntity(date.getTime() + '', 'modal', taskBoxNewForm(boxDefinition, modal), factory.taskBoxes());
            if(boxDefinition.entryType && boxDefinition.isEdit){
                if(boxDefinition.entryType == "INPUT" ){
                    entity.boxes[2].boxDefinition.content.push(RegFactory.regEntry);
                }else if(boxDefinition.entryType == "OUTPUT"){
                    entity.boxes[3].boxDefinition.content.push(RegFactory.regEntry);
                }
            }
            if(boxDefinition.isTramBox){
                boxDefinition.isTramBox.dossier = {id: TramFactory.Dossier.id, dossierNumber: TramFactory.Dossier.dossierNumber, endDate: TramFactory.Dossier.endDate, closed: TramFactory.Dossier.closed, subject: TramFactory.Dossier.subject, procedure:{id: ((TramFactory.Dossier.procedure)? TramFactory.Dossier.id : ""), procedureViewProfiles: ((TramFactory.Dossier.procedureViewProfiles)? TramFactory.Dossier.procedureViewProfiles : [])}};
                entity.boxes[1].boxDefinition.content.push(boxDefinition.isTramBox);
            }
            showModal(modal, entity);
        };

        var newTask = function(model, okstate, processFunc, profile, form) {
            var deferrend = $q.defer();

            TaskFactory.addTask(model, profile).then(function(data) {
                if(okstate) {
                    $state.transitionTo(okstate);
                }
                if(processFunc) {
                    processFunc(data, form);
                }
                deferrend.resolve(data);
            }).catch(function (error) {
                deferrend.reject(ErrorFactory.getErrorMessage('task', 'new', error));
            });
            return deferrend.promise;
        };

        var getRelatedTasks = function(relatedTasks){
            var relatedTasksAux = [];
            if(relatedTasks) {
                _.forEach(relatedTasks, function (item) {
                    relatedTasksAux.push({relatedTask: {id: item.id}});
                });
            }
            return relatedTasksAux;
        };
        //endregion

        return factory;
    }]);