/**
 * Created by dpardell on 04/11/2016.
 */
var RemaingDaysGlobalColumn = function(filter, title, helper, holidays) {
    this.filter = filter;
    this.title = title;
    this.helper = helper;
    this.holidays = holidays;

    this.getTitle = function() {
        return filter('translate')(title);
    }

    this.getRender = function(data, type, full, meta) {
        //TODO: Falta el tema del justificant del retard
        var content = '';
        var taskEnd = false;
        var workinDays = 0;
        if(full.endDate){
            taskEnd = true;
        }
        if(!taskEnd) {
            content += '<span class="label ' + helper.getWorkingDaysClass(data) + '">';
            if (data > 0) {
                content += '+';
            }
            content += data + "</span>";
        }else{
            if(full.finishOk){
                content += '<i class="fa fa-check text-success"></i>';
            }else if(full.finishOk == false) {
                content += '<i class="fa fa-times-circle fa-lg text-danger"></i>';
            }
        }
        return content;
    }
}