angular
    .module('annexaApp')
    .factory('WebSocketsFactory',['$rootScope', '$stomp', '$location', 'RestService', function ($rootScope, $stomp, $location, RestService) {
        var factory = {};

        factory.updateThirdsSubscription = undefined;

        factory.isIE = function() {
            var ua = window.navigator.userAgent;

            var msie = ua.indexOf('MSIE ');
            if (msie > 0) {
                // IE 10 or older => return version number
                if(parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10) < 12){
                    return true;
                }else {
                    return false;
                }
            }

            var trident = ua.indexOf('Trident/');
            if (trident > 0) {
                // IE 11 => return version number
                var rv = ua.indexOf('rv:');
                if(parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10) < 12){
                    return true;
                }else{
                    return false;
                }
            }

            // other browser
            return false;
        };

        factory.updateThirdsFunction = function (payload, headers, res) {
            if(payload && payload.id) {
                RestService.findOne('Third', payload.id)
                    .then(function (data) {
                        $rootScope.$broadcast('AnnexaServerEventUpdateThird', {third: JSOG.decode(data.data)});
                    }).catch(function (error) {
                        //Empty;
                });
            }
        };

        factory.initialize = function() {
            /*if(!factory.isIE()) {
                var baseUrl = $location.$$protocol + '://' + $location.$$host + ($location.$$port != 80 ? ':' + $location.$$port : '');

                $stomp.connect(baseUrl + '/annexa_server_events', {})
                    .then(function (frame) {
                        factory.updateThirdsSubscription = $stomp.subscribe('/event/update_third', factory.updateThirdsFunction);
                    });
            }*/
        }

        return factory;
    }])