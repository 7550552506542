/**
 * Created by osirvent on 07/03/2016.
 */
angular
    .module('annexaApp')
    .service('LoginService',['$rootScope', '$http', '$q', '$localStorage', '$state', '$injector', 'HeaderService', 'Language', '$translate', 'tmhDynamicLocale', 'PermPermissionStore', 'AnnexaPermissionsFactory', 'RestService', 'AnnexaPendingCounterFactory', 'GlobalDataFactory', function($rootScope, $http, $q, $localStorage, $state, $injector, HeaderService, Language, $translate, tmhDynamicLocale, PermPermissionStore, AnnexaPermissionsFactory, RestService, AnnexaPendingCounterFactory, GlobalDataFactory) {
         this.PostLoginActions = function(user, user_roles, user_token, $scope, user_tenant, all_modules, managed_organs, addAutomaticActionPermissions, annexaVersion, activeMultipleRegisterOffice, registerEntryOffices) {
            
            //region DADES USUARI
            
            var loggedUser = user;
            loggedUser.isAuthenticated = true;
            loggedUser.roles = user_roles;
            loggedUser.token = user_token;
            loggedUser.tenant = user_tenant;
            loggedUser.apiUrl = $rootScope.app.baseUrl + 'api/users/' + loggedUser.id;
            loggedUser.managedOrgans = managed_organs;

             //Carregar actions permissions de l'usuari
             AnnexaPermissionsFactory.enableSecurity(loggedUser, addAutomaticActionPermissions, activeMultipleRegisterOffice, registerEntryOffices);

            //DADES GENERALS
            $http.defaults.headers.common['x-auth-token'] = user_token;
            $http.defaults.headers.common['x-tenant-ID'] = user_tenant;
            
            if (!loggedUser.photo) {
                loggedUser.photo = 'general-user.png';
            }
            $rootScope.LoggedUser = user;
            $rootScope.userLoaded = true;
            $rootScope.app.annexaVersion = annexaVersion;
            //endregion
            
            //region DADES $localstorage
            
            $localStorage.LoggedUserID = loggedUser.id;
            $localStorage.LoggedUserToken = loggedUser.token;
            $localStorage.LoggedUserTenant = loggedUser.tenant;
            
            //endregion

            //region DADES APP
            
            $rootScope.app.loggeduser = loggedUser;
            $rootScope.allModules = all_modules;

            $rootScope.app.entity = new Entity('id', $rootScope.app.loggeduser.entity.id, $rootScope.app.loggeduser.entity.logo, $rootScope.app.loggeduser.entity[Language.getActiveColumn()], $rootScope.app.loggeduser.entity.holidays);

            $rootScope.esetMode = false;
            $rootScope.app.UIButtons = [];


             if(loggedUser.language && loggedUser.language.acronym){
                 $translate.preferredLanguage(loggedUser.language.acronym);
                 $translate.use(loggedUser.language.acronym).then(function(){
                     $translate.refresh();
                 });
                 tmhDynamicLocale.set(loggedUser.language.acronym);
                 $rootScope.app.language = loggedUser.language.acronym;
                 Language.setCurrent(loggedUser.language.acronym);
                 $rootScope.app.langColumn = Language.getActiveColumn();
             }
             //endregion

             //region promises
             var promises = [
                 RestService.findAll('Language'),
                 AnnexaPermissionsFactory.getMenuWithPermissions(),
                 RestService.findAll('Configuration', 'login'),
                 $http({url: './api/plugin/uibutton/list', method: 'GET'})
             ];

             $q.all(promises).then(function(data) {
                 if(data[0] && data[0].data){
                    if($rootScope.app.languagedef) {
                        $rootScope.app.languagedef.length = 0;
                    }else{
                        $rootScope.app.languagedef = [];
                    }
                     _.forEach(JSOG.decode(JSOG.decode(data[0].data)),function(language) {
                         $rootScope.app.languagedef.push(new LanguageDef(language.acronym, language.name, language.column, $rootScope.LoggedUser.language.acronym == language.acronym));
                     });
                     if(loggedUser.language && loggedUser.language.acronym){
                         $translate.preferredLanguage(loggedUser.language.acronym);
                         $translate.use(loggedUser.language.acronym).then(function(){
                             $translate.refresh();
                         });
                         tmhDynamicLocale.set(loggedUser.language.acronym);
                         $rootScope.app.language = loggedUser.language.acronym;
                         Language.setCurrent(loggedUser.language.acronym);
                         $rootScope.app.langColumn = Language.getActiveColumn();
                     }
                 }

                 if(data[1]) {
                     if($rootScope.app.menu) {
                         if(Array.isArray($rootScope.app.menu)){
                             $rootScope.app.menu.length = 0;
                         }else{
                             $rootScope.app.menu = [];
                         }
                         if(data[1]){
                             _.forEach(data[1], function(value){
                                 $rootScope.app.menu.push(value);
                             });
                         }
                     }else{
                         if(data[1]){
                             $rootScope.app.menu = data[1];
                         }else{
                             $rootScope.app.menu = [];
                         }
                     }
                 }

                 if(data[2] && data[2].data) {
                     var decodedData = JSOG.decode(data[2].data);

                     _.forEach(decodedData, function (conf) {
                         $rootScope.app.configuration[conf.key] = JSON.parse(conf.value);
                     });

                     if($rootScope.app.configuration.eset.value) {
                         $rootScope.app.configuration.show_styled_document_type_acronym.value = false;
                         $rootScope.app.configuration.add_document_type_and_creation_date_to_document_name.value = true;
                         $rootScope.esetMode = true;
                     }
                     
                     //Elimina el "OTHER" que no se debe mostrar de la lista de tipos de documentos en funcion de esetMode  
                     var lstIdentificationDocumentTypes = GlobalDataFactory.thirdIdentificationDocumentTypes;
                     lstIdentificationDocumentTypes.forEach( function(item, index, object) {
                    	 if ($rootScope.esetMode && item.id == 'OTHER' && item.language1 == 'OTHER') {
                    		 object.splice(index, 1);
                    	 } else if (!$rootScope.esetMode && item.id == 'OTHER' && item.language1 == 'PASSPORT') {
                    		 object.splice(index, 1);
                    	 }
                     });

                     if($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.app_version && $rootScope.app.configuration.app_version.value){
                         $rootScope.app.version = $rootScope.app.configuration.app_version.value;
                     }


                     if($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.multiple_register_office && $rootScope.app.configuration.multiple_register_office.default_office && !$rootScope.app.configuration.multiple_register_office.active) {
                         var defaultRegisterEntryOffice = $linq(GlobalDataFactory.registerEntryOffices).singleOrDefault(undefined, "x => x.id == " + $rootScope.app.configuration.multiple_register_office.default_office);
                         if(defaultRegisterEntryOffice) {
                             GlobalDataFactory.registerEntryOffices = [defaultRegisterEntryOffice];
                         } else {
                             GlobalDataFactory.registerEntryOffices = [];
                         }
                     }
                 }

                 if(data[3] && data[3].data) {
                     $rootScope.app.UIButtons = data[3].data;
                 }

                 if ($rootScope.desiredtoState === undefined) {
                     $state.go('annexa.dashboard');
                 } else {
                     if($rootScope.desiredtoStateParams === undefined) {
                         $state.go($rootScope.desiredtoState);
                     } else {
                         $state.go($rootScope.desiredtoState, $rootScope.desiredtoStateParams);
                     }
                 }

                 if($scope) {
                     $injector.get('LoginService').UpdateLoggedUserCounters($scope); //??????????????
                 }

                 AnnexaPendingCounterFactory.runCounters();
                 AnnexaPendingCounterFactory.updateModuleCounters();

                 $rootScope.showLoadingdivSignin = false;
             });
             //endregion
             
        };

        this.UpdateLoggedUserCounters = function($scope) {
            if($rootScope) {
                AnnexaPermissionsFactory.getMenuWithPermissions()
                    .then(function (data) {
                        if($rootScope.app.menu) {
                            if(Array.isArray($rootScope.app.menu)){
                                $rootScope.app.menu.length = 0;
                            }else{
                                $rootScope.app.menu = [];
                            }
                            if(data){
                                _.forEach(data, function(value){
                                    $rootScope.app.menu.push(value);
                                });
                            }
                        }else{
                            if(data){
                                $rootScope.app.menu = data;
                            }else{
                                $rootScope.app.menu = [];
                            }
                        }
                    })
            }
        }

        this.LogOut = function($scope) {
            if($rootScope.LoggedUser) {
                $http({
                    url: '/access/logout',
                    method: 'GET'
                }).then(function(data) {
                	if ($rootScope) {
                        $rootScope.app.loggeduser = undefined;
                    }
                    $localStorage.LoggedUserID = undefined;
                    $localStorage.LoggedUserToken = undefined;
                    $localStorage.LoggedUserTenant = undefined;
                    $rootScope.LoggedUser = undefined;
                    $rootScope.userLoaded = false;
                    $rootScope.desiredtoState = undefined;
                    PermPermissionStore.clearStore();
                }).catch(function (error) {
                	if ($rootScope) {
                        $rootScope.app.loggeduser = undefined;
                    }
                    $localStorage.LoggedUserID = undefined;
                    $localStorage.LoggedUserToken = undefined;
                    $localStorage.LoggedUserTenant = undefined;
                    $rootScope.LoggedUser = undefined;
                    $rootScope.userLoaded = false;
                    $rootScope.desiredtoState = undefined;
                    PermPermissionStore.clearStore();                    
                });
            }
            $state.go('access.signin');
        };

        this.ChangeLanguage = function(lang) {
            $rootScope.LoggedUser.language = lang;
        };

        this.ChangeDelegation = function(deleg, remove) {
            if($rootScope.LoggedUser.delegatorUsers && deleg){
                var exist = false;
                for(var i=0; i<$rootScope.LoggedUser.delegatorUsers.length; i++){
                    if($rootScope.LoggedUser.delegatorUsers[i].id == deleg.id){
                        if(remove){
                            $rootScope.LoggedUser.delegatorUsers.splice(i, 1);
                            exist = true;
                            break;
                        }else{
                            $rootScope.LoggedUser.delegatorUsers[i].startDelegationDate.time = new Date(deleg.startDelegationDate).getTime();
                            $rootScope.LoggedUser.delegatorUsers[i].endDelegationDate.time =  new Date(deleg.endDelegationDate).getTime();
                            $rootScope.LoggedUser.delegatorUsers[i].delegatorUser = deleg.delegatorUser;
                            $rootScope.LoggedUser.delegatorUsers[i].delegatedUser = deleg.delegatedUser;
                            $rootScope.LoggedUser.delegatorUsers[i].createdDate.time =  new Date(deleg.createdDate).getTime();
                            exist = true;
                            break;
                        }
                    }
                }
                if(!exist && !remove){
                    var delegToCache = {
                        id:deleg.id,
                        startDelegationDate:{time: new Date(deleg.startDelegationDate).getTime()},
                        endDelegationDate:{time: new Date(deleg.endDelegationDate).getTime()},
                        delegatorUser:deleg.delegatorUser,
                        delegatedUser:deleg.delegatedUser,
                        createdDate:{time: new Date(deleg.createdDate).getTime()}
                    }
                    $rootScope.LoggedUser.delegatorUsers.push(delegToCache);
                }

                HeaderService.changeState($state.current);
            }
        };

        this.getLoadUser = function($scope) {
            if($localStorage.LoggedUserID != null && $localStorage.LoggedUserID != undefined){
                $http.get( "./api/users/getUserLogged"+ "?id=" + $localStorage.LoggedUserID).success(function (user) {
                    var loggedUser = angular.fromJson(user.user);
                    loggedUser.isAuthenticated = true;
                    loggedUser.roles = user.roles;
                    loggedUser.token = user.token;
                    loggedUser.tenant = user.tenant;
                    loggedUser.apiUrl = $rootScope.app.baseUrl + 'api/users/' + loggedUser.id;
                    if (!loggedUser.photo) {
                        loggedUser.photo = 'general-user.png';
                    }
                    $rootScope.app.loggeduser = loggedUser;
                    $rootScope.app.annexaVersion = user.annexaVersion;
                    $rootScope.LoggedUser = loggedUser;
                    $rootScope.userLoaded = true;
                    $injector.get('LoginService').UpdateLoggedUserCounters($scope);
                    if ($rootScope.desiredtoState === undefined) {
                        $state.go('annexa.dashboard');
                    }
                });
            }
        };

        this.getActiveDelegation = function(future) {
            var retDelegation = undefined;
            if($rootScope.LoggedUser && $rootScope.LoggedUser.delegatorUsers) {
                angular.forEach($rootScope.LoggedUser.delegatorUsers, function(delegation) {
                    if(!retDelegation) {
                        var endDate = new Date(delegation.endDelegationDate);
                        var startDate = new Date(delegation.startDelegationDate);
                        if(delegation.startDelegationDate.time){
                            var startDate = new Date(delegation.startDelegationDate.time);
                        }
                        if(delegation.startDelegationDate.time){
                            endDate = new Date(delegation.endDelegationDate.time);
                        }
                        var actualDate = new Date();
                        var todayDate = new Date();
                        todayDate.setHours(0,0,0,0);
                        if (actualDate >= startDate && todayDate <= endDate) {
                            retDelegation = delegation;
                        }else if(future && todayDate <= endDate){
                            retDelegation = delegation;
                        }
                    }
                });
            }

            return retDelegation;
        }

        this.validateToken = function() {
            var self = this;
            return $q.all([
                $http(
                    {
                        method: 'GET',
                        url: './access/validatetoken',
                        params: { token: $localStorage.LoggedUserToken }
                    }
                ).then(function(tokendata) {
                    if(!tokendata.data) {
                        $localStorage.LoggedUserID = undefined;
                        $localStorage.LoggedUserToken = undefined;
                        $localStorage.LoggedUserTenant = undefined;
                        $state.go('access.signin');
                    }
                }).catch(function (data) {
                    $localStorage.LoggedUserID = undefined;
                    $localStorage.LoggedUserToken = undefined;
                    $localStorage.LoggedUserTenant = undefined;
                    $state.go('access.signin');
                }),
                $http(
                    {
                        method: 'GET',
                        url: './api/users/getUserLogged',
                        params: { id: $localStorage.LoggedUserID },
                        headers: { 'x-auth-token' : $localStorage.LoggedUserToken, 'x-tenant-ID': $localStorage.LoggedUserTenant }
                    }
                ).then(function(data) {
                    if(data.status == 200) {
                        var tmp = JSOG.decode(data.data)
                        var LoadedUser = data.data;
                        self.PostLoginActions(
                            JSOG.decode(LoadedUser.userObject),
                            LoadedUser.roles,
                            LoadedUser.token,
                            undefined,
                            LoadedUser.tenant,
                            tmp.modules,
                            tmp.managedOrgans,
                            tmp.addAutomaticActionPermissions,
                            tmp.annexaVersion,
                            tmp.activeMultipleRegisterOffice,
                            registerEntryOffices
                        );
                    }
                }).catch(function (data) {
                    $localStorage.LoggedUserID = undefined;
                    $localStorage.LoggedUserToken = undefined;
                    $localStorage.LoggedUserTenant = undefined;
                    $state.go('access.signin');
                }),
                RestService.findAll('RoleActionPermission')
                    .then(function(data) {
                        var permissions = [];
                        _.forEach(JSOG.decode(data.data), function (perm) {
                            permissions.push(perm.actionPermission.keyId);
                        });
                        PermPermissionStore.defineManyPermissions(permissions, function (permissionName, transitionProperties) {
                            return true;
                        })
                    }),
                RestService.findAll('Language')
                    .then(function(data) {
                        $rootScope.app.languagedef = [];
                        _.forEach(JSOG.decode(data.data),function(language) {
                            $rootScope.app.languagedef.push(new LanguageDef(language.acronym, language.name, language.column, $rootScope.LoggedUser.language.acronym == language.acronym));
                        });
                    })
            ]);
        }

    }])
    .factory('LoginFactory',['$resource',function($resource) {
        return $resource(':action', {},
            {
                authenticate: {
                    method: 'POST',
                    url : 'access/authenticate',
                    params: {'action' : 'authenticate'}
                }
            }
        );
    }]);