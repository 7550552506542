angular
    .module('annexaApp')
    .factory('FileFactory',['$window', '$q', function($window, $q) {
        var factory = {}

        factory.scope = undefined;

        var rawFiles = [];
        var fileObjects = [];
        var attrs = {};

        var _readerOnLoad = function(fReader, file, fileObject) {
            return function(e) {
                var buffer = e.target.result;
                var promise;

                fileObject.base64 = $window._arrayBufferToBase64(buffer);

                promise = $q.when(fileObject);

                promise.then(function(fileObj) {
                    fileObjects.push(fileObj);
                })
            }
        }

        var _readfile = function(file, fileObject) {
            var deferred = $q.defer();

            var reader = new $window.FileReader();

            reader.onload = function() {
                fileObject.base64 = $window._arrayBufferToBase64(reader.result);

                var promise = $q.when(fileObject);
                promise.then(function (fileObj) {
                    fileObjects.push(fileObj);
                    deferred.resolve(fileObj);
                }).catch(function (error) {
                    deferred.reject(error);
                });
            }

            reader.readAsArrayBuffer(file);

            return deferred.promise;
        }

        var _readfiles = function() {
            var deferred = $q.defer();

            var promises = [];

            for(var i = rawFiles.length - 1; i >= 0; i--) {
                //var reader = new $window.FileReader();
                var file = rawFiles[i];
                var fileObject = {};

                fileObject.filetype = file.type;
                fileObject.filename = file.name;
                fileObject.filesize = file.size;

                promises.push(_readfile(file, fileObject));
                //reader.onload = _readerOnLoad(reader, file, fileObject);
                //reader.readAsArrayBuffer(file);
            }

            $q.all(promises)
                .then(function(data) {
                    deferred.resolve(data);
                }).catch(function(error) {
                    deferred.reject(error)
            })
            return deferred.promise;
        }

        factory.processInput = function (e) {
            var deferred = $q.defer();

            attrs.multiple = e.multiple;

            fileObjects = [];

            if(e.files.length === 0) {
                rawFiles = [];
                deferred.resolve(fileObjects);
            } else {
                rawFiles = e.files;

                _readfiles()
                    .then(function (data) {
                        deferred.resolve(fileObjects);
                    }).catch(function(error) {
                        deferred.reject(error);
                });
            }

            return deferred.promise;
        }

        return factory;
    }]);