angular
    .module('annexaApp')
    .factory('RouteStateFactory',['$rootScope', '$stateParams', function($rootScope, $stateParams) {
        var factory = {};

        factory.routes = [];

        factory.addRouteState = function (route, state, sufix) {
            var indexRoute = undefined;
            var type = undefined;
            if(route.name == 'annexa.doc.documents.type' && $stateParams.type){
            	type = $stateParams.type;
            }
            if(sufix){
                indexRoute = $linq(factory.routes).indexOf("x => x.route.name == '" + route.name + "' && x.user == " + $rootScope.LoggedUser.id + " && x.sufix == '"+sufix+"'"+((type)?" && x.type == '"+type+"'":""));
            }else{
                indexRoute = $linq(factory.routes).indexOf("x => x.route.name == '" + route.name + "' && x.user == " + $rootScope.LoggedUser.id + " && !x.sufix"+((type)?" && x.type == '"+type+"'":""));
            }
            if(indexRoute == -1) {
                factory.routes.push({
                    route: route,
                    user: $rootScope.LoggedUser.id,
                    state: state,
                    sufix: sufix,
                    type: type
                })
            } else {
                factory.routes[indexRoute].state = state;
            }
        }

        factory.getRouteState = function (route, sufix) {
            var indexRoute = undefined;
            var type = undefined;
            if(route.name == 'annexa.doc.documents.type' && $stateParams.type){
            	type = $stateParams.type;
            }
            if(sufix){
                indexRoute = $linq(factory.routes).indexOf("x => x.route.name == '" + route.name + "' && x.user == " + $rootScope.LoggedUser.id + " && x.sufix == '"+sufix+"'"+((type)?" && x.type == '"+type+"'":""));
            }else{
                indexRoute = $linq(factory.routes).indexOf("x => x.route.name == '" + route.name + "' && x.user == " + $rootScope.LoggedUser.id + " && !x.sufix"+((type)?" && x.type == '"+type+"'":""));
            }
            if(indexRoute != -1) {
                return factory.routes[indexRoute];
            }

            return undefined;
        }

        factory.resetRouteState = function (route) {
        	var type = undefined;
            var indexRoute = $linq(factory.routes).indexOf("x => x.route.name == '" + route.name + "' && x.user == " + $rootScope.LoggedUser.id);

            if(indexRoute != -1) {
                factory.routes.splice(indexRoute, 1);
            }
        }

        factory.resetRoutes = function () {
            factory.routes = $linq(factory.routes).where("x => x.user != " + $rootScope.LoggedUser.id).toArray();
        }

        return factory;
    }])