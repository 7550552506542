/**
 * Created by osirvent on 07/09/2016.
 */
var BoxThird = function(third, langColumn) {
    this.third = third;
    this.langColumn = langColumn;

    this.getDefaultAddress = function() {
        return $linq(this.third.addresses).firstOrDefault(null, "x => x.default == 1");
    };

    this.getOtherDefaultAdresses = function () {
        return $linq(this.third.addresses).where("x => x.default == 0 && x.defaultByType == 1").toArray();
    };

    this.getAdressesHtml = function() {
        var retAdresses = [];

        retAdresses.push(this.getDefaultAddressHtml());

        angular.forEach(this.getOtherDefaultAdressesHtml(), function (value, key) {
            retAdresses.push(value);
        })

        return retAdresses;
    };

    this.getDefaultAddressHtml = function() {
        var defaultAddress = this.getDefaultAddress();

        if(defaultAddress) {
            switch (defaultAddress.addressType) {
                case 'POSTAL':
                    return this.getPostalAddressHtml(defaultAddress);
                case 'TELEMATIC_EMAIL':
                    return this.getEmailAddressHtml(defaultAddress);
                case 'TELEMATIC_MOBILEPHONE':
                    return this.getMobilePhoneAddressHtml(defaultAddress);
                default:
                    return '';
            }
        } else {
            return '';
        }
    };

    this.getOtherDefaultAdressesHtml = function () {
        var otherDefaultAdressesHtml = [];
        var self = this;

        angular.forEach(this.getOtherDefaultAdresses(), function (value, key) {
            var address = '';

            switch(value.addressType) {
                case 'POSTAL':
                    address = self.getPostalAddressHtml(value);
                    break;
                case 'TELEMATIC_EMAIL':
                    address = self.getEmailAddressHtml(value);
                    break;
                case 'TELEMATIC_MOBILEPHONE':
                    address = self.getMobilePhoneAddressHtml(value);
                    break;
            }

            if(address != '') {
                otherDefaultAdressesHtml.push(address);
            }

        });

        return otherDefaultAdressesHtml;
    };

    this.getMobilePhoneAddressHtml = function (address) {
        if(address.addressType != 'TELEMATIC_MOBILEPHONE') {
            return '';
        }

        var addressHtml = '';

        addressHtml += '<i class="fa fa-mobile text-black-lt fa-fw fa-mob-esp"></i>';
        if(address.phoneInternationalPrefix) {
            addressHtml += '<a id="addr'+(Math.floor(Math.random() * (1000000 - 1000 +1) ) + 1000)+'" class="text-primary"  href="tel:+' + address.phoneInternationalPrefix + address.telematicValue + '"> +' + address.phoneInternationalPrefix + address.telematicValue + '</a>';
        } else {
            addressHtml += '<a id="addr'+(Math.floor(Math.random() * (1000000 - 1000 +1) ) + 1000)+'" class="text-primary"  href="tel:+34' + address.telematicValue + '"> ' + address.telematicValue + '</a>';
        }

        return addressHtml;
    };

    this.getEmailAddressHtml = function (address) {
        if(address.addressType != 'TELEMATIC_EMAIL') {
            return '';
        }

        var addressHtml = '';

        addressHtml += '<i class="fa fa-envelope fa-fw text-black-lt" aria-hidden="true"></i>';
        addressHtml += '<span>';
        addressHtml += '<a id="addr'+(Math.floor(Math.random() * (1000000 - 1000 +1) ) + 1000)+'" class="text-primary" href="mailto:' + address.telematicValue + '"> ' + address.telematicValue + '</a>';
        addressHtml += '</span>';

        return addressHtml;
    };

    this.getPostalAddressHtml = function(address) {
        if(address.addressType != 'POSTAL') {
            return '';
        }

        var addressHtml = '';

        addressHtml += '<i class="fa fa-map-marker fa-fw text-black-lt"></i>';
        addressHtml += '<span id="addr'+(Math.floor(Math.random() * (1000000 - 1000 +1) ) + 1000)+'">';

        if(address.validStreet) {
            if(address.validStreet.streetType){
                addressHtml += address.validStreet.streetType[this.langColumn]+ ' ';
            }
            addressHtml += address.validStreet.name+ ' ';
        } else {
            if(address.streetType) {
                addressHtml += ' ' + address.streetType[this.langColumn];
            }
            addressHtml += ' ' + address.street + ', ';
        }
        if(address.kilometer) {
            addressHtml += 'Km. ' + address.kilometer;
        }
        if(address.numberAddres) {
            addressHtml += 'Num. ' + address.numberAddres;
        }
        addressHtml += ' - ';
        if(address.postalCode) {
            addressHtml += 'C.P. ' + address.postalCode + " ";
        }
        if(address.validCity) {
            addressHtml += address.validCity.name;
        } else {
            if(address.city) {
                addressHtml += address.city;
            }
        }
        if(address.country) {
            addressHtml += ' ('+ address.country[this.langColumn] + ')';
        }

        addressHtml += '</span>';

        return addressHtml;
    }
}

Object.byString = function(o, s) {
    s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    s = s.replace(/^\./, '');           // strip a leading dot
    var a = s.split('.');
    for (var i = 0, n = a.length; i < n; ++i) {
        var k = a[i];
        if (k in o) {
            o = o[k];
        } else {
            return;
        }
    }
    return o;
}

var SortedArray = function (array, sortProperty) {

    this.array = array;
    this.sortProperty = sortProperty;

    this.sort = function () {
        return $linq(array).orderBy("x => x." + sortProperty, linq.caseInsensitiveComparer).toArray();
    }
}