/**
 * Created by dpardell on 08/01/2017.
 */
angular.module('annexaApp')
    .factory('AnnexaPlugins',['$compile', '$rootScope', '$http', 'DialogsFactory', 'globalModals','AnnexaModalFactory', '$filter', function ($compile, $rootScope, $http, DialogsFactory, globalModals, AnnexaModalFactory, $filter) {
        var factory = {};

        factory.addPlugin = function(scope, plugin, id){
            var modalPluginsCreated = function(plugins, args, model){
                var modal = angular.copy(globalModals.pluginCreate);
                modal.data = { plugins: plugins, args:args, model:model};
                modal.submitLabel = 'global.literals.create';
                modal.submitFunction = function() {};
                AnnexaModalFactory.showModal('modalPlugins', modal);
            }

            scope.$on('annexaPluginsExecute'+plugin+((id)?id:''), function(event, args) {
                if(!args.pluginNoExecute) {
                    if (args.pluginObject && args.pluginOrigin && args.object && args.submitFunction) {
                        $http({
                            url: './api/plugin/list/' + args.pluginOrigin + '/' + args.pluginObject,
                            method: 'GET',
                            params: {object: args.object}
                        }).then(function (data) {
                            var plugins = JSOG.decode(data.data);
                            var pluginsToCall = [];
                            var model = {};
                            if (plugins && plugins.length > 0) {
                                _.forEach(plugins, function (value) {
                                    value.parameters = angular.fromJson(value.configuration.parameters);
                                    model[value.target] = undefined;
                                    _.forEach(value.parameters, function (val, index) {
                                        if (val.frontendType != 'INPUT' && val.frontendType != 'TEXTAREA') {
                                            value.parameters[index].optionsUrl = './api/plugin/list_values/' + value.target + '/' + val.id;
                                            value.parameters[index].optionOnSelect = function (id, $item) {
                                                var linkedParameters = $linq(value.parameters).where("x => x.linked == '" + id + "'").toArray();
                                                _.forEach(linkedParameters, function (lp) {
                                                    var indexParameter = $linq(value.parameters).indexOf("x => x.id == '" + lp.id + "'");
                                                    if (indexParameter != -1) {
                                                        if (!model[value.target]) {
                                                            model[value.target] = {};
                                                        }
                                                        model[value.target][lp.id] = undefined;
                                                        value.parameters[indexParameter].optionsUrl = './api/plugin/list_values/' + value.target + '/' + lp.id + '/' + $item.id;
                                                    }
                                                })
                                            }
                                        } else {
                                            value.parameters[index].optionsUrl = undefined;
                                            if (!model[value.target]) {
                                                model[value.target] = {};
                                            }
                                            model[value.target][val.id] = undefined;
                                        }
                                    })
                                    pluginsToCall.push(value);
                                });
                                modalPluginsCreated(pluginsToCall, args, model);
                            } else {
                                args.submitFunction(args.object, args.params);
                            }
                        }).catch(function (error) {
                            DialogsFactory.error(error.data.message, $filter('translate')('DIALOGS_ERROR_MSG'));
                        });
                    } else {
                        DialogsFactory.error('Error plugin', $filter('translate')('DIALOGS_ERROR_MSG'));
                    }
                }else{
                    if (args.object && args.submitFunction) {
                        args.submitFunction(args.object, args.params);
                    }else{
                        DialogsFactory.error('Error plugin', $filter('translate')('DIALOGS_ERROR_MSG'));
                    }
                }
            });
        }


        return factory;
    }]);
