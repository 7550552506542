/**
 * Created by osirvent on 10/11/2015.
 */
angular.module('annexaApp')
    .factory('notificationInterceptor',['$q', function ($q) {
        return {
            response: function(response) {
                var alertKey = response.headers('X-sampleApplicationApp-alert');
                if (angular.isString(alertKey)) {
                    //AlertService.success(alertKey, { param : response.headers('X-sampleApplicationApp-params')});
                }
                return response;
            }
        };
    }]);

/*    .factory('notificationInterceptor',['$q', 'AlertService', function ($q, AlertService) {
        return {
            response: function(response) {
                var alertKey = response.headers('X-sampleApplicationApp-alert');
                if (angular.isString(alertKey)) {
                    AlertService.success(alertKey, { param : response.headers('X-sampleApplicationApp-params')});
                }
                return response;
            }
        };
    }]);*/
