/**
 * Created by osirvent on 01/08/2016.
 */
angular.module('annexaApp')
    .directive('annexaCommentTask',['$compile', 'TaskFactory', 'globalModals', 'AnnexaFormlyFactory', 'AnnexaModalFactory', function ($compile, TaskFactory, globalModals, AnnexaFormlyFactory, AnnexaModalFactory) {
        var getTemplate = function(scope) {
            var template = '';
            if(scope.actual == 'false') {
                template += '<span><a href="" > <i class="fa text-grey text-lg ng-class:getCommentClass(task.comments)"></i></a></span>';
            }else{
                template += '<span><a href="" ng-click="addComment(task)"> <i class="fa text-grey text-lg ng-class:getCommentClass(task.comments)"></i></a></span>';
            }
            return template;
        };

        return {
            restrict: 'E',
            replace: true,
            scope:{
                task: '=',
                actual: '@'
            },
            transclude: true,
            link: function(scope, element, attr, ctrl, transclude) {
                scope.getCommentClass = function(comment){
                    if(comment){
                        return "fa-comment";
                    }else{
                        return "fa-comment-o";
                    }
                }
                scope.updateComment = function(){
                    var self = this;
                    TaskFactory.updateTask(this.annexaFormly.model.modal_body.task).then(function(data) {
                        scope.task = JSOG.decode(data);
                        self.close();
                    }).catch(function (error) {
                        //Eliminar el document del llistat del documentBox
                    });

                }
                scope.addComment = function(task){
                    var modal = globalModals.taskUpdateComment;
                    modal.annexaFormly.model = {};
                    modal.annexaFormly.model.modal_body = {task:task};
                    modal.annexaFormly.options = {};
                    modal.annexaFormly.options.formState = {readOnly: false};
                    AnnexaFormlyFactory.showModal("modalUpdateComentTask", modal, scope.updateComment, false);
                }

                element.html(getTemplate(scope)).show();

                transclude(scope.$parent, function (clone, scope) {
                    element.find('.box-body').append(clone);
                });

                $compile(element.contents())(scope);
            }
        }

    }])
    .directive('annexaBoxCommentTask',['$filter','$compile', 'HelperService', '$rootScope',
        function ($filter, $compile, HelperService, $rootScope) {
            var getTemplate = function(scope) {
                var template = '';
                template += '<div ng-if="comments.length==0" class="text-center cursiva-grey" translate="global.literals.empty_comments">Add</div>';
                template += '<div ng-if="comments.length>0">';
                template += '   <div class="row ng-class:{\'m-b-p-b-sm-b-b\' : !$last}" ng-repeat="comment in comments" >';
                template += '       <div class="col-xs-11">';
                template += '           <div class="row m-b-xs _600">';
                template += '               <div class="col-sm-8">';
                template += '                   <span>{{comment.createdUser.name}} {{comment.createdUser.surename1}}</span>';
                template += '                   <span ng-if="comment.createdUser.surename2">{{comment.createdUser.surename2}}</span>';
                template += '               </div>';
                template += '               <div class="col-sm-4">';
                template += '                   <span>{{comment.createdDate | date:\'dd/MM/yyyy HH:mm:ss\'}}</span>';
                template += '               </div>';
                template += '           </div>';
                template += '           <div class="row m-b-xs">';
                template += '               <div class="col-sm-12">';
                template += '                   <span class="italica">{{comment.comments}}</span>';
                template += '               </div>';
                template += '           </div>';
                template += '       </div>';
                template += '       <div class="col-xs-1">';
                template += '           <annexa-delete-button ng-if="deleteFunction && !isManage && isCreator(comment.createdUser.id) && isOpenTask" class="pull-right" delete-function="deleteFunction" delete-object="taskComment" delete-object-index="$index" actual="true"></annexa-delete-button>';
                template += '       </div>';
                template += '   </div>';
                template += '</div>';
                return template;
            };

            return {
                restrict: 'E',
                replace: true,
                scope: {
                    comments: '=',
                    deleteFunction: '=',
                    isManage: '=',
                    isOpenTask: '='
                },
                link: function (scope, element, attr) {
                    scope.isCreator = function(creator){
                        if($rootScope.LoggedUser && $rootScope.LoggedUser.id && creator && $rootScope.LoggedUser.id == creator){
                            return true;
                        }else {
                            return false;
                        }
                    }
                    element.html(getTemplate(scope)).show();
                    $compile(element.contents())(scope);
                }
            }
        }])
    .directive('annexaBoxDocumentsTask', ['$compile', 'HelperService', 'DccumentsFactory', function ($compile, HelperService, DccumentsFactory) {
        var getTemplate = function (scope) {
            var template = '';
            template += '<div ng-if="documents.length==0" class="text-center cursiva-grey" translate="global.literals.empty_documents">Add</div>';
            template += '<div ng-if="documents.length>0" class="documentRows">';
            template += '   <div class="row ng-class:{\'m-b-sm-p-b-sm-b-b\' : !$last} " ng-repeat="document in documents">';
            template += '       <div class="col-sm-9 col-xxl-10">';
            template += '           <div class="row m-b-xs p-b-xs m-t-sm">';
            template += '               <div class="col-xs-3 col-sm-2 col-xxl-1">';
            template += '                   <span ng-if="document.docStatus == \'NEW\'"><i title="{{\'global.documentState.NEW\' | translate}}" class="fa fa-circle-o text-blue fa-lg v-t"></i></span>';
            template += '                   <span ng-if="document.docStatus == \'NEW_AND_ATTACHMENT\'"><i title="{{\'global.documentState.NEW_AND_ATTACHMENT\' | translate}}" class="fa v-t fa-adjust text-blue fa-lg"></i></span>';
            template += '                   <span ng-if="document.docStatus == \'PENDING_SIGN\'"><i title="{{\'global.documentState.PENDING_SIGN\' | translate}}" class="fa fa-circle text-blue v-t fa-lg "></i></span>';
            template += '                   <span ng-if="document.docStatus == \'SIGNED\'"><i title="{{\'global.documentState.SIGNED\' | translate}}" class="fa fa-circle text-primary fa-lg v-t"></i></span>';
            template += '                   <span ng-if="document.docStatus == \'REJECTED\'"><i title="{{\'global.documentState.REJECTED\'| translate}}" class="fa fa-circle text-danger fa-lg v-t"></i></span>';
            template += '                   <span ng-if="document.docStatus == \'COMPLETE\'"><i title="{{\'global.documentState.COMPLETE\'| translate}}" class="fa fa-dot-circle-o text-primary fa-lg v-t"></i></span>';
            template += '               </div>';
            template += '               <div class="col-xs-9 col-sm-10 col-md-5 col-lg-4 small-lg">';
            template += '                   <annexa-href-permission-document document="document" name-properties="\'name\'" origin="TASK"></annexa-href-permission-document>'
            template += '               </div>';
            template += '               <div class=" col-md-6 _600">';
            template += '                   <span >{{document.document.name}}</span>';
            template += '               </div>';
            template += '           </div>';
            template += '       </div>';
            template += '       <div class="col-sm-3 col-xxl-2">';
            template += '           <div class="btn-group dropdown pull-right btn-group-sm" annexa-permission annexa-permission-only="\'view_documents\'" annexa-permission-only-profile="document.profiles">';
            template += '               <a class="btn primary" ui-sref="annexa.doc.documents.view({code: document.id})" target="_blank">';
            template += '                   <i class="fa fa-eye" aria-hidden="true"></i>';
            template += '                   <span translate="global.literals.see">Show</span>';
            template += '               </a>';
            template += '               <div class="btn-group btn-group-sm" ng-if="!isManage && isOpenTask">';
            template += '                 <button class="btn primary dropdown-toggle" data-toggle="dropdown" title="{{ \'global.literals.showOptions\' | translate }}"><span class="sr-only">{{ \'global.literals.showOptions\' | translate }}</span></button>';
            template += '                   <div class="dropdown-menu primary pull-right">';
            template += '                       <a class="dropdown-item" ng-click="removeDocument($index)" annexa-permission annexa-permission-only="\'delete_document\'" annexa-permission-only-profile="document.profiles">';
            template += '                           <span translate="global.literals.remove">RemoveDoc</span>';
            template += '                       </a>';
            template += '                   </div>';
            template += '               </div>';
            template += '           </div>';
            template += '       </div>';
            template += '   </div>';
            template += '</div>';
            template += '</div>';

            return template;
        };

        return {
            restrict: 'E',
            replace: true,
            scope: {
                documents: '=',
                deleteFunction: '=',
                actionFunction: '=',
                isManage: '=',
                isOpenTask: '='
            },
            link: function (scope, element, attr) {
                element.html(getTemplate(scope)).show();
                $compile(element.contents())(scope);

                scope.showAlert = function (message) {
                    alert(message);
                }

                scope.canEditMimeType = function (fileTypeDoc) {
                    return HelperService.canEditMimeType(fileTypeDoc);
                }

                scope.editOnline = function (idDocumentManager) {
                    DccumentsFactory.getEditOnlineUrl(idDocumentManager).then(function (eou) {
                        if (eou != null && eou != undefined && eou != '') {
                            window.location.href = eou;
                        }
                    }).catch(function () {
                    });
                };

                scope.createFileInfoObject = function (idDocumentManager, fileType, name, idPDF, fileTypePDF, pdf) {
                    var fileInfo = {
                        idDocumentManager: idDocumentManager,
                        fileType: fileType,
                        name: name,
                        idPdf: idPDF,
                        fileTypePDF: fileTypePDF,
                        pdf: pdf
                    }
                    return fileInfo;
                };

                scope.removeDocument = function(index){
                    if(scope.deleteFunction){
                        scope.deleteFunction(undefined, index);
                    }else{
                        scope.documents.splice(index, 1);
                    }
                }
            }
        }
    }])
    .directive('annexaBoxRegisterEntriesTaskInput', ['$compile', function ($compile) {
        var getTemplate = function (scope) {
            var template = '';
            template += '<div ng-if="registerEntries.length==0 && type == \'INPUT\'" class="text-center cursiva-grey" translate="global.literals.empty_registerEntries">Add</div>';
            template += '<div ng-if="registerEntries.length==0 && type == \'OUTPUT\'" class="text-center cursiva-grey" translate="global.literals.empty_registerEntriesOutput">Add</div>';
            template += '<div ng-if="registerEntries.length>0">';
            template += '   <div class="row ng-class:{\'m-b-p-b-sm-b-b\' : !$last}" ng-repeat="registerEntry in registerEntries">';
            template += '       <div class="col-xs-11">';
            template += '           <div class="row m-b-xs">';
            template += '               <div class="col-xs-10 col-sm-5 col-xl-3 hellip ">';
            template += '                   <annexa-href-permission-reg-input register-entry="registerEntry"></annexa-href-permission-reg-input>'
            template += '               </div>';
            template += '               <div class="pull-left col-sm-7 col-xl-9  _600">';
            template += '                   <span>{{registerEntry.extract}}</span>';
            template += '               </div>';
            template += '               <div class="col-sm-2">';
            template += '               </div>';
            template += '           </div>';
            template += '       </div>';
            template += '       <div class="col-xs-1">';
            template += '           <annexa-delete-button ng-if="!isManage && isOpenTask" class="pull-right" delete-function="deleteFunction" delete-object="registerEntry" delete-object-index="$index" actual="true"></annexa-delete-button>';
            template += '       </div>';
            template += '   </div>';
            template += '</div>';

            return template;
        };

        return {
            restrict: 'E',
            replace: true,
            scope: {
                registerEntries: '=',
                deleteFunction: '=',
                type: '@',
                isManage: '=',
                isOpenTask: '='
            },
            link: function (scope, element, attr) {
                element.html(getTemplate(scope)).show();
                $compile(element.contents())(scope);
            }
        }
    }])
    .directive('annexaBoxRegisterEntriesTaskOutput', ['$compile', function ($compile) {
        var getTemplate = function (scope) {
            var template = '';
            template += '<div ng-if="registerEntries.length==0 && type == \'INPUT\'" class="text-center cursiva-grey" translate="global.literals.empty_registerEntries">Add</div>';
            template += '<div ng-if="registerEntries.length==0 && type == \'OUTPUT\'" class="text-center cursiva-grey" translate="global.literals.empty_registerEntriesOutput">Add</div>';
            template += '<div ng-if="registerEntries.length>0">';
            template += '   <div class="row ng-class:{\'m-b-p-b-sm-b-b\' : !$last}" ng-repeat="registerEntry in registerEntries">';
            template += '       <div class="col-xs-11">';
            template += '           <div class="row m-b-xs">';
            template += '               <div class="col-xs-10 col-sm-5 col-xl-3 hellip ">';
            template += '                   <annexa-href-permission-reg-input register-entry="registerEntry"></annexa-href-permission-reg-input>'
            template += '               </div>';
            template += '               <div class="pull-left col-sm-7 col-xl-9  _600">';
            template += '                   <span>{{registerEntry.extract}}</span>';
            template += '               </div>';
            template += '               <div class="col-sm-2">';
            template += '               </div>';
            template += '           </div>';
            template += '       </div>';
            template += '       <div class="col-xs-1">';
            template += '           <annexa-delete-button ng-if="!isManage && isOpenTask" class="pull-right" delete-function="deleteFunction" delete-object="registerEntry" delete-object-index="$index" actual="true"></annexa-delete-button>';
            template += '       </div>';
            template += '   </div>';
            template += '</div>';

            return template;
        };

        return {
            restrict: 'E',
            replace: true,
            scope: {
                registerEntries: '=',
                deleteFunction: '=',
                type: '@',
                isManage: '=',
                isOpenTask: '='
            },
            link: function (scope, element, attr) {
                element.html(getTemplate(scope)).show();
                $compile(element.contents())(scope);
            }
        }
    }])
    .directive('annexaBoxAttachmentTask',['$filter','$compile', 'HelperService', '$rootScope', 'CommonService',
        function ($filter, $compile, HelperService, $rootScope, CommonService) {
            var getTemplate = function(scope) {
                var template = '';
                template += '<div ng-if="attachments.length==0" class="text-center cursiva-grey" translate="global.literals.attachments">Add</div>';
                template += '<div ng-if="attachments.length>0">';
                template += '   <div class="row ng-class:{\'m-b-p-b-sm-b-b\' : !$last}" ng-repeat="attachment in attachments" >';
                template += '       <div class="col-xs-12">';
                template += '           <div class="row m-b-xs _600">';
                template += '               <div class="col-sm-10">';
                template += '                   <a href ng-click="viewOrDownloadDocument(attachment.idDocumentManager, attachment.fileName, attachment.fileTypeDoc);" class="text-primary">';
                template += '                       <span>{{attachment.fileName}}</span>';
                template += '                   </a>&nbsp;';
                template += '                   ({{calculateSizeFile(attachment.sizeDoc)}})';
                template += '               </div>';
                template += '               <div class="col-sm-2">';
                template += '                   <span>{{attachment.createdDate | date:\'dd/MM/yyyy\'}}</span>';
                template += '               </div>';
                template += '           </div>';
                template += '       </div>';
                template += '   </div>';
                template += '</div>';
                return template;
            };

            return {
                restrict: 'E',
                replace: true,
                scope: {
                    attachments: '=',
                    viewOrDownloadDocument: '=',
                    isManage: '=',
                    isOpenTask: '='
                },
                link: function (scope, element, attr) {
                    scope.calculateSizeFile = function(size){
                        if(size){
                            return CommonService.getFileSizeDescription(size);
                        }
                        return "";
                    }

                    element.html(getTemplate(scope)).show();
                    $compile(element.contents())(scope);
                }
            }
        }])
    .directive('annexaBoxDossierTransactionsTask', ['$compile', 'Language', function ($compile, Language) {
        var getTemplate = function (scope) {
            var template = '';
            template += '<div ng-if="dossierTransactions.length==0" class="text-center cursiva-grey" translate="global.literals.empty_dossiers">Empty</div>';
            template += '<div ng-if="dossierTransactions.length>0">';
            template += '   <div class="row ng-class:{\'m-b-p-b-sm-b-b\' : !$last} " ng-repeat="dossierTransaction in dossierTransactions">';
            template += '       <div class="col-xs-11">';
            template += '           <div class="row m-b-xs">';
            template += '               <div class="col-xs-10 col-sm-5 hellip ">';
            template += '                   <annexa-href-permission-dossier-transaction dossier-transaction="dossierTransaction"/>';
            template += '               </div>';
            template += '               <div ng-class="dossierTransaction.dossier.closed ? \'pull-left col-sm-4\' : \'pull-left col-sm-6\'">';
            template += '                   <span class=" _600">{{descriptionDossierTransaction(dossierTransaction)}}</span>';
            template += '               </div>';
            template += '               <div class="col-sm-3" ng-if="!dossierTransaction.dossier.closed">';
            template += '                   <span class="label label-xxs" translate="global.literals.in_process">In proces</span>';
            template += '               </div>';
            template += '               <div class="col-sm-5" ng-if="dossierTransaction.dossier.closed">';
            template += '                   <span class="label label-xxs success" ng-if="dossierTransaction.dossier.dossierStatus != \'CANCELLED\'" translate="global.literals.ended">Ended</span>';
            template += '                   <span class="label label-xxs danger" ng-if="dossierTransaction.dossier.dossierStatus == \'CANCELLED\'" translate="CANCELLED">Cancelled</span>';
            template += '                   <span class="small text-success">{{dossierTransaction.dossier.endDate | date: \'dd/MM/yyyy HH:mm:ss\'}}</span>';
            template += '               </div>';
            template += '           </div>';
            template += '       </div>';
            template += '       <div class="col-xs-1">';
            template += '           <annexa-delete-button ng-if="!isManage && isOpenTask" class="pull-right" delete-function="deleteFunction" delete-object="dossierTransaction" delete-object-index="$index" actual="true"></annexa-delete-button>';
            template += '       </div>';
            template += '   </div>';
            template += '</div>'
            return template;
        };

        return {
            restrict: 'E',
            replace: true,
            scope: {
                dossierTransactions: '=',
                deleteFunction: '=',
                isManage: '=',
                isOpenTask: '='
            },
            link: function (scope, element, attr) {
                scope.descriptionDossierTransaction =  function (object) {
                    var languageColumn = Language.getActiveColumn();
                    var profiles = '';
                    if(object.transactionType){
                        profiles += object.transactionType[languageColumn];

                        angular.forEach(object.processProfiles, function(value){
                            profiles += ", "+value.profile[languageColumn];
                        });
                    }else{
                        profiles += object['descriptionLanguage'+languageColumn[languageColumn.length - 1]];
                    }
                    if(object.dossier && object.dossier.subject){
                        profiles +=  ": "+object.dossier.subject;
                    }
                    return profiles;
                }
                element.html(getTemplate(scope)).show();
                $compile(element.contents())(scope);
            }
        }
    }])
    .directive('annexaBoxTaskTask',['$filter','$compile', 'HelperService', '$rootScope', 'globalModals', 'AnnexaFormlyFactory', 'TaskFactory', 'AnnexaModalFactory', 'RestService', function ($filter, $compile, HelperService, $rootScope, globalModals, AnnexaFormlyFactory, TaskFactory, AnnexaModalFactory, RestService) {
        var getTemplate = function(scope) {
            var template = '';
            template += '<div ng-if="tasks.length==0" class="text-center cursiva-grey" translate="global.literals.empty_tasks_register">Add</div>';
            template += '<div ng-if="tasks.length>0" class="scroll-x p-b">';
            template += '   <table class="wi-100 m-b  b-b tableWidthGreenButtons">';
            template += '       <thead >';
            template += '           <tr class=" m-b-xs _600 b-b hidden-md-down">';
            template += '               <th class="p-b-sm"><span class="text-primary" translate="global.literals.days">days</span> / <span class="text-danger" translate="global.literals.state">state</span></th>';
            template += '               <th class="p-b-sm"><span translate="global.literals.task_name">name</span></th>';
            template += '               <th class="p-b-sm"><span translate="global.literals.created_for">started</span></th>';
            template += '               <th class="p-b-sm"><span translate="global.customFields.DATETIME">start date</span></th>';
            template += '               <th class="p-b-sm"><span translate="global.literals.assign_to">assign</span></th>';
            template += '               <th class="p-b-sm text-center"><span translate="global.literals.actions">actions</span></th>';
            template += '           </tr>';
            template += '       </thead>';
            template += '       <tbody>';
            template += '           <tr class=" ng-class:{\'m-b-p-b-sm-b-b\' : !$last} " ng-repeat="task in tasks">';
            template += '               <td >';
            template += '                   <i ng-if="task.endedTask && task.finishOk" class="fa fa-check text-success"></i>';
            template += '                   <i ng-if="task.endedTask && !task.finishOk" class="fa fa-times-circle fa-lg text-danger"></i>';
            template += '                   <span ng-if="!task.endedTask && task.startDate"  class="label ng-class:getClass(task)">{{getLabel(task)}}</span>';
            template += '               </td>';
            template += '               <td>';
            template += '                   {{task.description}}';
            template += '               </td>';
            template += '               <td>';
            template += '                   {{task.createdUser.name + " " + task.createdUser.surename1}}';
            template += '               </td>';
            template += '               <td>';
            template += '                   {{task.createdDate | date:\'dd/MM/yyyy\'}}';
            template += '               </td>';
            template += '               <td >';
            template += '                   {{task.user.name + " " + task.user.surename1}}';
            template += '               </td>';
            template += '               <td class="p-y-sm text-center ">';
            template += '                   <div class="flexbuttons btn-group btn-group-sm ng-class: { \'dropdown\' : !task.startDate && !task.endedTask && !task.endDate }" ng-if="task.id ">';
            template += '                       <a class="btn primary" ui-sref="annexa.tasks.assignedTasks.edit({ task: task.id})" target="_blank">';
            template += '                           <i class="fa fa-eye" aria-hidden="true"></i>';
            template += '                           <span translate="global.literals.see">Show</span>';
            template += '                       </a>';
            template += '                   </div>';
            template += '                   <div class="btn-group ng-class: { \'dropdown\' : !task.startDate && !task.endedTask && !task.endDate }" ng-if="!task.id">';
            template += '                       <button ng-if="deleteFunction" class="md-btn p-a-0 md-flat md-btn__star grey-pencil" ng-click="deleteFunction(task.id, $index)"><i class="fa fa-remove" title="{{ \'global.literals.remove\' | translate}}" aria-hidden="true"></i><span class="sr-only">{{ \'global.literals.remove\' | translate}}</span></button>'
            template += '                   </div>';
            template += '               </td>';
            template += '           </tr>';
            template += '       </tbody>';
            template += '   </table>';
            template += '</div>';

            return template;
        };

        var getMaxDays = function(task, $rootScope){
            var holidays = [];
            var workinDays = 0;
            if($rootScope && $rootScope.LoggedUser && $rootScope.LoggedUser.entity && $rootScope.LoggedUser.entity.holidays){
                holidays = $rootScope.LoggedUser.entity.holidays;
            }
            workinDays = HelperService.getWorkingDays(new Date(task.createdDate),new Date(), holidays);
            return task.days - workinDays;
        }
        return {
            restrict: 'E',
            replace: true,
            scope: {
                tasks: '=',
                deleteFunction: '=',
                isManage: '=',
                isOpenTask: '='
            },
            link: function (scope, element, attr) {
                scope.getClass = function(task) {
                    return HelperService.getWorkingDaysClass(getMaxDays(task, $rootScope));
                };
                scope.actual = false;
                if(scope.deleteFunction){
                    scope.actual = true;
                }

                scope.searchTasks = function (value) {
                    var valueOk = value;
                    if(valueOk && valueOk.val){
                        valueOk = valueOk.val;
                    }
                    if(!valueOk) {
                        return [];
                    }else if(valueOk != '*' && valueOk.length < 3){
                        return [];
                    }else {
                        if(valueOk == '*'){
                            valueOk = '';
                        }
                        return RestService.loadData('common', 'User', valueOk).then(function (dataSearch) {
                            var usersLoad = [];
                            if (dataSearch.data && dataSearch.data.content && dataSearch.data.content.length > 0) {
                                angular.forEach(JSOG.decode(dataSearch.data.content), function (val, key) {
                                    var name = val.name + " " + val.surename1;
                                    if (val.surename2) {
                                        name = name + " " + val.surename2;
                                    }
                                    usersLoad.push({'id': val.id, 'user': val, 'value': name});
                                });
                            }
                            return usersLoad;
                        }).catch(function () {
                            return [];
                        })
                    }
                }

                scope.getLabel = function(task) {
                    var maxDays = getMaxDays(task, $rootScope);
                    if(maxDays > 5){
                        return "+"+maxDays;
                    }else{
                        return maxDays;
                    }
                };

                element.html(getTemplate(scope)).show();
                $compile(element.contents())(scope);
            }
        }
    }]);