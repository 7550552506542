/**
 * Created by dpardell on 09/11/2016.
 */
angular.module('annexaApp')
    .directive('documentFileOrTemplate',['$compile', '$rootScope', 'HelperService', function($compile, $rootScope, HelperService) {
        var getTemplate = function(enabledField, languages, labelFile, labelTemplate, scope) {
            var template = '';
            template += '<div class="row" >';
            template += '   <div class="col-md-12">';
            template += '       <ul class="nav nav-tabs annexaTabs">';
            template += '           <li class=" ng-class: {\'active\' : ngModel[inputType] == keyFile}" ng-show="ngModel.showFile">';
            template += '               <div class="radio">';
            template += '                   <span class="ui-check">';
            template += '                       <input tabindex="0" type="radio" name="radioType" id="fileOption1" value="{{keyFile}}" ng-model="ngModel[inputType]" ng-checked="ngModel[inputType] == keyFile" ng-disabled="disabled"/>';
            template += '                       <i class="dark-white"></i>';
            template += '                   </span>';
            template += '                   <label for="fileOption1" translate="'+labelFile+'">'+labelFile+'</label>';
            template += '               </div>';
            template += '           </li>';
            template += '           <li class=" ng-class: {\'active\' : ngModel[inputType] == keyTemplate}"  ng-show="ngModel.showTemplate">';
            template += '               <div class="radio">';
            template += '                   <span class="ui-check">';
            template += '                       <input tabindex="0"  type="radio" name="radioType" id="fileOption2" value="{{keyTemplate}}" ng-model="ngModel[inputType]" ng-checked="ngModel[inputType] == keyTemplate" ng-disabled="disabled"/>';
            template += '                       <i class="dark-white"></i>';
            template += '                   </span>';
            template += '                   <label for="fileOption2" translate="'+labelTemplate+'">'+labelTemplate+'</label>';
            template += '               </div>';
            template += '           </li>';
            template += '       </ul>';
            template += '   </div>';
            template += '</div>';
            template += '<div class="p-x-9375" >';
            template += '   <div class="row tab-panel" id="listCircuitsPredef" ng-show="ngModel[inputType] == keyFile && ngModel.showFile">';
            template += '       <label for="newDoc-{{keyFile}}" class="sr-only">{{ \'global.literals.SelectUploadFile\' | translate }}</label>';
            template += '       <input type="file" ng-model="ngModel[keyFile]" class="form-control" id="newDoc-{{keyFile}}" name="{{keyFile}}" accept="{{options.data.accept}}" onchange="angular.element(this).scope().onSelected(this)" ng-disabled="disabled">';
            template += '   </div>';
            template += '   <div class="row tab-panel" id="listCircuitsPerso" ng-show="ngModel[inputType] == keyTemplate && ngModel.showTemplate">';
            template += '       <ui-tree-select ng-model="ngModel[keyTemplate]" data="data" required="required" only-last-level-click="onlyLastLevelClick" change="change" disabled="disabled" extra-change="fieldOptions"></ui-tree-select>';
            template += '   </div>';
            template += '</div>';

            return template;
        };

        return {
            restrict: 'E',
            replace: true,
            scope: {
                ngModel: '=',
                data: '=',
                change: '=',
                required: '=',
                onlyLastLevelClick: '=',
                changeFilter: '&',
                keyFile:'=',
                keyTemplate:'=',
                types:'=',
                inputType:'=',
                onSelected:'=',
                fieldOptions: '='
            },
            link: function(scope, element, attr) {
                element.html(getTemplate(attr['enabledField'], $rootScope.app.languagedef, attr['labelFile'], attr['labelTemplate'], scope)).show();
                scope.disabled = ((scope.fieldOptions && scope.fieldOptions.templateOptions && scope.fieldOptions.templateOptions.disabled)?true:false);
                $compile(element.contents())(scope);
            }
        }
    }])
    .directive('annexaBoxTaskDoc',['$filter','$compile', 'HelperService', '$rootScope', 'globalModals', 'AnnexaFormlyFactory', 'TaskFactory', 'AnnexaModalFactory', 'RestService', function ($filter, $compile, HelperService, $rootScope, globalModals, AnnexaFormlyFactory, TaskFactory, AnnexaModalFactory, RestService) {
    var getTemplate = function(scope) {
        var template = '';
        template += '<div ng-if="tasks.length==0" class="text-center cursiva-grey"  translate="global.literals.empty_tasks_document">Add</div>';
        template += '<div ng-if="tasks.length>0" class="scroll-x p-b">';
        template += '   <table class="wi-100 m-b  b-b tableWidthGreenButtons">';
        template += '       <thead >';
        template += '           <tr class=" m-b-xs _600 b-b hidden-md-down">';
        template += '               <th class="p-b-sm"><span class="text-primary" translate="global.literals.days">days</span> / <span class="text-danger" translate="global.literals.state">state</span></th>';
        template += '               <th class="p-b-sm"><span translate="global.literals.task_name">name</span></th>';
        template += '               <th class="p-b-sm"><span translate="global.literals.created_for">started</span></th>';
        template += '               <th class="p-b-sm"><span translate="global.literals.creation_date">start date</span></th>';
        template += '               <th class="p-b-sm"><span translate="global.literals.assign_to">assign</span></th>';
        template += '               <th class="p-b-sm text-center"><span translate="global.literals.actions">actions</span></th>';
        template += '           </tr>';
        template += '       </thead>';
        template += '       <tbody>';
        template += '           <tr class=" ng-class:{\'m-b-p-b-sm-b-b\' : !$last} " ng-repeat="task in tasks">';
        template += '               <td >';
        template += '                   <i ng-if="task.endedTask && task.finishOk" class="fa fa-check text-success"></i>';
        template += '                   <i ng-if="task.endedTask && !task.finishOk" class="fa fa-times-circle fa-lg text-danger"></i>';
        template += '                   <span ng-if="!task.endedTask && task.startDate"  class="label ng-class:getClass(task)">{{getLabel(task)}}</span>';
        template += '               </td>';
        template += '               <td>';
        template += '                   {{task.description}}';
        template += '               </td>';
        template += '               <td>';
        template += '                   {{task.createdUser.name + " " + task.createdUser.surename1}}';
        template += '               </td>';
        template += '               <td>';
        template += '                   {{task.createdDate | date:\'dd/MM/yyyy\'}}';
        template += '               </td>';
        template += '               <td >';
        template += '                   {{task.user.name + " " + task.user.surename1}}';
        template += '               </td>';
        template += '               <td class="p-y-sm text-center ">';
        template += '                   <div class="btn-group ng-class: { \'dropdown\' : !task.startDate && !task.endedTask && !task.endDate }" ng-if="task.id">';
        template += '                       <a class="btn primary" ui-sref="annexa.tasks.assignedTasks.edit({ task: task.id})" target="_blank"">';
        template += '                           <i class="fa fa-eye" aria-hidden="true"></i>';
        template += '                           <span translate="global.literals.see">Show</span>';
        template += '                       </a>';
        template += '                       <button ng-if="!task.startDate && !task.endedTask && !task.endDate" title="{{ \'global.literals.showOptions\' | translate }}" class="btn primary dropdown-toggle" data-toggle="dropdown"><span class="sr-only">{{ \'global.literals.showOptions\' | translate }}</span></button>';
        template += '                       <div ng-if="!task.startDate && !task.endedTask && !task.endDate" class="dropdown-menu primary pull-right">';
        template += '                           <a class="dropdown-item" ng-click="rejectTask(task.id)">';
        template += '                               <span translate="global.literals.rejectTask">Edit Online</span>';
        template += '                           </a>';
        template += '                       </div>';
        template += '                   </div>';
        template += '                   <div class="btn-group ng-class: { \'dropdown\' : !task.startDate && !task.endedTask && !task.endDate }" ng-if="!task.id">';
        template += '                       <annexa-delete-button class="pull-right" delete-function="deleteFunction" delete-object="task" delete-object-index="$index" actual="true"></annexa-delete-button>';
        template += '                   </div>';
        template += '               </td>';
        template += '           </tr>';
        template += '       </tbody>';
        template += '   </table>';
        template += '</div>';
        return template;
    };

    var getMaxDays = function(task, $rootScope){
        var holidays = [];
        var workinDays = 0;
        if($rootScope && $rootScope.LoggedUser && $rootScope.LoggedUser.entity && $rootScope.LoggedUser.entity.holidays){
            holidays = $rootScope.LoggedUser.entity.holidays;
        }
        workinDays = HelperService.getWorkingDays(new Date(task.createdDate),new Date(), holidays);
        return task.days - workinDays;
    }
    return {
        restrict: 'E',
        replace: true,
        scope: {
            document: '=',
            tasks: '=',
            deleteFunction: '=',
            permissions: '='
        },
        link: function (scope, element, attr) {
            scope.getClass = function(task) {
                return HelperService.getWorkingDaysClass(getMaxDays(task, $rootScope));
            };
            scope.actual = false;
            if(scope.deleteFunction){
                scope.actual = true;
            }

            scope.rejectTask = function(data){
                if(scope.tasks && scope.tasks.length > 0){
                    var aux = HelperService.findFirstElementWhere(scope.tasks, 'id', data, -1);
                    var modal = angular.copy(globalModals.taskUpdateComment);
                    modal.title = 'global.literals.rejectTask';
                    modal.annexaFormly.model = {};
                    modal.annexaFormly.model.modal_body = {task:aux};
                    modal.annexaFormly.options = {};
                    modal.annexaFormly.options.formState = {readOnly: false};
                    modal.annexaFormly.fields[0].fieldGroup[0].templateOptions.placeholder = $filter('translate')('global.literals.commentReject');
                    modal.annexaFormly.fields[0].fieldGroup[0].templateOptions.required = true;
                    var rejectTaskComplete = function(){
                        if(modal.annexaFormly.model.modal_body.task && modal.annexaFormly.model.modal_body.task.comments){
                            modal.annexaFormly.model.modal_body.task.endedTask = true;
                            modal.annexaFormly.model.modal_body.task.endDate = new Date();
                            modal.annexaFormly.model.modal_body.task.finishOk = false;

                            TaskFactory.updateTask(modal.annexaFormly.model.modal_body.task, './api/tasks/rejectTask').then(function(data){
                                modal.close();
                            }).catch(function(msg, error){
                                modal.close();
                            });
                        }
                    };
                    AnnexaFormlyFactory.showModal("modalUpdateComentTask", modal, rejectTaskComplete, false);
                }
            }

            scope.getLabel = function(task) {
                var maxDays = getMaxDays(task, $rootScope);
                if(maxDays > 5){
                    return "+"+maxDays;
                }else{
                    return maxDays;
                }
            };

            element.html(getTemplate(scope)).show();
            $compile(element.contents())(scope);
        }
    }
}]);