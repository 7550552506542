/**
 * Created by osirvent on 27/01/2017.
 */
angular.module('annexaApp')
    .factory('ABMModelFactory', ['$rootScope', 'apiAdmin', 'AdminFactory', '$translate', 'Language', 'CommonService', function($rootScope, apiAdmin, AdminFactory, $translate, Language, CommonService) {
        var factory = {};

        factory.scope = undefined;

        factory.AT_GUIDED = 'GUIDED';
        factory.AT_NONGUIDED = 'NONGUIDED';
        factory.AT_INHERITED = 'INHERITED';
        factory.TT_NORMAL = 'NORMAL';
        factory.TT_FINAL = 'FINAL';

        //region General

        factory.setScope = function (scope) {
            factory.scope = scope;
        };

        factory.getOptions = function() {

            return {
                showSubject: apiAdmin.showSubjectList,
                family: AdminFactory.familyProcedures,
                archiveClassification: AdminFactory.archiveClassificationsTree,
                showClassification: apiAdmin.showClassificationList,
                procedureStartProfiles: AdminFactory.profiles,
                procedureViewProfiles: AdminFactory.profiles,
                procedureResponsibleProfiles: AdminFactory.profiles,
                allowThirds: apiAdmin.yesNo,
                allowExpedientRelations: apiAdmin.yesNo,
                allowUserChangeMaxDays: apiAdmin.yesNo,
                daysComputeGlobal: apiAdmin.yesNo,
                showEndProcess: apiAdmin.yesNo,
                userInitialStateInheritance: apiAdmin.yesNo,
                processProfiles: AdminFactory.profiles,
                //viewProfiles: AdminFactory.profiles,
                assignationType: [ { id: factory.AT_GUIDED, description: 'global.literals.typeGuided' }, { id: factory.AT_NONGUIDED, description: 'global.literals.typeNonGuided' } ],
                assignationTypeTram:[{ id: factory.AT_GUIDED, description: 'global.literals.typeGuidedTram' }, { id: factory.AT_INHERITED, description: 'global.literals.typeInherited' }, { id: factory.AT_NONGUIDED, description: 'global.literals.typeUnguidedTram' }],
                tramitationType: [ { id: factory.TT_NORMAL, description: 'global.literals.no' }, { id: factory.TT_FINAL, description: 'global.literals.yes' } ],
                //tramitationTypeProcedure:AdminFactory.tramitationTypes,
                transactionType:AdminFactory.transactionTypesOrdinary,
                active: apiAdmin.yesNo,
                userStateInheritance:[],
                procedureType: [
                    { id: 'PROCEDURE', description: 'global.literals.PROCEDURE' },
                    { id: 'SUBPROCEDURE', description: 'global.literals.SUBPROCEDURE' },
                    { id: 'PROCEDURE_SUBPROCEDURE', description: 'global.literals.PROCEDURE_SUBPROCEDURE' }
                ],
                subprocedure: AdminFactory.subprocedures
            };
        };

            factory.getOptionsAdd = function() {
            return {
                procedureClassifications: ''
            };
        };

        factory.resetModel = function () {
            factory.scope.model = factory.InitializeModel();
        };

        //endregion

        //region Procedure

        factory.InitializeModel = function() {
            return {
                language1: '',
                language2: '',
                language3: '',
                descriptionLanguage1: '',
                descriptionLanguage2: '',
                descriptionLanguage3: '',
                maxDays: 1,
                acronym: '',
                family: {},
                showSubject: 'DISABLED',
                showClassification: 'DISABLED',
                active: 'NO',
                guided: true,
                procedureViewProfiles: [],
                procedureStartProfiles: [],
                procedureClassifications: [],
                transactions: [],
                informationLanguage1: '',
                informationLanguage2: '',
                informationLanguage3: '',
                archiveClassification: {},
                graphJSON: '',
                procedureType: 'PROCEDURE',
                procedureResponsibleProfiles: [],
                allowUserChangeMaxDays: 'NO',
                tramitationTypeProcedure: $rootScope.app.configuration.default_tramitation_type.value
            };
        };

        //endregion

        //region Transaction

        factory.getAssignationType = function (cell) {
            if(factory.scope.model && factory.scope.model.transactions && factory.scope.model.transactions[cell.id]) {
                return factory.scope.model.transactions[cell.id].assignationType;
            } else {
                if(cell.get('type') == 'event-sub-process') {
                    return factory.AT_NONGUIDED;
                } else {
                    return factory.AT_GUIDED;
                }
            }
        };

        factory.getTramitationType = function (cell, graphSearch) {
            if(graphSearch == undefined) {
                graphSearch = false;
            }

            if(graphSearch) {
                if (cell.attributes.attrs['.outer']['stroke-width'] == 'none' || cell.attributes.attrs['.outer']['stroke-width'] == 1) {
                    return factory.TT_NORMAL;
                } else {
                    return factory.TT_FINAL;
                }
            } else {
                if (factory.scope.model && factory.scope.model.transactions && factory.scope.model.transactions[cell.id]) {
                    return factory.scope.model.transactions[cell.id].tramitationType;
                } else {
                    if (cell.attributes.attrs['.outer']['stroke-width'] == 'none' || cell.attributes.attrs['.outer']['stroke-width'] == 1) {
                        return factory.TT_NORMAL;
                    } else {
                        return factory.TT_FINAL;
                    }
                }
            }
        };

        factory.setTransactionModel = function(cell, property, value) {
            factory.scope.model.transactions[cell.id][property] = value;
        };

        factory.getTransactionModel = function(cell, property) {
            return factory.scope.model.transactions[cell.id][property];
        };

        factory.InitializeTransaction = function(noGudied, cell, shapeType) {
            var transaction = {
                language1: '',
                language2: '',
                language3: '',
                descriptionLanguage1: '',
                descriptionLanguage2: '',
                descriptionLanguage3: '',
                informationLanguage1: '',
                informationLanguage2: '',
                informationLanguage3: '',
                maxDays: 1,
                allowThirds: 'NO',
                allowExpedientRelations: 'NO',
                daysComputeGlobal: 'YES',
                showEndProcess: 'NO',
                userInitialStateInheritance: 'NO',
                processByProfile: false,
                customFields: [],
                processProfiles: [],
                //viewProfiles: [],
                assignationType: 'GUIDED',
                tramitationType: 'NORMAL',
                graphNodeId: '',
                graphNodeType: '',
                subprocedure: '',
                transactionType:''
            }

            if(noGudied) {
                transaction.daysComputeGlobal= 'NO';
                transaction.type = 'NONGUIDED';
                transaction.processByProfile = true;
            }

            if(cell) {
                transaction.graphNodeId = cell.id;
                transaction.graphNodeType = shapeType;
            }

            return transaction;
        }

        factory.setStartTransaction = function(cell, shapeType) {
            var gatewayId = CommonService.guid();
            cell.set('gatewayId', gatewayId);
            cell.set('gatewayPath', { gatewayId: gatewayId, path: 1 });
            factory.scope.model.transactions[cell.id] = factory.InitializeTransaction(false, cell, shapeType);
            factory.setTransactionModel(cell, 'tramitationType', 'INITIAL');

            Language.getAllTranslations('global.bpm.event.start')
                .then(function(data) {
                    factory.setTransactionModel(cell, 'language1', data.language1);
                    factory.setTransactionModel(cell, 'language2', data.language2);
                    factory.setTransactionModel(cell, 'language3', data.language3);
                }).catch(function(data) {
                    factory.setTransactionModel(cell, 'language1', data.language1);
                    factory.setTransactionModel(cell, 'language2', data.language2);
                    factory.setTransactionModel(cell, 'language3', data.language3);
            });
        }

        factory.sanitizeModel = function(){
            if(factory.scope.model.transactions){
                var trans = factory.scope.model.transactions;
                _.forOwn(trans, function(value, key) {
                    if(!_.contains(['SubprocessNormal', 'SubprocessFreeNormal'], value.graphNodeType)) {
                        if (!value.hasOwnProperty('language1')) {
                            delete factory.scope.model.transactions[key];
                        }
                    }else if(_.contains(['SubprocessFreeNormal'], value.graphNodeType)) {
                        if (!value.hasOwnProperty('subprocedureName1')) {
                            delete factory.scope.model.transactions[key];
                        }
                    } else {
                        if (!value.hasOwnProperty('subprocedure')) {
                            delete factory.scope.model.transactions[key];
                        }
                    }
                });
            }
        }
        //endregion

        return factory;
    }]);