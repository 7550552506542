/**
 * Created by osirvent on 27/09/2016.
 */
angular.module('annexaApp')
    .directive('annexaEntity',['$compile', '$timeout', '$state', '$rootScope', function ($compile, $timeout, $state, $rootScope) {
        var getFormTemplate = function () {
            var template = '';

            template += '<div id="{{entityDefinition.id}}" class="container-fluid">';
            template += '   <div uib-alert ng-repeat="alert in entityDefinition.form.alerts" class="alert-danger p-y-xs m-t" close="closeAlert($index)">{{alert.msg | translate}}</div>'
            template += '   <div class="row m-t">';
            template += '       <div class="col-xs-12 col-md-6">';
            template += '           <div class="box ">';
            template += '               <div class="row">';
            template += '                   <div class="col-xs-12">';
            template += '                       <div class="box-header clearfix" ng-if="entityDefinition.title">';
            template += '                           <h3 class="annexa-box-header" translate="{{entityDefinition.title}}">{{entityDefinition.title | translate}}</h3>'
            template += '                       </div>';
            template += '                   </div>';
            template += '               </div>';
            template += '               <div class="row">';
            template += '                   <div class="col-xs-12">';
            template += '                       <form id="{{entityDefinition.id + \'Form\'}}" class="p-a p-b-md" name="{{entityDefinition.id + \'Form\'}}" ng-submit="entityDefinition.form.submit()" novalidate>';
            template += '                           <formly-form model="entityDefinition.form.model" fields="entityDefinition.form.fields" options="entityDefinition.form.options" form="entityDefinition.form.form" class="compact-group-sm">';
            template += '                               <button type="submit" class="btn btn-primary submit-button sr-only" id="{{\'smEntityForm\' + entityDefinition.id}}">Submit</button>';
            template += '                           </formly-form>';
            template += '                       </form>';
            template += '                   </div>';
            template += '               </div>';
            template += '           </div>';
            template += '       </div>';
            template += '       <div class="col-xs-12 col-md-6">';
            template += '           <div class="row" ng-repeat="box in entityDefinition.boxes">';
            template += '               <annexa-box ng-if="!box.origin" definition="box.boxDefinition" class="col-xs-12">';
            template += '                   <annexa-entity-render-box definition="definition" type="{{definition.renderType}}"/>';
            template += '               </annexa-box>';
            template += '               <annexa-box-registerentry class="col-xs-12" ng-if="box.origin && box.origin ==\'register-entry\'" ';
            template += '                   box-title="{{box.boxTitle}}" ';
            template += '                   permissions="box.permissions" ';
            template += '                   search="box.search" ';
            template += '                   new="box.new" ';
            template += '                   content="box.content" ';
            template += '                   empty-text="global.reg.literals.empty_registerEntries" ';
            template += '                   is-edit="false" ';
            template += '                   entry-type="{{box.entryType}}" ';
            template += '                   config="box.config" ';
            template += '                   search-by-entry-type="box.searchByEntryType" ';
            template += '                   origin="{{box.origin}}">';
            template += '               </annexa-box-registerentry>';
            template += '           </div>';
            template += '       </div>';
            template += '   </div>';
            template += '</div>';

            return template;
        }

        var getModalTemplate = function () {
            var template = '';

            template += '<div class="modal-dialog ng-class:entityDefinition.modal.size;">';
            template += '   <div class="modal-content">';
            template += '       <div class="modal-header">';
            template += '           <div class="row">';
            template += '               <div class="col-sm-10">';
            template += '                   <h2 class="modal-title" tabindex="0">';
            template += '                       <i class="entityDefinition.modal.icon" ng-if="entityDefinition.modal.icon" aria-hidden="true"></i> ';
            template += '                       <span translate="{{entityDefinition.modal.title}}">{{entityDefinition.modal.title}}</span>';
            template += '                   </h2>';
            template += '               </div>';
            template += '           </div>';
            template += '       </div>';
            template += '       <div class="modal-body">';
            template += '           <div uib-alert ng-repeat="alert in entityDefinition.modal.alerts" class="alert-danger p-y-xs" close="closeAlert($index)">{{alert.msg | translate}}</div>'
            template += '           <form id="{{entityDefinition.id}} " class="compact-group" name="{{entityDefinition.id}}" ng-submit="entityDefinition.form.submit()" novalidate>';
            template += '               <formly-form model="entityDefinition.form.model" fields="entityDefinition.form.fields" options="entityDefinition.form.options" form="entityDefinition.form.form">';
            template += '                   <button type="submit" class="btn btn-primary submit-button" id="{{\'smEntityForm\' + entityDefinition.id}}" style="display: none;">Submit</button>';
            template += '               </formly-form>';
            template += '           </form>';
            template += '           <div class="p-a grey-100 overflow-y-60" ng-if="entityDefinition.boxes.length != 0">';
            template += '               <div class="row" ng-repeat="box in entityDefinition.boxes">';
            template += '                   <annexa-box ng-if="!box.origin" definition="box.boxDefinition" class="col-xs-12">';
            template += '                       <annexa-entity-render-box definition="definition" type="{{definition.renderType}}"/>';
            template += '                   </annexa-box>';
            template += '                   <annexa-box-registerentry ng-if="box.origin && box.origin ==\'register-entry\'" ';
            template += '                       box-title="{{box.boxTitle}}" ';
            template += '                       permissions="box.permissions" ';
            template += '                       search="box.search" ';
            template += '                       new="box.new" ';
            template += '                       content="box.content" ';
            template += '                       empty-text="global.reg.literals.empty_registerEntries" ';
            template += '                       is-edit="false" ';
            template += '                       entry-type="{{box.entryType}}" ';
            template += '                       config="box.config" ';
            template += '                       search-by-entry-type="box.searchByEntryType" ';
            template += '                       origin="{{box.origin}}">';
            template += '                   </annexa-box-registerentry>';
            template += '               </div>';
            template += '           </div>'; // /Ximixangas
            template += '       </div>';
            template += '       <div class=modal-footer>';
            //if(!hideSubmitButton){
            template += '           <button type="button" class="btn btn-sm primary" ng-click="saveEntity()"><i class="fa fa-floppy-o" aria-hidden="true"></i> <span translate="global.literals.save">Save</span></button>';
            //}
            template += '           <button type="button" class="btn btn-sm grey-500  text-white" data-dismiss="modal" ng-click="entityDefinition.modal.close();"><span translate="global.literals.cancel">Cancel</span></button>';
            template += '       </div>';
            /*if(showdebuginfo) {
                template += '       <h2>Form Data</h2>';
                template += '       <pre>{{modal.annexaFormly.model | json}}</pre>';
                template += '       <h2>Fields <small>(note, functions are not shown)</small></h2>';
                template += '       <pre>{{modal.annexaFormly.originalFields | json}}</pre>';
                template += '       <h2>Form</h2>';
                template += '       <pre>{{modal.annexaFormly.form | json}}</pre>';
            }*/
            template += '   </div>';
            template += '</div>';

            return template;

        }

        return {
            restrict: 'E',
            replace: true,
            scope: {
                entityDefinition: '=',
            },
            link: function (scope, element, attr) {
                switch(scope.entityDefinition.type) {
                    case 'form':
                        element.html(getFormTemplate()).show();
                        break;
                    case 'modal':
                        element.html(getModalTemplate()).show();
                        break;
                    default:
                        element.html('').show();
                        break;
                }

                scope.saveEntity = function() {
                    scope.entityDefinition.form.extra = scope.entityDefinition.boxes;

                    $timeout(function () {
                        angular.element('#smEntityForm' + scope.entityDefinition.id).trigger('click');
                    })
                };

                scope.cancelEntity = function() {
                    if(scope.entityDefinition.cancelState) {
                        $state.transitionTo(scope.entityDefinition.cancelState);
                    }
                };

                scope.closeAlert = function(index) {
                    if(scope.modal && scope.modal.alerts) {
                        scope.modal.alerts.splice(index, 1);
                    }
                }
                if(scope.entityDefinition && scope.entityDefinition.disabledFunction){
                    scope.disabledFunction = scope.entityDefinition.disabledFunction;
                }
                $compile(element.contents())(scope);
            }
        }
    }])
    .directive('annexaEntityRenderBox',['$compile', function ($compile) {
        var getTemplateDocumentThird = function () {
            return '<annexa-box-documents-third documents="definition.content" delete-function="definition.deleteFunction"/>';
        }

        var getTemplateContactThird = function () {
            return '<annexa-box-contacts-third contacts="definition.content" delete-function="definition.deleteFunction" default-function="definition.defaultFunction" permissions="definition.rederPermissions" can-edit-third="definition.canEditThird"/>';
        }

        var getTemplateConsentThird = function () {
            return '<annexa-box-consents-third consents="definition.content" delete-function="definition.deleteFunction" permissions="definition.rederPermissions"/>'
        }

        var getTemplateReceiverThird = function () {
            return '<annexa-box-receivers-third receivers="definition.content" delete-function="definition.deleteFunction" />';
        }

        var getTemplateDossierThird = function () {
            return '<annexa-box-dossier-third receivers="definition.content" delete-function="definition.deleteFunction" />';
        }

        var getTemplateRegisterEntryThird = function () {
            return '<annexa-box-register-entries-third receivers="definition.content" delete-function="definition.deleteFunction" />';
        }

        var getTemplateDossierTram = function () {
            return '<annexa-box-dossiers dossiers="definition.content" delete-function="definition.deleteFunction" />';
        }

        var getTemplateRegisterEntryTram = function (type) {
            return '<annexa-box-register-entries register-entries="definition.content" delete-function="definition.deleteFunction" type="' + type + '" />';
        }

        var getTemplateReceiverTram = function () {
            return '<annexa-box-receivers receivers="definition.content" edit-function="definition.editFunction" delete-function="definition.deleteFunction" set-principal="definition.setPrincipal"/>';
        }

        var getTemplateDocumentTram = function () {
            return '<annexa-box-documents-tram documents="definition.content" delete-function="definition.deleteFunction" actual-transaction="definition.actualTransaction"/>';
        }

        var getTemplateDossierTransactionTask = function (type) {
            return '<annexa-box-dossier-transactions-task dossier-transactions="definition.content" delete-function="definition.deleteFunction" />';
        }

        var getTemplateRegisterEntryTask = function (type) {
            if(type == 'OUTPUT') {
                return '<annexa-box-register-entries-task-output register-entries="definition.content" delete-function="definition.deleteFunction" type="' + type + '" />';
            }else{
                return '<annexa-box-register-entries-task-input register-entries="definition.content" delete-function="definition.deleteFunction" type="' + type + '" />';
            }
        }

        var getTemplateDocumentTask = function () {
            return '<annexa-box-documents-task documents="definition.content" delete-function="definition.deleteFunction"/>';
        }

        var getTemplateCommentTask = function () {
            return '<annexa-box-comment-task comments="definition.content" delete-function="definition.deleteFunction"/>';
        }

        var getTemplateTaskTask = function () {
            return '<annexa-box-task-task tasks="definition.content" delete-function="definition.deleteFunction"/>';
        }

        return {
            restrict: 'E',
            replace: true,
            scope: {
                definition: '=',
                type: '@'
            },
            link: function (scope, element, attr) {
                switch (scope.type) {
                    case 'document-third':
                        element.html(getTemplateDocumentThird()).show();
                        break;
                    case 'contact-third':
                        element.html(getTemplateContactThird()).show();
                        break;
                    case 'consent-third':
                        element.html(getTemplateConsentThird()).show();
                        break;
                    case 'receiver-third':
                        element.html(getTemplateReceiverThird()).show();
                        break;
                    case 'dossier-third':
                        element.html(getTemplateDossierThird()).show();
                        break;
                    case 'registerEntry-third':
                        element.html(getTemplateRegisterEntryThird()).show();
                        break;
                    case 'receiver-tram':
                        element.html(getTemplateReceiverTram()).show();
                        break;
                    case 'dossier-tram':
                        element.html(getTemplateDossierTram()).show();
                        break;
                    case 'registerEntry-tram-input':
                        element.html(getTemplateRegisterEntryTram('INPUT')).show();
                        break;
                    case 'registerEntry-tram-output':
                        element.html(getTemplateRegisterEntryTram('OUTPUT')).show();
                        break;
                    case 'document-tram':
                        element.html(getTemplateDocumentTram()).show();
                        break;
                    case 'registerEntry-task-input':
                        element.html(getTemplateRegisterEntryTask('INPUT')).show();
                        break;
                    case 'registerEntry-task-output':
                        element.html(getTemplateRegisterEntryTask('OUTPUT')).show();
                        break;
                    case 'document-task':
                        element.html(getTemplateDocumentTask()).show();
                        break;
                    case 'comment-task':
                        element.html(getTemplateCommentTask()).show();
                        break;
                    case 'dossierTransaction-task':
                        element.html(getTemplateDossierTransactionTask()).show();
                        break;
                    case 'task-task':
                        element.html(getTemplateTaskTask()).show();
                        break;
                    default:
                        element.html('').show();
                        break;
                }

                $compile(element.contents())(scope);
            }
        }
    }]);