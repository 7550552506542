/**
 * Created by osirvent on 15/02/2016.
 */
angular
    .module('annexaApp')
    .service('RestService', ['$q', '$http', '$filter', 'Language', function($q, $http, $filter, Language) {
        this.getDataJson = function(url,filter) {
            var httpPromise = $http({
                url: url,
                method: "GET",
                params: filter
            }).success(function (response) {
                return angular.toJson(response,true);
            }).error(function(error){
                console.log(error);
            });

            return httpPromise;
        };

        this.filterData = function(origin, object_type, filter, aditional_filter,page,size,sort,jsonDepth, callOrigin, noLoading) {
            var request = {
                url: './api/general/filterdata',
                method: 'GET',
                params: {
                    origin: origin,
                    object_type: object_type,
                    filter: angular.fromJson(filter),
                    aditional_filter: aditional_filter,
                    page: page,
                    size: size,
                    order_by:sort,
                    jsonDepth: jsonDepth,
                    callOrigin: callOrigin }
            }

            if(noLoading) {
                request.headers = { noLoading: true }
            }

            var httpPromise = $http(request)
                .success(function(response) {
                    response.content = JSOG.decode(response.content);
                    return response;
                }).error(function(error) {
                    console.log(error);
            });

            return httpPromise;
        }

        this.findOne = function(object_type, id, origin) {
            var httpPromise = $http({
                url: './api/general/findOne',
                method: 'GET',
                params: { object_type: object_type, id:id, origin: origin}
            }).success(function(response) {
                return JSOG.decode(response);
                //response.content = JSOG.decode(response.content);
                //return response;
            }).error(function(error) {
                console.log(error);
                return error;
            });

            return httpPromise;
        }

        this.findAll = function(object_type, origin, cache, headers) {
            var httpParameters = {
                url: './api/general/findAll',
                method: 'GET',
                params: { object_type: object_type, origin: origin}
            }

            if(cache) {
                httpParameters.cache = cache;
            }

            if(headers) {
                httpParameters.headers = headers;
            }

            var httpPromise = $http(httpParameters)
                .success(function(response) {
                    //response.content = JSOG.decode(response.content);
                    return JSOG.decode(response);
                }).error(function(error) {
                    //console.log('Object type: ' + object_type);
                    console.log(error);
                    return error;
            });

            return httpPromise;
        }

        this.query = function (object_type, query, query_parameters) {
            var httpPromise = $http({
                url: './api/general/query',
                method: 'GET',
                params: { object_type: object_type, query: query, query_parameters: query_parameters }
            }).success(function(response) {
                response.content = JSOG.decode(response.content);
                return response;
            }).error(function(error) {
                console.log(error);
                return error;
            });

            return httpPromise;
        }

        this.findAllPaginated = function(object_type, page, size, sort) {
            var httpPromise = $http({
                url: './api/general/findAllPaginated',
                method: 'GET',
                params: { object_type: object_type, page: page, size: size, sort: sort}
            }).success(function(response) {
                response.content = JSOG.decode(response.content);
                return response;
            }).error(function(error) {
                console.log(error);
                return error;
            });

            return httpPromise;
        }

        this.findAllSort = function(object_type, sort) {
            var httpPromise = $http({
                url: './api/general/findAllSort',
                method: 'GET',
                params: { object_type: object_type, sort : sort}
            }).success(function(response) {
                response.content = JSOG.decode(response.content);
                return response;
            }).error(function(error) {
                console.log(error);
                return error;
            });

            return httpPromise;
        }

        this.findByUrl = function(url) {
            var httpPromise = $http({
                url: url,
                method: 'GET'
            }).success(function(response) {
                if(response && response.content) {
                    response.content = JSOG.decode(response.content);
                } else if (response) {
                    response = JSOG.decode(response);
                }
                return response;
            }).error(function(error) {
                console.log(error);
                return error;
            });

            return httpPromise;
        }

        this.insert = function(url, data) {
            var deferred = $q.defer();

            $http({
                url: url,
                method: 'POST',
                data: JSOG.encode(data)
            }).then(function(data) {
                if(data && data.status && (data.status == 500 || data.status == 406 || data.status == 403 || data.status == 404)){
                    deferred.reject(data);
                }else{
                    deferred.resolve(JSOG.decode(data.data));
                }
            }).catch(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }
        
        this.insertMultiPartData = function(url, model, files) {
            var deferred = $q.defer();

            var formData = new FormData();
            if(Array.isArray(files)){
            	_.forEach(files, function(file, index){
            		formData.append('files', file);
            	});
            }else{
            	formData.append('files', files);
            }
            formData.append('model', new Blob([JSON.stringify(model)], {
                type: "application/json"
            }));
            $http({
                method: 'POST',
                url: url,
                headers: {'Content-Type': undefined},
                data: formData,
                transformRequest: function(data, headersGetterFunction) {
                    return data;
                }
            }).success(function (data, status) {
            	deferred.resolve(data);
            }).error(function (msg, code) {
            	deferred.reject(msg);
            });

            return deferred.promise;
        }

        this.update = function(url, data) {
            var deferred = $q.defer();

            $http({
                url: url,
                method: 'PUT',
                data: JSOG.encode(data)
            }).then(function (data) {
                deferred.resolve(JSOG.decode(data.data));
            }).catch(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }
        
        
        this.updateMultiPartData = function(url, model, files) {
            var deferred = $q.defer();
            var formData = new FormData();
            if(Array.isArray(files)){
            	_.forEach(files, function(file, index){
            		formData.append('files', file);
            	});
            }else{
            	formData.append('files', files);
            }
            if(model){
	            formData.append('model', new Blob([JSON.stringify(model)], {
	                type: "application/json"
	            }));
            }
            $http({
                url: url,
                method: 'PUT',
                headers: {'Content-Type': undefined},
                data: formData,
                transformRequest: function(data, headersGetterFunction) {
                    return data;
                }
            }).then(function (data) {
                deferred.resolve(JSOG.decode(data.data));
            }).catch(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }
        
        this.updateRegisterEntryDocumentsProfile = function(idRegisterEntry, idOldProfile, idNewProfile) {
            var deferred = $q.defer();

            $http({
            	url: './api/reg/updateRegisterEntryDocumentsProfile',
                method: 'POST',
                params: { idRegisterEntry: idRegisterEntry, idOldProfile: idOldProfile, idNewProfile: idNewProfile}
            }).then(function (data) {
                deferred.resolve(JSOG.decode(data.data));
            }).catch(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }
       
        this.checkDelete = function(url) {
            var deferred = $q.defer();

            $http({
                url: url,
                method: 'GET',
            }).then(function(data) {
                if(data) {
                    deferred.resolve(data.data);
                } else {
                    deferred.resolve(false);
                }
            }).catch(function(error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        this.delete = function(url) {
            var deferred = $q.defer();

            $http({
                url: url,
                method: 'DELETE'
            }).then(function(data) {
                deferred.resolve(data);
            }).catch(function(error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        this.insertOrUpdate = function(url,data){
            var httpPromise = $http({
                method: 'POST',
                url: url,
                headers: {'Content-Type': 'application/json'},
                params: data
            }).success(function(response) {
                    if(response.content) {
                        response.content = JSOG.decode(response.content);
                    } else{
                        response = JSOG.decode(response);
                    }
                    return response;
                }).error(function(error) {
                console.log(error);
                return error;
            });
            return httpPromise;
        }

        this.loadData = function(origin, object_type, search, langColumn) {
            var params = {
                origin: origin,
                object_type: object_type,
                search: search,
                page: 0,
                size:1000 //numero de resultados a mostrar
            }

            if(origin == 'common' && object_type == 'User') {
                params.langColumn = Language.getActiveColumn();
            }

            var httpPromise = $http({
                url: './api/general/loadData',
                method: 'GET',
                params: params
            }).success(function(response) {
                return response;
            }).error(function(msg,code) {
                console.log(msg);
            });

            return httpPromise;
        }

        this.getUrl = function(key,version) {
            var ret = './api';

            var sKey = key.split(".");

            for(var i = 0; i < sKey.length; i++) {
                ret += '/' + sKey[i];
            }

            return ret;
        }

        this.call = function(url, method, data, params) {
            var deferred = $q.defer();

            if(!url) {
                deferred.reject('No url')
            }

            var httpParams = {
                url: url,
                method: method ? method : 'GET'
            };

            if(data) {
                httpParams.data = JSOG.encode(data);
            }

            if(params) {
                httpParams.params = params;
            }

            $http(httpParams)
                .then(function(data) {
                    deferred.resolve(JSOG.decode(data.data));
                }).catch(function(error) {
                    deferred.reject(error)
            });

            return deferred.promise;
        }

        this.getTranslationsForSend = function () {
            return {
                "third_relation_interested": $filter('translate')('global.literals.interested'),
                "third_relation_representative": $filter('translate')('global.literals.representative'),
                "yes": $filter('translate')('global.literals.yes'),
                "no": $filter('translate')('global.literals.no')
            };
        }
    }]);