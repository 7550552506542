/**
 * Created by aguerrero on 14/04/2016.
 */
angular
    .module('annexaApp')
    .service('SearchBoxService',['$q', '$http','$filter','$state', '$sce', 'TableFilter', 'DTColumnBuilder', 'DTOptionsBuilder', 'ModalAddRepresentation','ModalNewConsent','ModalNewContact', 'Language',
        function ($q, $http, $filter, $state, $sce, TableFilter, DTColumnBuilder, DTOptionsBuilder, ModalAddRepresentation,ModalNewConsent,ModalNewContact, Language) {
            function callbackInstance(json) {};
            return {
                defineTableBoxDataDocuments  : function($scope, $rootScope){
                    //***********************************************************
                    // Definir links per eliminar documents de la taula         *
                    //***********************************************************
                    regDeleteDocDiv = function(data) {
                        var content = '';
                        content = '<div class="dropdown inline"><a class="btn dropdown-toggle" data-toggle="dropdown"><span class="fa fa-ellipsis-v">  </span></a>';
                        content = content + '<div class="dropdown-menu pull-right">';
                        if(data){
                            content = content + '<a href ng-click="deleteFromDiv('+data+', \'doc\', undefined, this);" class="dropdown-item"><span translate="global.literals.remove">Borrar</span></a>';
                        }content = content + '</div></div>';
                        return content;
                    };

                    linkViewDocument = function(data, type, full, meta) {
                        var href = new annexaHref('text-info', 'annexa.doc.documents.view({code: \''+full.id+'\'})', full.name);
                        return href.htmlObj.getHtml();
                    };

                    divDate = function(data, type, full, meta){
                        var content=  '';
                        if(data){
                            content = $filter('date')(data.time, 'dd/MM/yyyy');
                        }
                        return content;
                    };

                    //Definir ESTRUCTURES DE DADES
                    $scope.defDoc = new SearchDataBox('addFromDiv','searchDoc','tableDoc', 'dtDefDoc', 'global.thirds.literals.thirdDocs', 'global.literals.placeholder_search_dossiers','doc','Document', true, true, 'name', 'global.literals.placeholder_search_dossiers','#searchDocumentModal',undefined,'#adjuntarDocumentModal');
                    $scope.searchDoc={search:'', loadObjects:[], objects:[], addObject:[]};
                    $scope.dtDefDoc = new TableData(undefined,undefined,'searchDoc', 'rt<"row"<"col-sm-6"><"col-sm-6"p>>', 'code', [[0, 'desc']]);
                    $scope.tableDoc={
                        dtColumns:[
                            DTColumnBuilder.newColumn('name').withOption('width', '70%').withTitle($filter('translate')('global.literals.title')).renderWith(linkViewDocument),
                            DTColumnBuilder.newColumn('createdDate').withOption('width', '20%').withTitle($filter('translate')('global.literals.title')).renderWith(divDate),
                            DTColumnBuilder.newColumn('id').withOption('width', '10%').renderWith(regDeleteDocDiv)
                        ],
                        reloadInternalData:function(resetPaging){
                            this.table.reloadData(callbackInstance,resetPaging);
                        },
                        reloadInternalDataAndAdd:function(resetPaging, id){
                            $scope.addFromDiv(id, 'Document', $scope);
                        }
                    };

                    //*******************************************
                    // Funció per crear les icones de la taula  *
                    //*******************************************
                    typeSearchDocumentsImg = function(data, type, full, meta) {
                        var content = '';
                        if (full.type){
                            if (full.type.style) {
                                content = '<circleicon bgcolor="' + full.type.style + ' " >' + full.type.acronym + '</circleicon>';
                            } else {
                                content = '<icon name="' + full.type.acronym + '">';
                            }
                        }
                        return content;
                    };

                    //*******************************
                    // Funció per cercar per codi   *
                    //*******************************
                    codeSearchDocuments = function(data, type, full, meta) {
                        var content = '';
                        var code = full.id;
                        if(full.code != null && full.code != undefined) {
                            content = full.code.substr((full.code.indexOf(full.id) - 2), full.id.toString().length + 2);
                        }
                        return content;
                    };

                    // Funció per afegir contingut a la taula
                    actionButtonsSearchDocumentsHtml = function(data, type, full, meta) {
                        var html = new annexaDiv('text-right');
                        if($scope.modalDataDoc.objects){
                            var objectOk = $.grep($scope.modalDataDoc.objects, function( n, j ) {
                                if(n.id == full.id){
                                    return n;
                                }
                                return ;
                            });
                            if(objectOk && objectOk.length > 0){
                                html.htmlObj.addChildren(
                                    new annexaButton(
                                        'btn-sm primary text-white',
                                        'removeFromQueueSearchDoc('+full.id+')',
                                        'hidden-sm-down',
                                        undefined,
                                        'fa-check', undefined, true, 'hovering'+full.id, '{\'danger\':hovering'+full.id+'}', 'fa-remove')
                                );

                            }else{
                                html.htmlObj.addChildren(
                                    new annexaButton(
                                        'btn-sm grey-500 text-white',
                                        'addToQueueSearchDoc(' + full.id + ')',
                                        'hidden-sm-down',
                                        $filter('translate')('global.literals.select'))
                                );
                            }
                        }
                        return html.htmlObj.getHtml();
                    };

                    var filterLastModified = [
                        { id: '',name:'global.documents.datatable.all' },
                        { id: '1',name:'global.documents.datatable.today' },
                        { id: '2',name:'global.documents.datatable.yesterday' },
                        { id: '3',name:'global.documents.datatable.oneWeekAgo' },
                        { id: '4',name:'global.documents.datatable.twoWeeksAgo' },
                        { id: '5',name:'global.documents.datatable.threeWeekAgo' }
                    ];

                    // FILTRE
                    $scope.tfilterDoc = new TableFilter(false);
                    $scope.tfilterDoc.loadLocalData(['documenttypes', 'sections']);
                    $scope.tfilterDoc.addElement('code', 'text', 0, 'global.literals.code');
                    $scope.tfilterDoc.addElement('name', 'text', 1, 'global.literals.title');
                    $scope.tfilterDoc.addElement('template', 'select', 2, 'global.literals.template', 2, 'Template', true, 'description', false);
                    $scope.tfilterDoc.addElement('type', 'select', 3, 'global.literals.type', 1, 'documenttypes', true, 'name', true);
                    $scope.tfilterDoc.addElement('origin', 'select', 4, 'global.literals.module', 1, undefined, true, 'name', true);
                    $scope.tfilterDoc.addElement('modificat', 'select', 5, 'global.literals.modified', 3, filterLastModified, true, 'name', true);
                    $scope.tfilterDoc.addElement('section', 'select', 6, 'global.literals.departament', 1, 'sections', true, 'name', true);
                    $scope.tfilterDoc.addElement('createUser', 'text', 7, 'global.literals.user');
                    
                    // MRS-I expFilterAdv ¿?
                    $scope.dtDefDocs = new TableData('doc','Document','./api/doc/byfilter', 'rt<"row"<"col-sm-5"><"col-sm-7"p>>', 'code', [[1, 'desc']]);
                    //callAuxToAdd: { added: $linq(vm.content).select("x => x.id").toArray()
                    // MRS-F

                    //***************************
                    // Callback del FILTRE      *
                    //***************************
                    var getFilterCallDoc = function(){
                        var filterCallDoc = {code:undefined, name:$scope.tfilterDoc.name.model, template:{}, section:{}, type:{}, createUser:{}};
                        if($scope.tfilterDoc.code.model){
                            filterCallDoc.code = $scope.tfilterDoc.code.model;
                        }
                        if($scope.tfilterDoc.template.model.id){
                            filterCallDoc.template.id = $scope.tfilterDoc.template.model.id;
                        }
                        if($scope.tfilterDoc.section.model.id){
                            filterCallDoc.section.id = $scope.tfilterDoc.section.model.id;
                        }
                        if($scope.tfilterDoc.type.model.id){
                            filterCallDoc.type.id = $scope.tfilterDoc.type.model.id;
                        }
                        if($scope.tfilterDoc.createUser.model && $scope.tfilterDoc.createUser.model != ''){
                            filterCallDoc.createUser.identifier = $scope.tfilterDoc.createUser.model;
                        }
                        return filterCallDoc;
                    };

                    //***********************
                    // Definir TAULA        *
                    //***********************
                    $scope.tableDocs={
                        dtColumns:[
                            DTColumnBuilder.newColumn('type.id').withOption('width', '5%').withTitle($filter('translate')('global.literals.type')).renderWith(typeSearchDocumentsImg).notSortable(),
                            DTColumnBuilder.newColumn('code').withOption('width', '15%').withTitle($filter('translate')('global.literals.code')).renderWith(codeSearchDocuments),
                            DTColumnBuilder.newColumn('name').withOption('width', '70').withTitle($filter('translate')('global.literals.title')),
                            DTColumnBuilder.newColumn('status.id').withOption('width', '10%').notSortable().renderWith(actionButtonsSearchDocumentsHtml)
                        ],
                        filterCall:getFilterCallDoc(),
                        filterCallAux:{loggedUser: $rootScope.LoggedUser.id, modificat: $scope.tfilterDoc.modificat.model.id},
                        reloadInternalData:function(resetPaging){
                            this.filterCall = getFilterCallDoc();
                            this.filterCallAux.loggedUser = $rootScope.LoggedUser.id;
                            if($scope.tfilterDoc.modificat.model.id){
                                this.filterCallAux.modificat = $scope.tfilterDoc.modificat.model.id;
                            }else{
                                this.filterCallAux.modificat = undefined;
                            }
                            this.table.reloadData(callbackInstance,resetPaging);
                        },
                        list:[]
                    };

                    //***********************
                    // Definir MODAL        *
                    //***********************
                    // MRS-I exp ¿?
                    $scope.modalDataDoc = new modalData($scope.searchDoc.objects, 'modal-dialog modal-lg', 'global.literals.advancedSearchDoc','tfilterDoc','tfilterDoc','tableDocs','dtDefDocs',undefined,'submit',undefined,'submit','reloadSearchDoc()');
                    //$scope.ModalAltaDocuments = new ModalAltaDocuments($scope, false, 'THIRDS','searchDoc', 'tableDoc');
                    // MRS-F
                    
                    //****************************************
                    // Callback REFRESC de la cerca MODAL    *
                    //****************************************
                    $scope.reloadSearchDoc = function () {
                        $scope.tableDocs.reloadInternalData(true);
                    };

                    //*******************************
                    // Callback AFEGIR del MODAL    *
                    //*******************************
                    $scope.addToQueueSearchDoc = function (id) {
                        var objectOk = $.grep($scope.tableDocs.list, function( n, j ) {
                            if(n.id ==id){
                                return n;
                            }
                            return ;
                        });
                        if(objectOk && objectOk.length > 0){
                            $scope.modalDataDoc.objects.push(objectOk[0]);
                            $scope.addFromDiv(id, 'Document', $scope);
                            $scope.tableDocs.reloadInternalData(true);
                        }
                    };

                    //*******************************
                    // Callback ELIMINAR del MODAL  *
                    //*******************************
                    $scope.removeFromQueueSearchDoc = function (id) {
                        var objectOk = $.grep($scope.modalDataDoc.objects, function( n, j ) {
                            if(n.id ==id){
                                return n;
                            }
                            return ;
                        });
                        if(objectOk && objectOk.length > 0){
                            $scope.deleteFromDiv(id, 'doc', undefined, $scope);
                            $scope.tableDocs.reloadInternalData(true);
                        }
                    };

                },
                defineTableBoxDataRepresentacio  : function($scope, $rootScope){
                    //*******************************************
                    // Definir valors de Representació  *
                    //*******************************************
                    regNameTranslateDiv = function(data) {
                        var content = '';
                        if(data && data.id){
                            content = content + $filter('annexatranslate')(data, $rootScope.app.language);
                        }
                        return content;
                    };

                    //**********************************************************
                    // Definir links per eliminar representants de la taula     *
                    //**********************************************************
                    thirdDeleteRepreDiv = function(data, type, full, meta) {
                        var content = '';
                        content = '<div class="dropdown inline"><a class="btn dropdown-toggle" data-toggle="dropdown"><span class="fa fa-ellipsis-v">  </span></a>';
                        content = content + '<div class="dropdown-menu pull-right">';
                        if(data){
                            content = content + '<a href ng-click="deleteFromDiv('+data+', \'repre\', undefined, this);" class="dropdown-item"><span translate="global.literals.remove">Borrar</span></a>';
                        }else{
                            content = content + '<a href ng-click="deleteFromDiv('+meta.row+', \'repre\', true, this);" class="dropdown-item"><span translate="global.literals.remove">Borrar</span></a>';
                        }
                        content = content + '</div></div>';
                        return content;
                    };

                    thirdGetIdent = function(data, type, full, meta) {
                        var content = '';
                        if(full.agent.identificationDocument){
                            content = '<span>'+full.agent.identificationDocument+'</span>';
                        }
                        return content;
                    };

                    //***************************************
                    // Definir contingut columna 'name'     *
                    //***************************************
                    getHtmlContentName = function(data, type, full, meta){
                        var content = "";
                        if(full.agent) {
                            var code = "";
                            if (full.agent.name) {
                                code = full.agent.name + ' ' + full.agent.surename1 + ((full.agent.surename2) ? ' '+full.agent.surename2 : '');
                            } else if (full.agent.corporateName) {
                                code = full.agent.corporateName;
                            }
                            var href = new annexaHref('text-info', 'annexa.thirds.edit({id: \''+full.agent.id+'\'})', code);
                            return href.htmlObj.getHtml();
                        }
                        return content;
                    };
                    regValidityDateDiv = function(data, type, full, meta) {
                        var content = '';
                        var date = full.endValidityPeriod;
                        if(full.endValidityPeriod.time){
                            date = new Date(full.endValidityPeriod.time);
                        }
                        if(full.revoked){
                            content = content + "Rebocat el " +  $filter('date')(date, 'dd/MM/yyyy HH:MM:ss');
                        }else{
                            if(date >= new Date()){
                                content = content + "Vigent fins el" + $filter('date')(date, 'dd/MM/yyyy HH:MM:ss');
                            }else {
                                content = content + "Cacucada el " +  $filter('date')(date, 'dd/MM/yyyy HH:MM:ss');
                            }
                        }
                        return content;
                    };

                    // Definir ESTRUCTURA DE DADES
                    $scope.defRepre = new SearchDataBox('addFromDiv','searchRepre','tableRepre', 'dtDefRepre', 'global.thirds.literals.repr', 'global.literals.placeholder_search_dossiers','thirds','Third', true, false, 'id', 'global.literals.placeholder_search_dossiers','#searchAssentModal','#addRepreModal',undefined,2);
                    $scope.searchRepre={search:'', loadObjects:[], objects:[], addObject:[]};
                    $scope.dtDefRepre = new TableData(undefined,undefined,'searchRepre', 'rt<"row"<"col-sm-6"><"col-sm-6"p>>', 'id', [[0, 'desc']]);
                    $scope.tableRepre={
                        dtColumns:[
                            DTColumnBuilder.newColumn('agent.name').withTitle($filter('translate')('global.literals.number')).renderWith(getHtmlContentName),
                            DTColumnBuilder.newColumn('agent.identificationDocument').withTitle($filter('translate')('global.literals.classification')).renderWith(thirdGetIdent),
                            DTColumnBuilder.newColumn('endValidityPeriod').withTitle($filter('translate')('global.literals.classification')).renderWith(regValidityDateDiv),
                            DTColumnBuilder.newColumn('id').renderWith(thirdDeleteRepreDiv)
                        ],
                        reloadInternalData:function(resetPaging){
                            this.table.reloadData(callbackInstance,resetPaging);
                        }
                    };

                    //***********************************
                    // Callback cerca d'assentaments    *
                    //***********************************
                    actionButtonsSearchRepreHtml = function(data, type, full, meta) {
                        var html = new annexaDiv('text-right');
                        if($scope.modalDataRepre.objects){
                            var objectOk = $.grep($scope.modalDataRepre.objects, function( n, j ) {
                                if(n.id == full.id){
                                    return n;
                                }
                                return ;
                            });
                            if(objectOk && objectOk.length > 0){
                                html.htmlObj.addChildren(
                                    new annexaButton(
                                        'btn-sm primary text-white',
                                        'removeFromQueueSearchRepre('+full.id+')',
                                        'hidden-sm-down',
                                        undefined,
                                        'fa-check', undefined, true, 'hovering'+full.id, '{\'danger\':hovering'+full.id+'}', 'fa-remove')
                                );

                            }else{
                                html.htmlObj.addChildren(
                                    new annexaButton(
                                        'btn-sm grey-500 text-white',
                                        'addToQueueSearchRepre(' + full.id + ')',
                                        'hidden-sm-down',
                                        $filter('translate')('global.literals.select'))
                                );
                            }
                        }
                        return html.htmlObj.getHtml();
                    };

                    //***************
                    // FILTRE       *
                    //***************
                    $scope.tfilterRepre = new TableFilter(false);
                    $scope.tfilterRepre.addElement('fidentificador', 'text', 0, 'global.literals.identifier');
                    $scope.tfilterRepre.addElement('fnom', 'text', 1, 'global.literals.name');
                    $scope.tfilterRepre.addElement('fpoblacio', 'text', 2, 'global.literals.city');
                    $scope.tfilterRepre.addElement('femail', 'text', 3, 'global.profile.email');
                    $scope.tfilterRepre.addElement('ftelefon', 'text', 4, 'global.profile.phone');

                    //***************************
                    // Callback del FILTRE      *
                    //***************************
                    var getFilterCallRepre = function(){
                        var filterCallRepre = {name:"", identificationDocument:""};
                        if($scope.tfilterRepre.fidentificador.model){
                            filterCallRepre.identificationDocument = $scope.tfilterRepre.fidentificador.model;
                        }
                        if($scope.tfilterRepre.fnom.model){
                            filterCallRepre.name = $scope.tfilterRepre.fnom.model;
                        }
                        return filterCallRepre;
                    };
                    getHtmlContentName = function(data, type, full, meta){
                        var content = "";
                        if(full.name){
                            content = '<span>'+full.name +'&nbsp;'+full.surename1+ ((full.surename2) ? '&nbsp;'+full.surename2 : '')+'</span>';
                        }else if(full.corporateName){
                            content = '<span>'+full.corporateName+'</span>';
                        }
                        return content;
                    };
                    getHtmlContentAdresses = function(data, type, full, meta){
                        var content = "";
                        if(full.addresses && full.addresses.length > 0){
                            for(var i=0; i<full.addresses.length; i++){
                                address = full.addresses[i];
                                if(address.default){
                                    var html = new annexaDiv('');
                                    var icon;
                                    if(address.addressType == "TELEMATIC_EMAIL"){
                                        icon = new annexaIcon('fa-envelope');
                                        var html2 = new annexaSpan(address.telematicValue);
                                        html.htmlObj.addChildren(icon);
                                        html.htmlObj.addChildren(html2);
                                    }else if(address.addressType == "TELEMATIC_MOBILEPHONE"){
                                        icon = new annexaIcon('fa-mobile');
                                        var html2 = '';
                                        if(address.phoneInternationalPrefix) {
                                            html2 = new annexaSpan('+' + address.phoneInternationalPrefix + address.telematicValue);
                                        } else {
                                            html2 = new annexaSpan(address.telematicValue);
                                        }
                                        html.htmlObj.addChildren(icon);
                                        html.htmlObj.addChildren(html2);
                                    }else{
                                        icon = new annexaIcon('fa-map-marker');
                                        var html2 = new annexaSpan(address.street);
                                        html.htmlObj.addChildren(icon);
                                        html.htmlObj.addChildren(html2);
                                    }
                                    return html.htmlObj.getHtml();
                                }
                            }
                            //content
                        }
                        return content;
                    };
                    getHtmlContentRepresentations = function(data, type, full, meta){
                        var content = "";
                        if(full.representations && full.representations.length > 0){
                            content = '<span translate="global.thirds.literals.datatable.col3"></span>';
                        }
                        return content;
                    };
                    //************************************************
                    // Definir contingut columna 'identificador'     *
                    //************************************************
                    thirdGetIdentLink = function(data, type, full, meta) {
                        var content = '';
                        if(full.identificationDocument){
                            content = '<a ng-click="seeDetail('+full.id+')"><span>'+full.identificationDocument+'</span></a> ';
                        }
                        return content;
                    };
                    //***********************
                    // Definir TAULA        *
                    //***********************
                    $scope.dtDefRepres = new TableData('thirds','Third','./api/thirds/byfilter','rt<"row"<"col-sm-6"><"col-sm-6"p>>','identificationDocument',[[0, 'asc']], undefined, undefined,undefined, undefined, undefined, 2);
                    $scope.tableRepres = {
                        dtColumns:[
                            DTColumnBuilder.newColumn('identificationDocument').withTitle($filter('translate')('global.thirds.literals.datatable.col1')).renderWith(thirdGetIdentLink),
                            DTColumnBuilder.newColumn('name').withOption('width', '25%').withTitle($filter('translate')('global.thirds.literals.datatable.col2')).renderWith(getHtmlContentName),
                            DTColumnBuilder.newColumn('representations').notSortable().withTitle($filter('translate')('global.thirds.literals.datatable.col3')).renderWith(getHtmlContentRepresentations),
                            DTColumnBuilder.newColumn('addresses').notSortable().withTitle($filter('translate')('global.thirds.literals.datatable.col4')).renderWith(getHtmlContentAdresses),
                            DTColumnBuilder.newColumn('name').renderWith(actionButtonsSearchRepreHtml).notSortable()
                        ],
                        filterCall: getFilterCallRepre(),
                        filterCallAux: {},
                        reloadInternalData: function(resetPaging){
                            this.filterCall = getFilterCallRepre();
                            this.filterCallAux = {};
                            if($scope.tfilterRepre.fpoblacio.model){
                                this.filterCallAux.city = $scope.tfilterRepre.fpoblacio.model;
                            }
                            if($scope.tfilterRepre.femail.model){
                                this.filterCallAux.mail = $scope.tfilterRepre.femail.model;
                            }
                            if($scope.tfilterRepre.ftelefon.model){
                                this.filterCallAux.phone = $scope.tfilterRepre.ftelefon.model;
                            }
                            this.filterCallAux.loggedUser = $rootScope.LoggedUser.id;
                            this.table.reloadData(callbackInstance,resetPaging);
                        },
                        list:[]
                    };


                    //*******************
                    // Definir MODAL    *
                    //*******************
                    $scope.modalDataRepre = new modalData($scope.searchRepre.addObject, 'modal-dialog modal-lg', 'global.literals.advancedSearchRepres','tfilterRepre','tfilterRepre','tableRepres','dtDefRepres',undefined,'submit',undefined,'submit','reloadSearchRepre()','#addRepreModal');

                    //**************************************
                    // Callback REFRESC cerca del MODAL    *
                    //**************************************
                    $scope.reloadSearchRepre = function () {
                        $scope.tableRepres.reloadInternalData(true);
                    };

                    //*******************************
                    // Callback AFEGIR del MODAL    *
                    //*******************************
                    $scope.addToQueueSearchRepre = function (id) {
                        var objectOk = $.grep($scope.tableRepres.list, function( n, j ) {
                            if(n.id ==id){
                                return n;
                            }
                            return ;
                        });
                        if(objectOk && objectOk.length > 0){
                            $scope.modalDataRepre.objects.push(objectOk[0]);
                            //$scope.addFromDiv(id, 'ThirdRepresentation', $scope);
                            $scope.tableRepres.reloadInternalData(true);
                        }
                    };

                    //*******************************
                    // Callback ELIMINAR del MODAL  *
                    //*******************************
                    $scope.removeFromQueueSearchRepre = function (id) {
                        var list = $scope.modalDataRepre.objects;
                        for (var i = 0; i < list.length; i++) {
                            if (list[i].id == id) {
                                list.splice(i, 1);
                            }
                        }
                        //$scope.deleteFromDiv(id, 'repre', undefined, $scope);
                        $scope.tableRepres.reloadInternalData(true);
                    };
                    $scope.addRepreModal = new ModalAddRepresentation($scope, 'searchRepre', 'tableRepre');
                },
                defineTableBoxDataContacte  : function($scope, $rootScope){
                    //**********************************************************
                    // Definir links per eliminar contactes de la taula     *
                    //**********************************************************
                    thirdDeleteContactDiv = function(data, type, full, meta) {
                        var content = '';
                        content = '<div class="dropdown inline"><a class="btn dropdown-toggle" data-toggle="dropdown"><span class="fa fa-ellipsis-v">  </span></a>';
                        content = content + '<div class="dropdown-menu pull-right">';
                        if(full.id){
                            content = content + '<a href ng-click="deleteFromDiv('+full.id+', \'contact\', undefined, this);" class="dropdown-item"><span translate="global.literals.remove">Borrar</span></a>';
                        }else{
                            content = content + '<a href ng-click="deleteFromDiv('+meta.row+', \'contact\', true, this);" class="dropdown-item"><span translate="global.literals.remove">Borrar</span></a>';
                        }
                        content = content + '</div></div>';
                        return content;
                    };

                    thirdTypeContactDiv = function(data, type, full, meta) {
                        var content = '';
                        if(full && full.addressType){
                            content = content + $filter('translate')("thirds.addressTypes."+full.addressType);
                        }
                        return content;
                    };

                    thirdIconContactDiv = function(data, type, full, meta) {
                        if(full && full.addressType){
                            if(full.addressType == "TELEMATIC_EMAIL"){
                                var icon = new annexaIcon('fa-envelope');
                                return icon.htmlObj.getHtml();
                            }else if(full.addressType == "TELEMATIC_MOBILEPHONE"){
                                var icon = new annexaIcon('fa-mobile');
                                return icon.htmlObj.getHtml();
                            }else{
                                var icon = new annexaIcon('fa-map-marker');
                                return icon.htmlObj.getHtml();
                            }
                        }
                        return "";
                    };

                    thirdisDefaultContactDiv = function(data, type, full, meta) {
                        if(full && full.default){
                            var icon = new annexaIcon('fa-star text-warn');
                            return icon.htmlObj.getHtml();
                        }else{
                            var icon = new annexaIcon('fa-star-o');
                            return icon.htmlObj.getHtml();
                        }
                        return "";
                    };

                    thirdDescriptionContactDiv = function(data, type, full, meta) {
                        if(full && full.addressType) {
                            if (full.addressType == "TELEMATIC_EMAIL") {
                                return full.telematicValue;
                            } else if (full.addressType == "TELEMATIC_MOBILEPHONE") {
                                if(full.phoneInternationalPrefix) {
                                    return '+' + full.phoneInternationalPrefix + full.telematicValue;
                                } else {
                                    return full.telematicValue;
                                }
                            } else{
                                var content = "";
                                if(full.validStreet){
                                    if(full.validStreet.streetType){
                                        content = content + " "+$filter('annexatranslate')(full.validStreet.streetType, $rootScope.app.language);
                                    }
                                }else if(full.street){
                                    if(full.streetType) {
                                        content += " " + full.streetType[Language.getActiveColumn()];
                                    }
                                    content = content + " "+full.street;
                                }
                                if(full.numberAddres){
                                    content = content + " "+full.numberAddres;
                                }
                                if(full.block){
                                    content = content + " "+full.block;
                                }
                                if(full.stair){
                                    content = content + " "+full.stair;
                                }
                                if(full.floor){
                                    content = content + " "+full.floor;
                                }
                                if(full.portal){
                                    content = content + " "+full.portal;
                                }
                                if(full.validCity){
                                    content = content + " "+$filter('annexatranslate')(full.validCity.name, $rootScope.app.language);
                                }else if(full.city){
                                    content = content + " "+full.city+"";
                                }
                                if(full.country && full.country.name){
                                    content = content + " ("+$filter('annexatranslate')(full.country.name, $rootScope.app.language)+")";
                                }
                                return content;
                            }
                        }
                        return "";
                    };

                    //*******************************
                    //Definir ESTRUCTURES DE DADES  *
                    //*******************************

                    $scope.defContact = new SearchDataBox('addFromDiv','searchContact','tableContact', 'dtDefContact', 'global.thirds.literals.form.contactData', 'global.reg.literals.addDiligPlace','thirds','ThirdAddress', false, true, 'id',undefined,undefined,undefined,'#newContactModal');
                    $scope.searchContact={search:'', loadObjects:[], objects:[], addObject:[]};
                    $scope.dtDefContact = new TableData(undefined,undefined,'searchContact', 'rt<"row"<"col-sm-6"><"col-sm-6"p>>', 'code', [[0, 'desc']]);
                    $scope.tableContact={
                        dtColumns:[
                            DTColumnBuilder.newColumn('addressType').withOption('width', '15%').withTitle($filter('translate')('global.literals.code')).renderWith(thirdIconContactDiv),
                            DTColumnBuilder.newColumn('addressType').withOption('width', '35%').withTitle($filter('translate')('global.literals.title')).renderWith(thirdTypeContactDiv),
                            DTColumnBuilder.newColumn('addressType').withOption('width', '30%').withTitle($filter('translate')('global.literals.code')).renderWith(thirdDescriptionContactDiv),
                            DTColumnBuilder.newColumn('addressType').withOption('width', '10%').withTitle($filter('translate')('global.literals.code')).renderWith(thirdisDefaultContactDiv),
                            DTColumnBuilder.newColumn('addressType').withOption('width', '10%').withTitle($filter('translate')('global.literals.title')).renderWith(thirdDeleteContactDiv)
                        ],
                        reloadInternalData:function(resetPaging){
                            this.table.reloadData(callbackInstance,resetPaging);
                        }
                    };

                    $scope.newContactModal = new ModalNewContact($scope, 'searchContact', 'tableContact');
                },
                defineTableBoxDataConsent  : function($scope, $rootScope){
                    //**********************************************************
                    // Definir links per eliminar Consentiments de la taula     *
                    //**********************************************************
                    thirdDeleteConsentDiv = function(data, type, full, meta) {
                        var content = '';
                        content = '<div class="dropdown inline"><a class="btn dropdown-toggle" data-toggle="dropdown"><span class="fa fa-ellipsis-v">  </span></a>';
                        content = content + '<div class="dropdown-menu pull-right">';
                        if(data){
                            content = content + '<a href ng-click="deleteFromDiv('+data+', \'consent\', undefined, this);" class="dropdown-item"><span translate="global.literals.remove">Borrar</span></a>';
                        }else{
                            content = content + '<a href ng-click="deleteFromDiv('+meta.row+', \'consent\', true, this);" class="dropdown-item"><span translate="global.literals.remove">Borrar</span></a>';
                        }
                        content = content + '</div></div>';
                        return content;
                    };

                    thirdStateConsentDiv = function(data, type, full, meta) {
                        var content = '';
                        if(full && full.diligenceState){
                            content = content + $filter('annexatranslate')(full.diligenceState.name, $rootScope.app.language);
                            content = content + '&nbsp;&nbsp;';
                            if(full.createdDate && full.createdDate.time){
                                content = content + $filter('date')(new Date(full.createdDate.time), 'dd/MM/yyyy HH:MM:ss');
                            }else{
                                content = content + $filter('date')(new Date(), 'dd/MM/yyyy HH:MM:ss');
                            }
                        }
                        return content;
                    };

                    //*******************************
                    //Definir ESTRUCTURES DE DADES  *
                    //*******************************

                    $scope.defConsent = new SearchDataBox('addFromDiv','searchConsent','tableConsent', 'dtDefConsent', 'global.thirds.literals.form.cons', 'global.reg.literals.addDiligPlace','thirds','ThirdConsent', false, false, 'id',undefined,undefined,undefined,'#newConsentModal');
                    $scope.searchConsent={search:'', loadObjects:[], objects:[], addObject:[]};
                    $scope.dtDefConsent = new TableData(undefined,undefined,'searchConsent', 'rt<"row"<"col-sm-6"><"col-sm-6"p>>', 'code', [[0, 'desc']]);
                    $scope.tableConsent={
                        dtColumns:[
                            DTColumnBuilder.newColumn('id').withOption('width', '15%').withTitle($filter('translate')('global.literals.code')),
                            DTColumnBuilder.newColumn('id').withOption('width', '35%').withTitle($filter('translate')('global.literals.title')),
                            DTColumnBuilder.newColumn('id').withOption('width', '40%').withTitle($filter('translate')('global.literals.code')),
                            DTColumnBuilder.newColumn('id').withOption('width', '10%').withTitle($filter('translate')('global.literals.title')).renderWith(thirdDeleteConsentDiv)
                        ],
                        reloadInternalData:function(resetPaging){
                            this.table.reloadData(callbackInstance,resetPaging);
                        }
                    };

                    $scope.newConsentModal = new ModalNewConsent($scope, 'searchConsent', 'tableConsent');
                }
            }
        }]);