/**
 * Created by osirvent on 29/03/2016.
 */

var htmlAttribute = function (key, value) {
    this.key = key;
    this.value = value;

    this.getHtml = function () {
        if(value) {
            return ' ' + key + '="' + value + '"';
        } else {
            return ' ' + key;
        }
    };
};

var htmlObject = function(tag, attributes, text, translate) {
    this.tag = tag;
    if(attributes) {
        this.attributes = attributes;
    } else {
        this.attributes = []
    }
    this.text = text;
    this.translate = translate;
    this.children = [];

    this.addChildren = function (child) {
        this.children.push(child);
    };

    this.addAttribute = function (key, value) {
        this.attributes.push(new htmlAttribute(key,value));
    };

    this.getHtml = function () {
        var content = '';
        if(this.tag == 'br') {
            content = '<br/>';
        }else{
            content = '<' + this.tag;

            if (this.attributes) {
                for (var i = 0; i < this.attributes.length; i++) {
                    content += this.attributes[i].getHtml();
                }
            }

            if (this.text && this.translate) {
                content += ' translate="' + this.text + '"';
            }

            content += ">";

            for (var i = 0; i < this.children.length; i++) {
                if (this.children[i].hasOwnProperty('htmlObj')) {
                    content += ' ' + this.children[i].htmlObj.getHtml();
                } else {
                    content += ' ' + this.children[i].getHtml();
                }
            }

            if (this.text) {
                content += this.text;
            }

            content += '</' + this.tag + '>';
        }
        return content;
    };
};

var annexaDiv = function(classes, id, dataBackDrop, role, include) {
    if(classes) {
        this.htmlObj = new htmlObject('div', [new htmlAttribute('class', classes)]);
    } else {
        this.htmlObj = new htmlObject('div', []);
    }
    if(id){
        this.htmlObj.addAttribute('id', id);
    }
    if(dataBackDrop != undefined && dataBackDrop != null){
        this.htmlObj.addAttribute('data-backdrop', dataBackDrop);
    }
    if(role){
        this.htmlObj.addAttribute('role', role);
    }
    if(include){
        this.htmlObj.addAttribute('ng-include', include);
    }
};

var annexaSpan = function (text,classes, show) {
    if(classes) {
        this.htmlObj = new htmlObject('span', [new htmlAttribute('class', classes)], text);
    } else {
        this.htmlObj = new htmlObject('span', [], text);
    }
    if(show != undefined && show != null) {
        this.htmlObj.addAttribute('ng-show', show);
    }
};

var annexaIcon = function (icon, classes, title, show) {
    this.htmlObj = new htmlObject('i', [new htmlAttribute('class', 'fa ' + icon)]);
    if(classes){
        this.htmlObj.addAttribute('ng-class', classes);
    }
    if(title) {
        this.htmlObj.addAttribute('title', title);
    }
    
    if(show) {
        this.htmlObj.addAttribute('ng-show', show);
    }
};

var annexaButton = function (classes, click, span_classes, span_text, icon, title, show, hoverVar, ngclasses, iconHover, disabled) {
    this.htmlObj = new htmlObject('button',
        [
            new htmlAttribute('class', 'btn ' + classes),
            new htmlAttribute('ng-click', click)
        ]);

    if(title) {
        this.htmlObj.addAttribute('title', title);
    }

    if(icon) {
        if(hoverVar) {
            this.htmlObj.addChildren(new annexaIcon(undefined,'{\''+icon+'\':(!'+hoverVar+')}'));
        }else{
            this.htmlObj.addChildren(new annexaIcon(icon));
        }
    }

    if(span_text) {
        this.htmlObj.addChildren(new annexaSpan(span_text, span_classes));
    }

    if(show) {
        this.htmlObj.addAttribute('ng-show',show);
    }
    
    if(disabled) {
    	this.htmlObj.addAttribute('ng-disabled',disabled);
    }

    if(hoverVar) {
        this.htmlObj.addAttribute('ng-mouseover', hoverVar+'=true');
        this.htmlObj.addAttribute('ng-mouseout',hoverVar+'=false');
    }
    if(ngclasses) {
        this.htmlObj.addAttribute('ng-class', ngclasses);
    }

    if(iconHover) {
        this.htmlObj.addChildren(new annexaIcon(undefined,'{\''+iconHover+'\':'+hoverVar+'}'));
    }
    /*var annexaIcon = function (icon, classes, title) {
     this.htmlObj = new htmlObject('i', [new htmlAttribute('class', 'fa ' + icon)]);
     if(classes){
     this.htmlObj.addAttribute('ng-class', classes);
     }
     if(title) {
     this.htmlObj.addAttribute('title', title);
     }
     };*/
    /*ng-mouseover="imgsrc='/images/twitter_brown.png'" ng-mouseout="imgsrc='/images/twitter_blue.png'"*/
};

var annexaDropdownButton = function(classes) {
    this.htmlObj = new htmlObject('button',
        [
            new htmlAttribute('class', 'btn ' + classes),
            new htmlAttribute('data-toggle', 'dropdown')
    ]);    
}

var annexaDropdownSpan = function(classes) {
    this.htmlObj = new htmlObject('span',
        [
            new htmlAttribute('class', classes),
            new htmlAttribute('data-toggle', 'dropdown')
        ]);
}

var annexaModalButton = function (classes, modal_selector, click, span_classes, span_text, icon, title, dataDismiss, disabled) {
    this.htmlObj = new htmlObject('button',
        [
            new htmlAttribute('class', 'btn ' + classes),
            new htmlAttribute('data-toggle', 'modal'),
            new htmlAttribute('data-target', modal_selector),
            new htmlAttribute('ng-click', click)
        ]);

    if(title) {
        this.htmlObj.addAttribute('title', title);
    }

    if(icon) {
        this.htmlObj.addChildren(new annexaIcon(icon));
    }

    if(span_text) {
        this.htmlObj.addChildren(new annexaSpan(span_text, span_classes));
    }

    if(dataDismiss) {
        this.htmlObj.addAttribute('data-dismiss', dataDismiss);
    }

    if(disabled != undefined && disabled != null) {
        this.htmlObj.addAttribute('ng-disabled', disabled);
    }
}

var annexaHref = function (classes,ui_sref,text, target, title) {
    this.htmlObj = new htmlObject('a',
    [
        new htmlAttribute('href'),
        new htmlAttribute('ui-sref', ui_sref),
        new htmlAttribute('class', classes)
    ],text);
    if(target) {
        this.htmlObj.addAttribute('target', target);
    }
    if(title) {
        this.htmlObj.addAttribute('title', title);
    }

}

var annexaModalHref = function (classes, modal_selector, click, span_classes, span_text, icon, title, dataAnimation) {
    this.htmlObj = new htmlObject('a',
        [
            new htmlAttribute('href',''),
            new htmlAttribute('class', classes),
            new htmlAttribute('data-toggle', 'modal'),
            new htmlAttribute('data-target', modal_selector),
            new htmlAttribute('ng-click', click),
            new htmlAttribute('role', 'button')
        ]);

    if(title) {
        this.htmlObj.addAttribute('title', title);
    }

    if(dataAnimation) {
        this.htmlObj.addAttribute('data-animation', dataAnimation);
    }

    if(icon) {
        this.htmlObj.addChildren(new annexaIcon(icon));
    }

    if(span_text) {
        this.htmlObj.addChildren(new annexaSpan(span_text, span_classes));
    }
}

var annexaDropDownHref = function (classes,text) {
    this.htmlObj = new htmlObject('a',
        [
            new htmlAttribute('class', classes),
            new htmlAttribute('data-toggle', 'dropdown')
        ],text);
}


var annexaClickHref = function (classes,click,text, translate) {
    this.htmlObj = new htmlObject('a',
        [
            new htmlAttribute('href'),
            new htmlAttribute('ng-click', click),
            new htmlAttribute('class', classes)
        ],text);
}

var annexaParagraph = function (classes,text) {
    this.htmlObj = new htmlObject('p',
        [
            new htmlAttribute('class', classes)
        ],text);
}


var annexaStrong = function (text) {
    this.htmlObj = new htmlObject('strong',[],text);
}

var annexaBr = function () {
    this.htmlObj = new htmlObject('br',[],'');
}

var annexaCircleicon = function (bgcolor, title, text, classes, size, icon) {
    this.htmlObj = new htmlObject('circleicon',
        [
            new htmlAttribute('bgcolor', bgcolor),
            new htmlAttribute('title', title)
        ], text);

    if(classes) {
        this.htmlObj.addAttribute('class', classes);
    }
    if(size) {
        this.htmlObj.addAttribute('size', size);
    }

    if(icon) {
        this.htmlObj.addChildren(new annexaIcon(icon));
    }
}

var annexaTextarea = function (classes, maxlength, rows, model, disabled, placeholder) {
    this.htmlObj = new htmlObject('textarea',
        [
            new htmlAttribute('class', classes),
            new htmlAttribute('maxlength', maxlength),
            new htmlAttribute('rows', rows),
            new htmlAttribute('ng-model', model),
            new htmlAttribute('aria-label', placeholder),
        ], '');

    if(disabled) {
        this.htmlObj.addAttribute('ng-disabled', disabled);
    }
    if(placeholder) {
        this.htmlObj.addAttribute('placeholder', placeholder);
    }
}

var annexaDossierTransactionColumn = function($scope, idParent, objectClass, transactions, startText, endText){
    $scope["dossierTransactionsComponent"+idParent] = transactions;
    this.htmlObj = new htmlObject('annexa-view-dossier-transaction',
        [
            new htmlAttribute('dossier-transactions', "dossierTransactionsComponent"+idParent)
        ], '');
    if(objectClass){
        this.htmlObj.addAttribute('class', objectClass)
    }
    if(startText) {
        this.htmlObj.addAttribute('start-text', startText)
    }
    if(endText){
        this.htmlObj.addAttribute('end-text', endText)
    }
}