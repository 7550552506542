/**
 * Created by osirvent on 15/03/2016.
 */
angular.module('annexaApp')
    .directive('searchdata',['$compile', '$rootScope', 'RestService', function($compile, $rootScope, RestService) {
        var getTemplate = function(id, def, definitions, scope) {
            var template = '<div class="row">';
            template += ' <div class="col-sm-9">';
            if(definitions.buttonSearch) {
                //template += ' <div class="row">';
                //template += ' <div class="col-sm-2">';
                template += ' <div class="input-group">';
                if(definitions.modalSearch){
                    template += ' <div class="input-group-btn">';
                    template += ' <button type="button" ng-disabled="'+definitions.disabled+'" class="btn bg-faded" data-toggle="modal" data-target="' + definitions.modalSearch + '" ng-click="searchObject(\''+id+'\',\''+def+'\')"><i class="fa fa-search"></i></button>';
                    template += ' </div>';
                }
               // template += ' </div><div class="col-sm-8">';
                template += ' <input type="text" ng-disabled="'+definitions.disabled+'" ng-model="'+id+'.search" placeholder="{{\'' + definitions.searchText + '\' | translate}}" class="form-control" ng-blur="validObject(\''+id+'\',\''+def+'\')" ng-keyup="loadObject(\''+id+'\',\''+def+'\')" typeahead="s.'+definitions.nameShow+' for s in '+id+'.loadObjects | filter:$viewValue | limitTo:8" />';
                template += ' <div class="input-group-btn">';
                // template += ' </div><div class="col-sm-2">';
                if(definitions.modalAdd){
                    template += ' <button type="button" ng-disabled="'+definitions.disabled+'" class="btn bg-inverse" data-toggle="modal" data-target="' + definitions.modalAdd + '" ng-click="addObjectModal(\''+id+'\',\''+def+'\')" ng-disabled="disabledAdd(\''+id+'\',\''+def+'\')"><span translate="global.literals.add">Afegir</span></button>';
                }else{
                    template += ' <button type="button" class="btn bg-inverse" ng-click="addObject(\''+id+'\',\''+def+'\')" ng-disabled="disabledAdd(\''+id+'\',\''+def+'\','+definitions.disabled+')"><span translate="global.literals.add">Afegir</span></button>';
                }
                template += ' </div></div>';
                //template += ' </div></div>';
            }
            template += ' </div>';
            template += ' <div class="col-sm-3">';
            if(definitions.buttonNew){
                if(definitions.modalNew){
                    template += ' <button type="button" ng-disabled="'+definitions.disabled+'" class="btn grey m-l text-white" ng-click="newObject(\''+id+'\',\''+def+'\')" data-toggle="modal" data-target="' + definitions.modalNew + '"><span translate="global.literals.new">New</span></button>';
                }else{
                    template += ' <button type="button" ng-disabled="'+definitions.disabled+'" class="btn grey m-l text-white" ng-click="newObject(\''+id+'\',\''+def+'\')"><span translate="global.literals.new">New</span></button>';
                }
            }
            template += ' </div>';
            template += ' </div>';
            return template;
        };

        var getObjectsBySearch = function(scope, id, definitions){
            var idObject = scope[id];
            RestService.loadData(definitions.origin,definitions.objectType, idObject.search).then(function(dataSearch) {
                if(dataSearch.data){
                    idObject.loadObjects = JSOG.decode(dataSearch.data).content;
                }
            }).catch(function(error) {
            });
        };

        var getObjectBySearch = function(scope, id, definitions){
            var idObject = scope[id];
            var trobat = false;
            if(idObject && idObject.loadObjects && definitions) {
                for (var i = 0; i < idObject.loadObjects.length; i++) {
                    var object = idObject.loadObjects[i];
                    if (idObject.search == object[definitions.nameShow]) {
                        idObject.objAdd = object;
                        trobat = true;
                        break;
                    }
                }
                if (!trobat) {
                    idObject.objAdd = "";
                }
            }
        };

        var valueDefinitions = function(scope, def){
            var valueDef = {};
            if(def.indexOf('.') > 0){
                var values = def.split('.');
                for(var i = 0; i<values.length; i ++){
                   if(i == 0){
                       valueDef = scope[values[i]];
                   }else{
                       valueDef = valueDef[values[i]];
                   }
                }
            }else{
                valueDef = scope[def];
            }
            return valueDef;
        }
        return {
            restrict: 'E',
            replace: true,
            link: function(scope, element, attr) {
                if(attr['id'] && attr['searchDataDefinition']) {
                    scope.validObject = function(id, def) {
                        getObjectBySearch(scope, id, valueDefinitions(scope, def));
                    };

                    scope.loadObject = function(id, def) {
                        getObjectsBySearch(scope, id, valueDefinitions(scope, def));
                    };

                    scope.addObject = function(id, def) {
                        var idObject = scope[id];
                        var definitions = valueDefinitions(scope, def);
                        getObjectBySearch(scope, id, definitions);

                        if(idObject.objAdd && idObject.objAdd.id) {
                            var objectOk = $.grep(idObject.objects, function( n, j ) {
                                if(n.id == idObject.objAdd.id){
                                    return n;
                                }
                                return ;
                            });
                            if(objectOk == undefined || objectOk == null || objectOk.length == 0) {
                                idObject.objects.push(idObject.objAdd);
                                if (definitions.addFunction && scope[definitions.addFunction]) {
                                    scope[definitions.addFunction](idObject.objAdd.id, definitions.objectType, scope);
                                }
                            }
                        }
                    };

                    scope.addObjectModal = function(id, def) {
                        var idObject = scope[id];
                        var definitions = valueDefinitions(scope, def);
                        getObjectBySearch(scope, id, definitions);

                        if(idObject.objAdd && idObject.objAdd.id) {
                            var objectOk = $.grep(idObject.addObject, function( n, j ) {
                                if(n.id == idObject.objAdd.id){
                                    return n;
                                }
                                return ;
                            });
                            if(objectOk == undefined || objectOk == null || objectOk.length == 0) {
                                idObject.addObject.push(idObject.objAdd);
                                var popup =  scope[definitions.modalAdd.substring(1, definitions.modalAdd.length)];
                                if(popup && typeof popup.init == 'function'){
                                    popup.init(scope);
                                }
                            }
                        }
                    };

                    scope.disabledAdd = function(id, def, isEditDiligence) {
                        if(isEditDiligence){
                            return true;
                        }
                        var idObject = scope[id];
                        var definitions = valueDefinitions(scope, def);
                        getObjectBySearch(scope, id, definitions);
                        if(idObject.objAdd && idObject.objAdd.id) {
                            return false;
                        }else{
                            return true;
                        }
                    };

                    scope.newObject = function(id, def) {
                        var definitions = valueDefinitions(scope, def);
                        var popup =  scope[definitions.modalNew.substring(1, definitions.modalNew.length)];
                        if(popup && typeof popup.init == 'function'){
                            popup.init(scope);
                        }
                    };

                    scope.searchObject = function(id, def) {
                    };

                    element.html(getTemplate(attr['id'], attr['searchDataDefinition'], valueDefinitions(scope, attr['searchDataDefinition']))).show();
                }
                $compile(element.contents())(scope);
            }
        }
    }]);